import * as React from "react";
import "../../views/Staff.scss";

//components
import { IconButton } from "../Atomics";
import { cilX, cilPencil, cilPlus } from "@coreui/icons";
import { GroupResponse } from "../../services/types";
import {
  StaffGroupList,
  NewGroup as EditGroup,
  AddStaffGroup,
} from "../../components/staff";
import { User } from "../types";
import { getOneGroup } from "../../services/groupService";
import HandleUIError from "../../utils/HandleUIError";

interface Props {
  closeView: () => void;
  group: GroupResponse;
  refreshGroups: () => void;
}

const GroupContent: React.FC<Props> = ({ closeView, group, refreshGroups }) => {
  const [visibleEdit, setVisibleEdit] = React.useState<boolean>(false);
  const [visibleAddStaff, setVisibleAddStaff] = React.useState(false);
  const [usersInGroup, setUsersInGroup] = React.useState<Array<User>>([])
  const [isLoadingUsers, setIsLoadingUsers] = React.useState<boolean>(true);
  const [groupName, setGroupName] = React.useState<string>()
  const [groupDescription, setGroupDescription] = React.useState<string>()

  React.useEffect(
    function(){
      getOneGroup(group.id)
      .then(res =>{
        setUsersInGroup(res.users)
        setIsLoadingUsers(false)
        setGroupName(res.name)
        setGroupDescription(res.description)
      }).catch((e) =>{
        HandleUIError(e)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  async function refreshUsersInGroup(){
    await getOneGroup(group.id)
    .then(res =>{
      setUsersInGroup(res.users)
    }).catch((e) =>{
      HandleUIError(e)
    })
  }

  async function refreshGroupInfo(){
    await getOneGroup(group.id)
    .then(res =>{
      setGroupName(res.name)
      setGroupDescription(res.description)
    }).catch((e) =>{
      HandleUIError(e)
    })
  }

  function openAddStaff() {
    setVisibleAddStaff(true);
  }

  function closeAddStaff() {
    setVisibleAddStaff(false);
  }

  function openEdit() {
    setVisibleEdit(true);
  }

  function closeEdit() {
    setVisibleEdit(false);
  }

  return (
    <div>
      <div className="d-flex justify-content-end ">
        <IconButton
          icon={cilX}
          size="xxl"
          onClick={closeView}
          className="close-group-content-button mb-1"
        />
      </div>
      <div className="d-flex justify-content-between align-items-end">
        <div className="w-75">
          <div className="d-flex align-items-center">
            <p className="text-light fs-4 me-3">{groupName}</p>
            <IconButton icon={cilPencil} size="lg" onClick={openEdit} />
          </div>
          <p className="text-light mt-1 w-50">{groupDescription}</p>
        </div>
        <div className="add-icon-container add-icon-staff">
          <IconButton
            icon={cilPlus}
            size="xxl"
            className="add-icon"
            onClick={openAddStaff}
          />
        </div>
      </div>
      <div className="mt-4">
      <StaffGroupList
        refreshGroups={refreshGroups}
        groupId={group.id}
        users={usersInGroup}
        isLoadingUsers={isLoadingUsers}
        refreshUsersInGroup={refreshUsersInGroup}
      />
      </div>
      <AddStaffGroup
        closeModal={closeAddStaff}
        visible={visibleAddStaff}
        groupName={group.name}
        groupId={group.id}
        refreshGroups={refreshGroups}
        refreshUsersInGroup={refreshUsersInGroup}
      />
      <EditGroup
        visible={visibleEdit}
        closeModal={closeEdit}
        editMode
        groupDetails={group}
        refreshGroups={refreshGroups}
        refreshGroupInfo={refreshGroupInfo}
      />
    </div>
  );
};

export default GroupContent;