import * as React from "react";
import "../App.scss";
import {NavBar, SideBar} from ".";
import LoadRoutes from "../routes/LoadRoutes";
import {BrowserRouter} from "react-router-dom";
import {useAppSelector} from "../redux/hooks";
import ToasterConfig from "../utils/ToasterConfig";
import AppRouting from "../routes/AppRouting";
// import { PermissionsBar } from "../components";

const DefaultLayout: React.FC = () => {
    const [sideBarVisible, setSideBarVisible] = React.useState(true);

    function toggleSideBarVisible() {
        setSideBarVisible(!sideBarVisible);
    }

    const token = useAppSelector((state) => state.UserSlice.tokens.access);
    const admin = useAppSelector((state) => state.UserSlice.user.isAdmin);

    return (
        <BrowserRouter>
            <ToasterConfig />
            {token && admin ? (
                <div className="default-layout">
                    <SideBar visible={sideBarVisible} />
                    <div className="main-layout">
                        {/*<PermissionsBar />*/}
                        <NavBar toggleSideBarVisible={toggleSideBarVisible} />
                        <LoadRoutes />
                    </div>
                </div>
            ) : (
                <AppRouting/>
            )}
        </BrowserRouter>
    );
};

export default DefaultLayout;
