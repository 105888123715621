import React from 'react';
import {useAppSelector} from "../redux/hooks";

const UsePermission = () => {
    const userSlice: any = useAppSelector((state) => state.UserSlice);

    console.log('userSlice', userSlice)

    const checkPermission = (permission: string) => {
        if(userSlice?.user?.roleId) {
            console.log('userSlice12', permission, userSlice?.permissions?.includes(permission))
            return userSlice?.permissions?.includes(permission);
        } else {
            return true
        }
    }
    return { checkPermission }
};

export default UsePermission;