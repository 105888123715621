import React, {useEffect, useState} from 'react';
import {cilTrash, cilX} from "@coreui/icons";
import {Button, IconButton, Modal, Spinner} from "../Atomics";
import s3 from "../../utils/aws-config";
import HandleUIError from "../../utils/HandleUIError";
import {editAlarmAlert} from "../../services/alarmAlertsService";
import {useDropzone} from 'react-dropzone';
import toast from "react-hot-toast";
import {createActivityReq} from "../../services/activityLogService";

const CreateAlarmAlert = ({visible, closeModal, isEdit, refreshAlarm}) => {
    const [audio, setAudio] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dragging, setDragging] = useState(false);


    const handleFileUpload = async (file) => {
        return new Promise((resolve, reject) => {
            const params = {
                Bucket: "callguardian",
                Key: file.name,
                Body: file,
                ContentType: file.type,
                ContentDisposition: "inline",
            };

            s3.upload(params, (err, data) => {
                if (err) {
                    console.error("Error uploading file:", err);
                    reject(err);
                } else {
                    console.log("File uploaded successfully:", data.Location);
                    resolve(data.Location);
                }
            });
        });
    };


    const onDrop = async (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            setAudio(null);
            setAudio(file);
        }
    };

    const {getRootProps, getInputProps} = useDropzone({onDrop,  accept: 'audio/*'});

    const onPressSend = async () => {
        if (!audio) {
            toast.error('Please upload audio first');
            return;
        }

        setLoading(true)
        const sound = audio ? await handleFileUpload(audio) : null;
        const body = {
            sound: sound
        }
        editAlarmAlert(isEdit?.id, body)
            .then(async (res) => {
                console.log('status1', res)
                setLoading(false)
                refreshAlarm()
                setAudio(null)
                closeModal()
                await createActivityReq('Alarm Alert Updated Successfully')
            })
            .catch((e) => {
                HandleUIError(e);
                setLoading(false)
            });
    }

    return (
        <>
            <Modal
                visible={visible}
                onPressOut={() => {
                    closeModal()
                    setAudio(null)
                }}
            >
                <div className="new-staff-container h-auto">
                    <div className="new-staff-header">
                        <p className="text-light py-2 ps-4 fs-5">
                            Edit Alarm Alert
                        </p>
                        <IconButton
                            icon={cilX} className="me-3"
                            onClick={() => {
                                closeModal()
                                setAudio(null)
                            }}
                        />
                    </div>

                    <div className="py-3">

                        <div className="px-4 mb-5">
                            <div {...getRootProps()}
                                 className="dropzone border d-flex align-items-center justify-content-center"
                                 style={{height: 200}}>
                                <input {...getInputProps()} />
                                {audio ? (
                                        <p className="p-2">
                                            {audio?.name}
                                        </p>
                                    )
                                    : (
                                        <p>Drag & Drop audio file here or click to select</p>
                                    )}
                            </div>
                            {audio && (
                                <p className="p-2 w-100 d-flex align-items-center justify-content-between">
                                    {audio?.name}
                                    <span>
                                        <IconButton icon={cilTrash} className="me-3" onClick={() => setAudio(null)}/>
                                    </span>
                                </p>
                            )}
                        </div>

                        <div className="d-flex justify-content-end px-3 pb-3">
                            <Button onClick={onPressSend} disabled={loading} className="px-4">
                                {loading ? <Spinner/> : "Save"}
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default CreateAlarmAlert;