import * as React from "react";
import "./NewTemplate.scss";

//redux
import { useAppSelector } from "../../redux/hooks";
import { store } from "../../redux/store";
import { saveMessage } from "../../redux/reducers/messageReducer";

//components
import { Modal, Button, Spinner, IconButton, Input } from "../Atomics";
import { TextEditor, TextViewer } from "..";
import { CFormSelect } from "@coreui/react";
import { cilX } from "@coreui/icons";
import { AudioRecorder } from ".";
import { GroupButtons, ButtonForGroup } from "../Atomics/GroupButtons";

//services
import HandleUIError from "../../utils/HandleUIError";
import {
  createMessageForAll,
  createMessageByDeviceId,
  createMessageByDeviceIdsArray,
} from "../../services/messagesService";
import toast from "react-hot-toast";
import { getAllMessageTemplatesByType } from "../../services/messagesService";
import { TemplateMessageResponse } from "../../services/types";

type messageTypeOptions = "audio" | "text";

const NewMessage: React.FC = () => {
  const headerTextRedux = useAppSelector(
    (state) => state.MessageSlice.headerText
  );
  const endpointService = useAppSelector(
    (state) => state.MessageSlice.endpointService
  );
  const visible = useAppSelector((state) => state.MessageSlice.visible);
  const deviceId = useAppSelector((state) => state.MessageSlice.deviceId);
  const [text, setText] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [templates, setTemplates] = React.useState<TemplateMessageResponse[]>();
  const [selectedTemplate, setSelectedTemplate] =
    React.useState<TemplateMessageResponse>();
  const [messageType, setMessageType] =
    React.useState<messageTypeOptions>("text");

  function onPressText() {
    setMessageType("text");
  }

  function onPressAudio() {
    setMessageType("audio");
  }

  function onChangeTemplate(e: any) {
    const selectedTemplate = templates?.find(
      (t) => t.id.toString() === e.target.value
    );
    setSelectedTemplate(selectedTemplate);
  }

  React.useEffect(
    function () {
      getTemplates();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messageType]
  );

  async function getTemplates() {
    try {
      setTemplates([]);
      const templates = await getAllMessageTemplatesByType(messageType);
      setTemplates(templates);
    } catch (error) {
      HandleUIError(error);
    }
  }

  function onChangeText(t: string) {
    setText(t);
  }

  function updateReduxMessageObject() {
    const messageData = {
      endpointService: endpointService,
      headerText: headerTextRedux,
      visible: false,
      deviceId: null,
    };
    store.dispatch(saveMessage(messageData));
  }

  function onClose() {
    updateReduxMessageObject();
    setSelectedTemplate(undefined);
  }

  async function triggerEndpointService() {
    setLoading(true);
    let message: any;
    if (selectedTemplate) {
      message = { messageTemplateId: selectedTemplate.id };
    } else {
      message = { text, type: "text" };
    }
    try {
      // let sendFunction;
      switch (endpointService) {
        case "device":
          typeof deviceId === "number" &&
            (await createMessageByDeviceId({ ...message, deviceId }));
          break;
        case "arrayDevices":
          deviceId &&
            typeof deviceId !== "number" &&
            (await createMessageByDeviceIdsArray({
              ...message,
              devicesIds: deviceId,
            }));
          break;
        default:
          await createMessageForAll(text, "text");
          break;
      }
      updateReduxMessageObject();
      setSelectedTemplate(undefined);
      toast.success("Message sent successfully");
      setLoading(false);
    } catch (e) {
      setLoading(false);
      HandleUIError(e);
    }
  }

  return (
    <Modal visible={visible} onPressOut={onClose}>
      <div className="new-staff-container">
        <div className="new-template-header d-flex justify-content-between align-items-center">
          <p className="text-light fs-5">{headerTextRedux}</p>
          <IconButton icon={cilX} onClick={onClose} />
        </div>
        <div className="px-3 pt-2 d-flex align-items-center justify-content-between">
          <CFormSelect className="template-select" onChange={onChangeTemplate}>
            <option>(Custom Message)</option>
            {templates?.map((template, index) => {
              return (
                <option value={template.id} key={index}>
                  {template.name}
                </option>
              );
            })}
          </CFormSelect>
          <div className="d-flex justify-content-center py-3 mb-2">
            <GroupButtons>
              <ButtonForGroup
                label="Text"
                checked={messageType === "text"}
                onClick={onPressText}
              />
              <ButtonForGroup
                label="Audio"
                checked={messageType === "audio"}
                onClick={onPressAudio}
              />
            </GroupButtons>
          </div>
        </div>

        {messageType === "text" ? (
          <>
            <div className="search-phone d-flex flex-column">
              {!selectedTemplate ? (
                <TextEditor className="" onChangeText={onChangeText} />
              ) : (
                <>
                  {selectedTemplate && (
                    <div className="d-flex align-items-center ms-3">
                      <p className="text-light fs-5 m-0 me-2">Title:</p>
                      <Input
                        className="w-100 me-3 text-light"
                        value={
                          selectedTemplate.title ? selectedTemplate.title : ""
                        }
                      />
                    </div>
                  )}
                  <div className="text-viewer-new-message m-3 pt-2 ps-2">
                    <TextViewer text={selectedTemplate?.text} />
                  </div>
                </>
              )}

              <div className="align-self-end px-3 pb-3">
                <Button
                  onClick={triggerEndpointService}
                  disabled={loading}
                  className="px-4"
                >
                  {loading ? <Spinner /> : "Send"}
                </Button>
              </div>
            </div>
          </>
        ) : (
          <AudioRecorder
            audioTemplate={selectedTemplate}
            closeModal={onClose}
            deviceId={deviceId}
          />
        )}
      </div>
    </Modal>
  );
};

export default NewMessage;
