import React, {useEffect, useState} from 'react';
import {Button, IconButton, Input, Modal, Spinner} from "./Atomics";
import {cilX} from "@coreui/icons";
import {GoogleMap} from "./google-map";

const MapModal = ({visible, closeModal, isEdit, zoom=16}) => {
    const [centers, setCenter] = useState( isEdit ? {lat: parseFloat(isEdit?.latitude), lng: parseFloat(isEdit?.longitude)} : {lat: 0, lng: 0})
    const [zones, setZones] = useState(isEdit?.zone ? [isEdit?.zone] : [])

    console.log('c,z',centers, zones, isEdit)
    return (
        <>
            <Modal visible={visible} onPressOut={closeModal}>
                <div className="new-staff-container h-auto">
                    <div className="new-staff-header">
                        <p className="text-light py-2 ps-4 fs-5">
                            Show Location
                        </p>
                        <IconButton  icon={cilX} className="me-3" onClick={closeModal} />
                    </div>

                    <div className="py-3">
                        {centers && (
                            <div className="map-container rounded mx-2 mx-sm-4 p-1">
                                <GoogleMap
                                    center={centers}
                                    zoom={zoom}
                                    zones={zones}
                                    zonesOrder={zones?.map((zone, index) => ({
                                        id: zone.id,
                                        index: zone.id,
                                    }))}
                                    pointerMap={true}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default React.memo(MapModal);