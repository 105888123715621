import { combineReducers } from "redux";
// reducers
import UserSlice from "./userReducer";
import MessageSlice from "./messageReducer";
import organizationSlice from './organizationReducer';
import zoneSlice from './zoneReducer';
import ResetPassSlice from "./resetPasswordReducer";

export default combineReducers({
  UserSlice,
  MessageSlice,
  zoneSlice,
  organizationSlice,
  resetPass: ResetPassSlice
});
