import axiosConfig from "./axiosConfig";
import { AxiosResponse, AxiosError } from "axios";
import { RefreshTokenResponse } from "./types";
import ApiError from "../utils/ApiError";
import { UserDataState } from "../redux/types";
import {saveUserInfo, saveUserPermission} from "../redux/reducers/userReducer";
import { store } from "../redux/store";
import { OrganizationResponse } from "../services/types";

export async function refreshAuthToken(): Promise<RefreshTokenResponse> {
  try {
    const response = await axiosConfig.get<AxiosResponse>("/me/refresh-token");
    return response.data.data as RefreshTokenResponse;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function getUserFullInfo(): Promise<UserDataState> {
  try {
    const response = await axiosConfig.get<AxiosResponse>("/me");
    const responsePermission = await axiosConfig.get<any>("/me/permissions");
    store.dispatch(saveUserPermission(responsePermission?.data?.data))
    const userData: UserDataState = response.data.data as UserDataState;
    store.dispatch(saveUserInfo(userData));
    return response.data.data as UserDataState;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function getOrganizationInfo(): Promise<OrganizationResponse> {
  try {
    const response = await axiosConfig.get<AxiosResponse>(
      "/admin/organization"
    );
    return response.data.data as OrganizationResponse;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}
