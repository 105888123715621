import axiosConfig from "./axiosConfig";
import { AxiosError, AxiosResponse } from "axios";
import ApiError from "../utils/ApiError";
import { User } from "../components/types";

interface PaginationResponse {
  data: Array<any>;
  numberOfTotalUsers: number;
}

export async function getAdminUsers(
  limit: number,
  page: number
): Promise<Array<User>> {
  try {
    const response = await axiosConfig.get<AxiosResponse>(
      `/admin/users?filter[isAdmin][eq]=1&limit=${limit}&page=${page}`
    );
    return response.data.data as Array<User>;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function searchUsers(
  limit: number,
  page: number,
  search: string
): Promise<PaginationResponse> {
  try {
    const response = await axiosConfig.get<any>(
      `/admin/users?filter[isAdmin][eq]=1&limit=${limit}&page=${page}&freeText=${search}`
    );
    const data = response.data.data as Array<User>;
    return { data, numberOfTotalUsers: response.data.meta.total };
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function associateDevice(
  userId: number,
  IMEI: string,
  phoneNo: string
): Promise<void> {
  try {
    await axiosConfig.post(`admin/users/${userId}/associate-device`, {
      imei: IMEI,
      phoneNo: phoneNo,
    });
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function associateNewDevice(
  userId: number,
  IMEI: string,
  ICCID: string,
  serialNo: string,
  organizationId: number
): Promise<void> {
  try {
    await axiosConfig.post(`/devices/activate`, {
      serialNo: serialNo,
      iccid: ICCID,
      imei: IMEI,
      ownerId: userId,
      organizationId,
    });
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function addUserAdminAlt(
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  password: string,
  role_id: any,
): Promise<object> {
  try {
    let response = await axiosConfig.post(`admin/users`, {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      password: password,
      roleId: role_id,
    });
    return response;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function addUserAdmin(
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  password: string,
  role_id: any,
): Promise<void> {
  try {
    await axiosConfig.post(`admin/users`, {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      password: password,
      roleId: role_id,
    });
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function editUserAdmin(
  userId: number,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  role_id: any,
  password?: any,
): Promise<void> {
  try {
    const data: any = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      roleId: role_id,

    };
    if(password) {
      data['password'] = password
    }
    
    await axiosConfig.patch<AxiosResponse>(`/admin/users/${userId}`, data);
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function deleteUserAdmin(userId: number): Promise<void> {
  try {
    await axiosConfig.delete<AxiosResponse>(`/admin/users/${userId}`);
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}
