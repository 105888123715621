import * as React from "react";
import "../../views/Staff.scss";

import {cilPencil, cilTrash, cilUser} from "@coreui/icons";
import { IconButton } from "../Atomics";
import { RolesUser } from "../types";
import EditRole from "./EditRole";
import {useHistory} from "react-router-dom";
import {CAvatar} from "@coreui/react";
import moment from "moment";
import {convertUTCtoLocale} from "../../utils/formatConverters";
//service

interface ParentsRowProps {
  parent: RolesUser;
  removeUser: (id: number) => void;
  refreshParents: () => void
}

const RolesRow: React.FC<ParentsRowProps> = ({ parent, removeUser, refreshParents }) => {
    const navigate: any = useHistory();
  const [visibleEditParent, setVisibleEditParent] = React.useState(false);

  function onPressAdd() {
    setVisibleEditParent(!visibleEditParent);
  }
    function deviceIsOnline(deviceHour: string) {
        let hour = moment();
        let hourDevice = moment(convertUTCtoLocale(deviceHour));
        if (hour.diff(hourDevice) <= 300000) {
            return true;
        } else {
            return false;
        }
    }


  return (
    <>
        <div className="d-flex py-3 user-information-container justify-content-between px-3 align-items-center table-size">
            <div className="section-width-parents-1 d-flex justify-content-start">
                <div className="d-flex align-items-center" style={{gap: 10}}>
                    <CAvatar
                        color="primary"
                        textColor="white"
                        size="lg"
                        status={
                            deviceIsOnline(parent.last_login)
                                ? "success"
                                : "danger"
                        }
                    >
                        {parent.firstName ? parent.firstName[0].toUpperCase() : "-"}
                        {parent.lastName ? parent.lastName[0].toUpperCase() : "-"}
                    </CAvatar>
                    <p className="text-light word-break text-start fw-bold">
                        {parent.firstName ? parent.firstName + " " : "-"}
                        {parent.lastName ? parent.lastName : "-"}
                    </p>
                </div>
            </div>
            <div className="section-width-parents-1 d-flex justify-content-start">
                <p className="text-light word-break text-center fw-bold">
                    {parent.email ? parent.email : '-'}
                </p>
            </div>
            <div className=" d-flex justify-content-start w-13">
                <p className="text-light word-break text-center fw-bold">
                    {parent.phone ? parent.phone : '-'}
                </p>
            </div>
            <div className=" d-flex justify-content-start w-13">
                <p className="text-light word-break text-center fw-bold">
                    {parent.last_login ? moment(parent.last_login).fromNow() : '-'}
                </p>
            </div>
            <div className=" d-flex justify-content-start w-13">
                <p className="text-light word-break text-center fw-bold">
                    {parent?.createdAt ? moment(parent.createdAt).fromNow() : '-'}
                </p>
            </div>
            <div className="d-flex justify-content-end align-items-center w-13">
                <div className="p-1">
                    <button
                        className="btn btn-sm btn-primary"
                        onClick={() => navigate.push(`/activity-logs/${parent?.id}`)}
                    >
                        View Logs
                    </button>
                </div>
            </div>
        </div>
        <EditRole
          visible={visibleEditParent}
          closeModal={onPressAdd}
          refreshParents={refreshParents}
          parentData={{
              parentId: parent.id,
              name: parent.name,
              email: parent.email,
              phone: parent.phone
            }}
          />
    </>
  );
};

export default RolesRow;
