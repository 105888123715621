import axiosConfig from "./axiosConfig";
import { AxiosError, AxiosResponse } from "axios";
import ApiError from "../utils/ApiError";
import {Roles} from "../components/types";

interface PaginationResponse {
    data: Array<Roles>;
    numberOfTotalParents: number;
}

export async function searchParents(
    limit: number,
    page: number,
    search: string
  ): Promise<PaginationResponse> {
    try {
      const response = await axiosConfig.get<any>(
        `/admin/roles`
      );
      const data = response.data.data as Array<Roles>;
      return { data, numberOfTotalParents: response.data.meta.total };
    } catch (e) {
      const err = e as AxiosError | Error;
      throw new ApiError(err);
    }
  }

export async function getParents(
  limit: number,
  page: number
): Promise<Array<Roles>> {
    try {
        const response = await axiosConfig.get<AxiosResponse>(`/admin/roles`);
        return response.data.data as Array<Roles>;
    } catch (e) {
        const err = e as AxiosError | Error;
        throw new ApiError(err);
    }
}

export async function searchRolesUser(
    id: number,
    limit: number,
    page: number,
    search: string,
): Promise<PaginationResponse> {
    try {
        const response = await axiosConfig.get<any>(
            `/admin/roles-user-list/${id ? id : ''}`
        );
        const data = response.data.data as Array<Roles>;
        return { data, numberOfTotalParents: response.data.meta.total };
    } catch (e) {
        const err = e as AxiosError | Error;
        throw new ApiError(err);
    }
}

export async function getRolesUser(
    id: number,
    limit: number,
    page: number
): Promise<Array<Roles>> {
    try {
        const response = await axiosConfig.get<AxiosResponse>(`/admin/roles-user-list/${id ? id : ''}`);
        return response.data.data as Array<Roles>;
    } catch (e) {
        const err = e as AxiosError | Error;
        throw new ApiError(err);
    }
}

export async function saveParents(name: string, email: string | null, phone: string | null, group?: string | null, roleType?: string | null): Promise<void> {
    try {
        const body = {
            role_name: name,
            email,
            phone,
            group_name: group,
            role_type: roleType,
          };
          await axiosConfig.post("admin/roles", body);
    } catch (e) {
        const err = e as AxiosError | Error;
        throw new ApiError(err);
    }
}

export async function savePermission(id: number, permissions: any): Promise<void> {
    try {
        const body = {
            permissions: permissions
        };
        const res: any = await axiosConfig.post(`/admin/update-permission/${id}`, body);
        return  res;
    } catch (e) {
        const err = e as AxiosError | Error;
        throw new ApiError(err);
    }
}

export async function saveCsv(file: any, id: number): Promise<void> {
    try {
        const body = {
            url: file
        };
        await axiosConfig.post(`/admin/upload-user-list/${id}`, body);
    } catch (e) {
        const err = e as AxiosError | Error;
        throw new ApiError(err);
    }
}

export async function removeContactFromParents(
  parentId: number
): Promise<Array<any>> {
  try {
    const response = await axiosConfig.delete<AxiosResponse>(
      `/admin/roles/${parentId}`
    );
    return response.data.data as Array<any>;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function editContactFromParents(
  parentId: number,
  name: string,
  email: string | null,
  phone: string | null,
  group?: string | null,
  role?: string | null,
): Promise<Array<any>> {
  try {
    const body = {
      role_name: name,
      email,
      phone,
      group_name: group,
      role_type: role,
    };
    const response = await axiosConfig.put<AxiosResponse>(
      `/admin/roles/${parentId}`,
      body
    );
    return response.data.data as Array<any>;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}