function setTextColorByBackground(hex: string) {
  function hex_to_rgb(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  const rgb = hex_to_rgb(hex);
  if (rgb) {
    const luminance = 0.2126 * rgb["r"] + 0.7152 * rgb["g"] + 0.0722 * rgb["b"];
    return luminance < 140 ? "#ffffff" : "#000000";
  }
}

export const getButtonStyle = (color: string) => {
  return {
    backgroundColor: color,
    borderColor: color,
    color: setTextColorByBackground(color),
  };
};
