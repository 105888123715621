import React from 'react';
import {IconButton, Modal} from "../Atomics";
import {cilX} from "@coreui/icons";
import moment from "moment";

const StaffView = ({isView, visible, closeModal}) => {
    return (
        <>
            <Modal visible={visible} onPressOut={closeModal}>
                <div className="new-staff-container h-auto">
                    <div className="new-staff-header">
                        <p className="text-light py-2 ps-4 fs-5">
                            User Info
                        </p>
                        <IconButton icon={cilX} className="me-3" onClick={closeModal}/>
                    </div>

                    {console.log('user===', isView)}
                    <div className="py-3 px-3" style={{height: 450, overflowX: 'auto'}}>
                        {isView?.userEmergencyContacts?.length > 0 && (
                            <>
                                <hr/>
                                <div className="d-flex">
                                    <h5>
                                        User Alert Categories
                                    </h5>
                                </div>
                                <hr/>
                                {isView?.userAlertCategories?.map((item) => (
                                    <div className="">
                                        <div className="row d-flex justify-content-between mb-2">
                                            <div className="col-auto">
                                                <p className="mb-0 text-capitalize">
                                                    {item?.alert_category?.name}
                                                </p>
                                            </div>
                                            <div className="col-auto fw-bold">
                                                <p className="mb-0">{item?.is_enable ? 'ON' : 'OFF'}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}

                        <hr/>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>
                                User Emergency Notification Type
                            </h5>
                            <p className="small fw-bold">
                                {/*{ isView?.userEmergencyNotificationType?.updatedAt && moment(isView?.userEmergencyNotificationType?.updatedAt).startOf('hour').fromNow() }*/}
                            </p>
                        </div>
                        <hr/>
                        <div className="border p-2 mb-2">
                            <div className="d-flex justify-content-between mb-2">
                                <div className="col-auto">
                                    <p className="mb-0">Email Alert</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">{isView?.userEmergencyNotificationType?.email_alert ? 'ON' : 'OFF'}</p>
                                </div>
                            </div>
                            <hr className="my-1" />
                            <div className="d-flex justify-content-between">
                                <div className="col-auto">
                                    <p className="mb-0">Last On Timing</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">
                                        {isView?.userEmergencyNotificationType?.email_alert_off ? moment(isView?.userEmergencyNotificationType?.email_alert_off).format('MMMM Do YYYY, h:mm:ss a') : '-'}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="col-auto">
                                    <p className="mb-0">Last Off Timing</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">
                                        {isView?.userEmergencyNotificationType?.email_alert_on ? moment(isView?.userEmergencyNotificationType?.email_alert_on).format('MMMM Do YYYY, h:mm:ss a') : '-'}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="border p-2 mb-2">
                            <div className="d-flex justify-content-between mb-2">
                                <div className="col-auto">
                                    <p className="mb-0">Emergency Contact</p>
                                </div>
                                <div className="col-auto ">
                                    <p className="mb-0 fw-bold">{isView?.userEmergencyNotificationType?.emergency_contact ? 'ON' : 'OFF'}</p>
                                </div>
                            </div>
                            <hr className="my-1" />
                            <div className="d-flex justify-content-between">
                                <div className="col-auto">
                                    <p className="mb-0">Last On Timing</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">
                                        {isView?.userEmergencyNotificationType?.emergency_contact_off ? moment(isView?.userEmergencyNotificationType?.emergency_contact_off).format('MMMM Do YYYY, h:mm:ss a') : '-'}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="col-auto">
                                    <p className="mb-0">Last Off Timing</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">
                                        {isView?.userEmergencyNotificationType?.emergency_contact_on ? moment(isView?.userEmergencyNotificationType?.emergency_contact_on).format('MMMM Do YYYY, h:mm:ss a') : '-'}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="border p-2 mb-2">
                            <div className="d-flex justify-content-between mb-2">
                                <div className="col-auto">
                                    <p className="mb-0">Sms Alert</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">{isView?.userEmergencyNotificationType?.sms_alert ? 'ON' : 'OFF'}</p>
                                </div>
                            </div>
                            <hr className="my-1" />
                            <div className="d-flex justify-content-between">
                                <div className="col-auto">
                                    <p className="mb-0">Last On Timing</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">
                                        {isView?.userEmergencyNotificationType?.sms_alert_off ? moment(isView?.userEmergencyNotificationType?.sms_alert_off).format('MMMM Do YYYY, h:mm:ss a') : '-'}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="col-auto">
                                    <p className="mb-0">Last Off Timing</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">
                                        {isView?.userEmergencyNotificationType?.sms_alert_on ? moment(isView?.userEmergencyNotificationType?.sms_alert_on).format('MMMM Do YYYY, h:mm:ss a') : '-'}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="border p-2 mb-2 d-none">
                            <div className="d-flex justify-content-between mb-2">
                                <div className="col-auto">
                                    <p className="mb-0">Sms Alert</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">{isView?.userEmergencyNotificationType?.sms_alert ? 'ON' : 'OFF'}</p>
                                </div>
                            </div>
                            <hr className="my-1" />
                            <div className="d-flex justify-content-between">
                                <div className="col-auto">
                                    <p className="mb-0">Last On Timing</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">
                                        {isView?.userEmergencyNotificationType?.sms_alert_off ? moment(isView?.userEmergencyNotificationType?.sms_alert_off).format('MMMM Do YYYY, h:mm:ss a') : '-'}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="col-auto">
                                    <p className="mb-0">Last Off Timing</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">
                                        {isView?.userEmergencyNotificationType?.sms_alert_on ? moment(isView?.userEmergencyNotificationType?.sms_alert_on).format('MMMM Do YYYY, h:mm:ss a') : '-'}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <hr/>
                        <div className="d-flex justify-content-between">
                            <h5>
                                Location Setting
                            </h5>
                            <p className="small fw-bold">
                                {/*{ isView?.userLocationSetting?.updatedAt && moment(isView?.userLocationSetting?.updatedAt).startOf('hour').fromNow() }*/}
                            </p>
                        </div>
                        <hr/>
                        <div className="border p-2 mb-2">
                            <div className="d-flex justify-content-between mb-2">
                                <div className="col-auto">
                                    <p className="mb-0">Off Site Location</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">{isView?.userLocationSetting?.off_site_location ? 'ON' : 'OFF'}</p>
                                </div>
                            </div>
                            <hr className="my-1" />
                            <div className="d-flex justify-content-between">
                                <div className="col-auto">
                                    <p className="mb-0">Last On Timing</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">
                                        {isView?.userLocationSetting?.off_site_location_off ? moment(isView?.userLocationSetting?.off_site_location_off).format('MMMM Do YYYY, h:mm:ss a') : '-'}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="col-auto">
                                    <p className="mb-0">Last Off Timing</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">
                                        {isView?.userLocationSetting?.off_site_location_on ? moment(isView?.userLocationSetting?.off_site_location_on).format('MMMM Do YYYY, h:mm:ss a') : '-'}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="border p-2 mb-2">
                            <div className="d-flex justify-content-between mb-2">
                                <div className="col-auto">
                                    <p className="mb-0">On Site Location</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">{isView?.userLocationSetting?.on_site_location ? 'ON' : 'OFF'}</p>
                                </div>
                            </div>
                            <hr className="my-1" />
                            <div className="d-flex justify-content-between">
                                <div className="col-auto">
                                    <p className="mb-0">Last On Timing</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">
                                        {isView?.userLocationSetting?.on_site_location_off ? moment(isView?.userLocationSetting?.on_site_location_off).format('MMMM Do YYYY, h:mm:ss a') : '-'}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="col-auto">
                                    <p className="mb-0">Last Off Timing</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">
                                        {isView?.userLocationSetting?.on_site_location_on ? moment(isView?.userLocationSetting?.on_site_location_on).format('MMMM Do YYYY, h:mm:ss a') : '-'}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="border p-2 mb-2">
                            <div className="d-flex justify-content-between mb-2">
                                <div className="col-auto">
                                    <p className="mb-0">Audio Alerts</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">{isView?.userLocationSetting?.audio_alerts ? 'ON' : 'OFF'}</p>
                                </div>
                            </div>
                            <hr className="my-1" />
                            <div className="d-flex justify-content-between">
                                <div className="col-auto">
                                    <p className="mb-0">Last On Timing</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">
                                        {isView?.userLocationSetting?.audio_alerts_off ? moment(isView?.userLocationSetting?.audio_alerts_off).format('MMMM Do YYYY, h:mm:ss a') : '-'}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="col-auto">
                                    <p className="mb-0">Last Off Timing</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">
                                        {isView?.userLocationSetting?.audio_alerts_on ? moment(isView?.userLocationSetting?.audio_alerts_on).format('MMMM Do YYYY, h:mm:ss a') : '-'}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <hr/>
                        <div className="d-flex justify-content-between">
                            <h5>
                                Notifications
                            </h5>
                            <p className="small fw-bold">
                                {/*{ isView?.userNotificationType?.updatedAt && moment(isView?.userNotificationType?.updatedAt).startOf('hour').fromNow() }*/}
                            </p>
                        </div>
                        <hr/>
                        <div className="border p-2 mb-2">
                            <div className="d-flex justify-content-between">
                                <div className="col-auto">
                                    <p className="mb-0">App Notification</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">{isView?.userNotificationType?.app_notification ? 'ON' : 'OFF'}</p>
                                </div>
                            </div>
                            <hr className="my-1" />
                            <div className="d-flex justify-content-between">
                                <div className="col-auto">
                                    <p className="mb-0">Last On Timing</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">
                                        {isView?.userNotificationType?.app_notification_off ? moment(isView?.userNotificationType?.app_notification_off).format('MMMM Do YYYY, h:mm:ss a') : '-'}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="col-auto">
                                    <p className="mb-0">Last Off Timing</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">
                                        {isView?.userNotificationType?.app_notification_on ? moment(isView?.userNotificationType?.app_notification_on).format('MMMM Do YYYY, h:mm:ss a') : '-'}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="border p-2 mb-2">
                            <div className="d-flex justify-content-between mb-2">
                                <div className="col-auto">
                                    <p className="mb-0">Audio Alert</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">{isView?.userNotificationType?.audio_alert ? 'ON' : 'OFF'}</p>
                                </div>
                            </div>
                            <hr className="my-1" />
                            <div className="d-flex justify-content-between">
                                <div className="col-auto">
                                    <p className="mb-0">Last On Timing</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">
                                        {isView?.userNotificationType?.audio_alert_off ? moment(isView?.userNotificationType?.audio_alert_off).format('MMMM Do YYYY, h:mm:ss a') : '-'}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="col-auto">
                                    <p className="mb-0">Last Off Timing</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">
                                        {isView?.userNotificationType?.audio_alert_on ? moment(isView?.userNotificationType?.audio_alert_on).format('MMMM Do YYYY, h:mm:ss a') : '-'}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="border p-2 mb-2">
                            <div className="d-flex justify-content-between mb-2">
                                <div className="col-auto">
                                    <p className="mb-0">Recorded Calls</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">{isView?.userNotificationType?.recorded_calls ? 'ON' : 'OFF'}</p>
                                </div>
                            </div>
                            <hr className="my-1" />
                            <div className="d-flex justify-content-between">
                                <div className="col-auto">
                                    <p className="mb-0">Last On Timing</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">
                                        {isView?.userNotificationType?.recorded_calls_off ? moment(isView?.userNotificationType?.recorded_calls_off).format('MMMM Do YYYY, h:mm:ss a') : '-'}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="col-auto">
                                    <p className="mb-0">Last Off Timing</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">
                                        {isView?.userNotificationType?.recorded_calls_on ? moment(isView?.userNotificationType?.recorded_calls_on).format('MMMM Do YYYY, h:mm:ss a') : '-'}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="border p-2 mb-2">
                            <div className="d-flex justify-content-between mb-2">
                                <div className="col-auto">
                                    <p className="mb-0">Sms Alert</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">{isView?.userNotificationType?.sms_alert ? 'ON' : 'OFF'}</p>
                                </div>
                            </div>
                            <hr className="my-1" />
                            <div className="d-flex justify-content-between">
                                <div className="col-auto">
                                    <p className="mb-0">Last On Timing</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">
                                        {isView?.userNotificationType?.sms_alert_off ? moment(isView?.userNotificationType?.sms_alert_off).format('MMMM Do YYYY, h:mm:ss a') : '-'}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="col-auto">
                                    <p className="mb-0">Last Off Timing</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">
                                        {isView?.userNotificationType?.sms_alert_on ? moment(isView?.userNotificationType?.sms_alert_on).format('MMMM Do YYYY, h:mm:ss a') : '-'}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="border p-2 mb-2">
                            <div className="d-flex justify-content-between mb-2">
                                <div className="col-auto">
                                    <p className="mb-0">Text Alert</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">{isView?.userNotificationType?.text_alert ? 'ON' : 'OFF'}</p>
                                </div>
                            </div>
                            <hr className="my-1" />
                            <div className="d-flex justify-content-between">
                                <div className="col-auto">
                                    <p className="mb-0">Last On Timing</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">
                                        {isView?.userNotificationType?.text_alert_off ? moment(isView?.userNotificationType?.text_alert_off).format('MMMM Do YYYY, h:mm:ss a') : '-'}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="col-auto">
                                    <p className="mb-0">Last Off Timing</p>
                                </div>
                                <div className="col-auto fw-bold">
                                    <p className="mb-0">
                                        {isView?.userNotificationType?.text_alert_on ? moment(isView?.userNotificationType?.text_alert_on).format('MMMM Do YYYY, h:mm:ss a') : '-'}
                                    </p>
                                </div>
                            </div>
                        </div>

                        {isView?.userEmergencyContacts?.length > 0 && (
                            <>
                                <hr/>
                                <div className="d-flex justify-content-between">
                                    <h5>
                                        User Emergency Contacts
                                    </h5>
                                </div>

                                <hr/>
                                {isView?.userEmergencyContacts?.map((item) => (
                                    <div className="p-2 border border-dark">
                                        <div className="row d-flex justify-content-between mb-2">
                                            <div className="col-auto">
                                                <p className="mb-0">First Name</p>
                                            </div>
                                            <div className="col-auto fw-bold">
                                                <p className="mb-0">{item?.first_name || '-'}</p>
                                            </div>
                                        </div>
                                        <div className="row d-flex justify-content-between mb-2">
                                            <div className="col-auto">
                                                <p className="mb-0">Last Name</p>
                                            </div>
                                            <div className="col-auto fw-bold">
                                                <p className="mb-0">{item?.last_name || '-'}</p>
                                            </div>
                                        </div>
                                        <div className="row d-flex justify-content-between mb-2">
                                            <div className="col-auto">
                                                <p className="mb-0">Email</p>
                                            </div>
                                            <div className="col-auto fw-bold">
                                                <p className="mb-0">{item?.email || '-'}</p>
                                            </div>
                                        </div>
                                        <div className="row d-flex justify-content-between mb-2">
                                            <div className="col-auto">
                                                <p className="mb-0">Phone No</p>
                                            </div>
                                            <div className="col-auto fw-bold">
                                                <p className="mb-0">{item?.phone || '-'}</p>
                                            </div>
                                        </div>
                                        <div className="row d-flex justify-content-between mb-2">
                                            <div className="col-auto">
                                                <p className="mb-0">User Relation</p>
                                            </div>
                                            <div className="col-auto fw-bold">
                                                <p className="mb-0">{(item?.relation &&  item?.relation?.name) ? item?.relation?.name : '-'}</p>
                                            </div>
                                        </div>
                                        <div className="row d-flex justify-content-between mb-2">
                                            <div className="col-auto">
                                                <p className="mb-0">Created At</p>
                                            </div>
                                            <div className="col-auto fw-bold">
                                                <p className="mb-0">{ item?.createdAt ? moment(item?.createdAt).fromNow() : '-' }</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default StaffView;