import React, {useEffect, useState} from 'react';
import {IconButton, MenuHeader, PageContainer} from "../components/Atomics";
import {CSpinner} from "@coreui/react";
import {cilPencil} from "@coreui/icons";
import {getAlarmAlerts} from "../services/alarmAlertsService";
import HandleUIError from "../utils/HandleUIError";
import {getActivityReq} from "../services/activityLogService";
import {useParams} from "react-router-dom";
import moment from "moment";

const ActivityLogs = () => {
    const params: any = useParams()
    const [loading, setLoading] = useState(false)
    const [logs, setLogs] = useState<any>([]);

    const getActivityLogs = () => {
        getActivityReq(params?.id)
            .then((res: any) => {
                console.log('users1', res)
                setLogs(res);
            })
            .catch((e: any) => {
                HandleUIError(e);
            });
    }

    useEffect(() => {
        getActivityLogs()
    }, []);
    return (
        <>
            <div className="bg-main pb-5 over-flow-auto h-100">
                <PageContainer className="page-container-staff d-flex flex-column justify-content-start pb-3 mx-4">
                    <div className="row align-items-end justify-content-between mb-4">
                        <div className="col-auto">
                            <h3 className="text-white mb-0">
                                Activity Logs
                            </h3>
                        </div>
                    </div>
                    <div className="">
                        <>
                            <MenuHeader className="d-flex pt-3 pb-3 px-2 justify-content-around">
                                <p className="text-color col d-none d-sm-block px-2">
                                    Message
                                </p>
                                <p className="text-color col d-none d-sm-block">
                                    CreatedAt
                                </p>
                                <div className="section-width-parents-4 d-flex justify-content-end align-items-center d-none">
                                    &nbsp;
                                </div>
                                {/*<p className="text-color col d-none d-sm-block">
                                    Action
                                </p>*/}
                                {/*<p className="text-color section-width-parents-4 d-none d-sm-block"></p>*/}
                            </MenuHeader>
                            {loading ? (
                                <div className="d-flex justify-content-center mt-4">
                                    <CSpinner variant="grow" />
                                </div>
                            ) : (
                                <>
                                    {logs?.length > 0 ? (
                                        <>
                                            {logs.map((item: any) => (
                                                <div key={item?.id} className="d-flex py-3 px-3 user-information-container justify-content-around">
                                                    <div className="col d-flex justify-content-start">
                                                        <p className="text-light word-break">
                                                            {item?.description ? item?.description : '-'}
                                                        </p>
                                                    </div>
                                                    <div className="col d-flex justify-content-start">
                                                        <p className="text-light word-break">
                                                            {item?.createdAt ? moment(item?.createdAt).fromNow() : '-'}
                                                        </p>
                                                    </div>

                                                </div>
                                            ))}
                                        </>
                                    ) : <h5 className="text-white text-center py-2">DATA NOT FOUND</h5>}
                                </>
                            )}
                        </>
                    </div>
                </PageContainer>
            </div>
        </>
    );
};

export default ActivityLogs;