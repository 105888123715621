import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import apiConfig from "../config";
import { store } from "../redux/store";
import {getUserFullInfo, refreshAuthToken} from "./loggedUserService";
import { updateTokens } from "../redux/reducers/userReducer";
import { signOut } from "../services/SecurityService";

const axiosConfig = axios.create({
  baseURL: apiConfig.apiBaseUrl,
  responseType: "json",
  headers: { "Content-Type": "application/json" },
});

interface AxiosRequestAuth extends AxiosRequestConfig {
  headers?: {
    Authorization?: string;
  };
}
interface AxiosInterceptorError extends AxiosRequestConfig {
  _retry: boolean;
}
interface AxiosResponseData extends AxiosResponse {
  data: {
    code: number;
  };
}
interface AxiosErrorResponseUrl extends AxiosError {
  request?: {
    responseURL: string;
  };
  config: AxiosInterceptorError;
  response: AxiosResponseData;
}

axiosConfig.interceptors.request.use(function (config: AxiosRequestAuth) {
  /**
   * Seteamos el refresh token como header solo para el endpoint /me/refresh-token,
   * en caso contrario seguimos utilizando el access token.
   */
  const isRefresh = config.url === "/me/refresh-token";
  const accessToken = store.getState().UserSlice.tokens.access;
  const refreshToken = store.getState().UserSlice.tokens.refresh;
  const token = `Bearer ${isRefresh ? refreshToken : accessToken}`;
  if (config.headers) {
    config.headers.Authorization = token;
  } else {
    const headers = {
      Authorization: token,
    };
    config.headers = headers;
  }

  return config;
});

let secondAPICalled = false;

axiosConfig.interceptors.response.use(
  function (response: any) {
    if(response?.data?.status == 200 && !secondAPICalled && !response?.data?.data?.tokens) {
      secondAPICalled = true;
      console.log('response-me', response, secondAPICalled)
      setTimeout(async () => {
        await getUserFullInfo()
            .then(async (res: any) => {
              console.log('response-me-to', res)
              if (!res?.active || !res?.organization?.active) {
                await signOut()
                window.location.replace("/");
              }
            })
        secondAPICalled = false;
      }, 2000)
    }
    return response;
  },
  async function (error: AxiosErrorResponseUrl) {
    const originalRequest = error.config;
    originalRequest._retry = true;
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data.code === 10001
    ) {
      if (
        error.request &&
        error.request.responseURL.includes("/me/refresh-token")
      ) {
        signOut().catch((e) => console.warn(e));
        return;
      }
      /**
       * si el token es invalido, vamos a buscar los nuevos tokens a
       * /me/refresh-token y los actualizamos en el store mediante el action updateTokens
       */
      try {
        const refreshData = await refreshAuthToken();
        const payload = {
          access: refreshData.tokens.access,
          refresh: refreshData.tokens.refresh,
        };
        store.dispatch(updateTokens(payload));
        return axiosConfig(originalRequest);
      } catch (e) {
        const err = e as AxiosError | Error;
        throw new Error(err.message);
      }
    }
    return Promise.reject(error);
  }
);
export default axiosConfig;
