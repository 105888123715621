import * as React from "react";
import "../../views/Staff.scss";

import { cilPencil, cilTrash, cilUser } from "@coreui/icons";
import { IconButton } from "../Atomics";
import { Roles } from "../types";
import EditEmergency from "./EditEmergency";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveRolesUser } from "../../redux/reducers/userReducer";
import usePermission from "../../hooks/usePermission";
import {convertUTCtoLocale} from "../../utils/formatConverters";
import moment from "moment";
//service

interface ParentsRowProps {
  parent: Roles;
  removeUser: (id: number) => void;
  refreshParents: () => void;
}

const EmergencyRow: React.FC<ParentsRowProps> = ({
  parent,
  removeUser,
  refreshParents,
}) => {
    const { checkPermission } = usePermission()
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [visibleEditParent, setVisibleEditParent] = React.useState(false);
    const localeDate = convertUTCtoLocale(parent.createdAt);
  function onPressAdd() {
    setVisibleEditParent(!visibleEditParent);
  }
  return (
    <>
      <div className="d-flex py-3 user-information-container justify-content-around">
        <div className="section-width-parents-1 d-flex justify-content-start">
          <p className="text-light word-break">{parent?.name}</p>
        </div>
        <div className="section-width-parents-1 d-flex justify-content-start">
          <p className="text-light word-break">{parent?.phone_number}</p>
        </div>
          <div className="section-width-parents-1 d-flex justify-content-start">
              <p className="text-light word-break">
                  {moment(localeDate).format("L")} At {moment(localeDate).format("LT")}
              </p>
          </div>
        <div className="section-width-parents-4 d-flex justify-content-end align-items-center">
          {/* <div className="margin-icon">
            <IconButton
              icon={cilUser}
              size="lg"
              onClick={() => {
                dispatch(saveRolesUser({ id: parent.id ? parent.id : 0 }));
                navigate.push(`/roles-user/${parent.id}`);
              }}
            />
          </div> */}
            {checkPermission("Delete Emergency Contact") && (
                <div className="margin-icon">
                    <IconButton
                        icon={cilPencil}
                        size="lg"
                        onClick={() => onPressAdd()}
                    />
                </div>
            )}
            {checkPermission("Delete Emergency Contact") && (
                <div>
                    <IconButton
                        icon={cilTrash}
                        size="lg"
                        className="ms-1"
                        onClick={() => removeUser(parent.id)}
                    />
                </div>
            )}
        </div>
      </div>
      <EditEmergency
        visible={visibleEditParent}
        closeModal={onPressAdd}
        refreshParents={refreshParents}
        parentData={{
          parentId: parent.id,
          name: parent.name,
          email: parent.email,
          phone: parent.phone_number,
        }}
      />
    </>
  );
};

export default EmergencyRow;
