import * as React from "react";

import { MenuHeader } from "../Atomics";
import CIcon from "@coreui/icons-react";
import { cilPeople } from "@coreui/icons";
import "./StaffList.css";
import { DeviceRowGroup } from ".";
import toast from "react-hot-toast";

// interfaces
import { User } from "../types";
import { removeUserFromGroup } from "../../services/groupService";
import confirmationDialog from "../toast-dialogs/ConfirmationDialog";
import HandleUIError from "../../utils/HandleUIError";
import { CSpinner } from "@coreui/react";

interface Props {
  refreshGroups: () => void;
  refreshUsersInGroup: () => void;
  isLoadingUsers: boolean;
  users: Array<User>;
  groupId: number;
}

const StaffGroupList: React.FC<Props> = ({ 
  refreshGroups,
  refreshUsersInGroup,
  isLoadingUsers,
  users,
  groupId, 
}) => {

  async function removeUser(userId: number): Promise<void> {
    try {
      await toast.promise(removeUserFromGroup(groupId, userId), {
        loading: "Removing user...",
        success: "User removed from group",
        error: "Error removing user from group",
      });
      refreshUsersInGroup();
      refreshGroups();
    } catch (e) {
      HandleUIError(e);
    }
  }

  function onPressRemoveUser(userId: number) {
    const confirmationDialogOptions = {
      title:
        "Are you sure you want to remove this user from the group?",
      confirmAction: () => removeUser(userId),
    };
    confirmationDialog(confirmationDialogOptions);
  }

  return (
    <>
      <MenuHeader className="d-flex pt-3 pb-3">
        <CIcon
          icon={cilPeople}
          size="xl"
          className="staff-icon section-width-1"
        />
        <p className="text-color section-width-2 d-block d-sm-none justify-content-center">Users</p>
        <p className="text-color section-width-2 d-none d-sm-block justify-content-center">User</p>
        <p className="text-color section-width-3 d-none d-sm-block justify-content-center">Device</p>
        <p className="text-color section-width-4 d-none d-sm-block justify-content-center">Battery</p>
        <p className="text-color section-width-5 d-none d-sm-block justify-content-center">
          Last connected
        </p>
        <p className="text-color section-width-6 d-none d-sm-block">
          Device IMEI
        </p>
        <p className="text-color section-width-7 d-none d-sm-block"></p>
      </MenuHeader>   
      {isLoadingUsers ? (
        <div className="d-flex justify-content-center">
          <CSpinner variant="grow" className="mt-5"/>
        </div>
      ):( 
        <>
        {users.length > 0 ? (
          <>
          {users.map((user, index) => {
            return(
              <DeviceRowGroup
              user={user}
              key={index}
              removeUser={() => onPressRemoveUser(user.id)}
              />
            );
          })}
          </>
        ):(
          <div className="d-flex justify-content-center">
            <p className="text-light fs-5 ms-5 me-2 pt-2">No users added yet</p>
          </div>
        )}
        
        </>
      )}
    </>
  );
};

export default StaffGroupList;