import * as React from "react";
import "./Staff.scss";

//components
import { Pagination } from "../components";
import { NewMessage } from "../components/messages";
import { PageContainer } from "../components/Atomics";
import CIcon from "@coreui/icons-react";
import { cilPlus } from "@coreui/icons";
import HandleUIError from "../utils/HandleUIError";
// types
import { RolesUser } from "../components/types";
// services
import { getParents, getRolesUser, searchParents, searchRolesUser } from "../services/rolesService";
import RolesList from "../components/roles-user/RolesList";
import NewRole from "../components/roles-user/NewRole"
import useDebounce from "../hooks/useDebounce";
import { useLocation, useParams } from "react-router-dom";
import { StaffList } from "../components/staff";
import { searchUsers, searchUsersAlt } from "../services/staffService";
import { useEffect } from "react";
import usePermission from "../hooks/usePermission";

const RolesUserComp: React.FC = () => {
  const { checkPermission } = usePermission()
  const getId: any = useParams()
  const location = useLocation();
  let data: any = location.state;
  const [visibleNewParent, setVisibleNewParent] = React.useState(false);
  const [currentParents, setCurrentParents] = React.useState<Array<RolesUser>>([]);
  const [parents, setParents] = React.useState<Array<RolesUser>>();
  const [numberOfTotalParents, setNumberOfTotalParents] = React.useState(0);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [parentsPageSize] = React.useState<number>(7);
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [pageNumbers, setPageNumbers] = React.useState<number>(1);
  const [pageNumbersStaff, setPageNumbersStaff] = React.useState<number>(1);
  const [inFacility, setInFacility] = React.useState<any>("all");
  const [currentUsers, setCurrentUsers] = React.useState<any>([]);
  const [currentPageStaff, setCurrentPageStaff] = React.useState<number>(1);
  const [registerType, setregisterType] = React.useState<any>(data.state == 'web' ? "0" : "1");
  const [currentTotalCount, setCurrentTotalCount] = React.useState<number>(0);

  // const debouncedSearch = useDebounce(searchValue, 300);
  //
  // function searchParentsDebounced() {
  //   searchParents(parentsPageSize, 1, searchValue).then((parentsFound: any) => {
  //       setCurrentPage(1)
  //       // @ts-ignore
  //     setCurrentParents(parentsFound.data.list);
  //       setPageNumbers(parentsFound.numberOfTotalParents/ parentsPageSize);
  //       setNumberOfTotalParents(parentsFound.numberOfTotalParents);
  //     })
  //     .catch((e) => {
  //       HandleUIError(e);
  //     });
  // }

  /*React.useEffect(
    function () {
      if(debouncedSearch) {
        searchParentsDebounced();
      } else {
        searchRolesUser(getId?.id, parentsPageSize, 1, searchValue).then((parentsFound: any) => {
          setCurrentPage(1)
          // @ts-ignore
          setCurrentParents(parentsFound.data.list);
          setPageNumbers(parentsFound.numberOfTotalParents/ parentsPageSize);
          setNumberOfTotalParents(parentsFound.numberOfTotalParents);
        }).catch((e) => {
          HandleUIError(e)
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearch]
  );*/

  React.useEffect(() => {
    refreshParents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  React.useEffect(function () {
    getRolesUser(getId?.id, parentsPageSize, 1).then((res: any) => {
      setCurrentParents(res.list);
    }).catch((e) => {
      HandleUIError(e)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeSearchValue(e: any) {
    setSearchValue(e.target.value);
  }


  //this function is treggered by pagination
  async function refreshParents() {
    setLoading(true);
    try {
      let parentsFound: any = await searchRolesUser(
        getId?.id,
        parentsPageSize,
        currentPage,
        searchValue
      );
      setCurrentParents(parentsFound.data.list);
      setPageNumbers(parentsFound.numberOfTotalParents / parentsPageSize);
      setNumberOfTotalParents(parentsFound.numberOfTotalParents);
      setLoading(false);
    } catch (e) {
      HandleUIError(e);
      setLoading(false);
    }
  }

  function onPressAdd() {
    setVisibleNewParent(!visibleNewParent);
  }

  async function refreshUsers() {
    setLoading(true);
    try {
      let usersFound: any = await searchUsersAlt(
        `${getId?.id}`,
        parentsPageSize,
        currentPageStaff,
        searchValue,
        inFacility,
        registerType,
      );
      setCurrentUsers(usersFound.data);
      setPageNumbersStaff(usersFound.numberOfTotalUsers / parentsPageSize);
      setCurrentTotalCount(usersFound.numberOfTotalUsers)
      setLoading(false);
    } catch (e) {
      HandleUIError(e);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (getId?.id) {
      refreshUsers()
    }
    if (getId?.id && registerType) {
      refreshUsers()
    }
  }, [getId?.id, currentPageStaff, registerType, data.state])

  console.log('currentRoles', currentParents)

  useEffect(() => {

  }, [data.state])

  return (
    <div className="bg-main pb-5 over-flow-auto h-100">
      <PageContainer className="page-container-staff d-flex flex-column justify-content-start pb-3 mx-4">
        <div className="d-flex flex-custom-row flex-column justify-content-end staff-menu">
          {data.state == 'web' ? '' : (
            <div className="d-flex w-100 justify-content-between align-items-center">
              <div className="wrap mg-bot mg-lt center-text d-none">
                <p className="text-light me-2">Search:</p>
                <input
                  value={searchValue}
                  onChange={onChangeSearchValue}
                  className="input-search"
                  type="text"
                />
              </div>
              <div className="col-lg-5 me-auto ms-2">


                <button
                  className="btn btn-sm btn-success text-white"

                  onClick={() => setregisterType('1')}
                >
                  {/* Csv Ids */}
                  Registered Users
                </button>
                {"   "}
                <button
                  className="btn btn-sm btn-danger text-white"
                  onClick={() => setregisterType('0')}
                >
                  Un-Registered Users
                </button>
                {" "}
                <h5 className="text-white mt-3">
                  {currentTotalCount} Users
                </h5>

                {/*<select
                        onChange={(e: any) => setregisterType(e.target.value)}
                        value={registerType}
                        className="form-select input-select-custom"
                        id="exampleFormControlSelect1"
                    >
                      <option value="">
                        All
                      </option>
                      <option value="1">
                        Registered
                      </option>
                      <option value="0">
                        Not Register
                      </option>
                    </select>*/}
              </div>
              <div className="col-auto">
                <a href="/sample-data.csv" className="text-info" download={true} target="_blank">
                  Download Sample File
                </a>
              </div>
              <div className="d-flex align-self-end mt-3 mt-xxl-0 buttons-right wrap">
                {checkPermission("Import CSV") && (
                  <div
                    className="add-icon-container mg-lt add-icon-staff"
                    onClick={onPressAdd}
                  >
                    <CIcon icon={cilPlus} size="xl" className="add-icon" />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div>
          {console.log('sdf', data.state)}
          {data.state == 'web' ? (
            <RolesList
              parents={currentUsers}
              loading={loading}
              refreshParents={refreshUsers}
            />
          ) : (
            <StaffList
              users={currentUsers}
              loading={loading}
              refreshUsers={refreshUsers}
            />
          )}

        </div>
        {pageNumbersStaff > 1 ? (
          <Pagination
            numberOfPages={pageNumbersStaff}
            setCurrentPage={setCurrentPageStaff}
            currentPage={currentPageStaff}
          />
        ) : (
          <></>
        )}
      </PageContainer>
      <NewRole
        visible={visibleNewParent}
        closeModal={onPressAdd}
        refreshParents={refreshUsers}
      />
      <NewMessage />
    </div>
  );
};

export default RolesUserComp;