import React, { useEffect, useState } from 'react';
import { Button, IconButton, Modal, Spinner } from "./Atomics";
import { cilX } from "@coreui/icons";
import { forwardInProgress, forwardShootAlertInProgress } from "../services/alertServices";
import toast from "react-hot-toast";
import Multiselect from 'multiselect-react-dropdown';
import HandleUIError from "../utils/HandleUIError";

const ApprovedModal = ({ visible, closeModal, isEdit, refetch, roles }) => {
    const [loading, setLoading] = useState(false);
    const [role, setRole] = useState('')
    const [selectedOptions, setSelectedOptions] = React.useState([]);
    const [selectedBody, setSelectedBody] = React.useState([]);

    const [options, setOptions] = useState([
        { id: 1, value: 'Option 1' },
        { id: 2, value: 'Option 2' },
        { id: 3, value: 'Option 3' },
    ]);

    const onSelectRole = (selectedList, selectedItem) => {
        setSelectedOptions(selectedList);
        setSelectedBody(pre => [...pre, { id: selectedItem?.id, value: selectedItem?.value, users: [] }]);
    };

    const onRemoveRole = (selectedList, removedItem) => {
        setSelectedOptions(selectedList);
        setSelectedBody(pre => pre.filter((item) => item?.id !== removedItem?.id));
    };

    const onSelectUser = (selectedItem, item) => {
        // setSelectedOptions(selectedList);
        setSelectedBody(pre => pre.map((i) => {
            if (i?.id === item?.id) {
                return { ...i, users: [...i?.users, selectedItem] }
            }
            return i;
        }))
    };

    const handleMessage = (e, item) => {
        setSelectedBody(pre => pre.map((i) => {
            if (i?.id === item?.id) {
                return { ...i, message: e.target.value }
            }
            return i;
        }))
    }

    const onRemoveUser = (removedItem, item) => {
        setSelectedBody(pre => pre.map((i) => {
            if (i?.id === item?.id) {
                return { ...i, users: i?.users.filter((j) => j?.id !== removedItem?.id) }
            }
            return i;
        }))
    };

    const onPressSend = async (data) => {
        if (!data) {
            toast.error('Please select a recipient group', { id: 35 });
            return;
        }

        const newData = data.map((item) => {
            return {
                role_id: item?.id,
                user_list: item?.users.map((i) => i?.id?.toString()),
                message: item?.message
            }
        })
        console.log('newData===', newData)

        setLoading(true);
        try {
            const response = isEdit?.alertType ? await forwardInProgress({ id: isEdit?.id, body: newData }) : await forwardShootAlertInProgress({ id: isEdit?.id, body: newData });
            if (response?.data?.status == 200) {
                console.log('response===', response);
                toast.success('Alert sent successfully', { id: 34 });
                setTimeout(() => {
                    refetch();
                }, 1000)
            }
            closeModal();
        } catch (e) {
            HandleUIError(e);
        }
        setLoading(false);
    }

    return (
        <>
            <Modal visible={visible} onPressOut={closeModal}>
                <div className="new-staff-container h-auto">
                    <div className="new-staff-header">
                        <p className="text-light py-2 ps-4 fs-5">
                            Forward Alerts
                        </p>
                        <IconButton icon={cilX} className="me-3" onClick={closeModal} />
                    </div>

                    <div className="py-3 px-3" style={{ height: 500, overflowX: 'auto' }}>
                        <p className="text-light fs-5 ms-3 pt-3 mb-2">
                            Select Role
                        </p>
                        <div className="px-3 mb-4">
                            <Multiselect
                                options={roles?.map((item) => ({ ...item, id: item?.id, value: item?.role_name }))}
                                selectedValues={selectedOptions}
                                onSelect={onSelectRole}
                                onRemove={onRemoveRole}
                                displayValue="value"
                                closeOnSelect={true}
                            />
                        </div>

                        {selectedOptions?.map((item) => (
                            <>
                                {item?.users?.length > 0 && (
                                    <p className="text-light fs-5 ms-3 pt-3 mb-2">
                                        Select {item?.role_name}
                                    </p>
                                )}
                                {item?.users?.length > 0 && (
                                    <div className="px-3 mb-4">
                                        <Multiselect
                                            options={item?.users
                                                ?.filter(user => user?.email)  // Filter users with emails
                                                .map(user => ({ id: user?.id, value: user?.firstName }))}
                                            // selectedValues={selectedOptions?.users}
                                            onSelect={(selectedList, selectedItem) => onSelectUser(selectedItem, item)}
                                            onRemove={(selectedList, selectedItem) => onRemoveUser(selectedItem, item)}
                                            displayValue="value"
                                            closeOnSelect={false}
                                            style={{
                                                color: '#fff'
                                            }}
                                        />
                                        <p className="text-light mb-2 mt-3">
                                            Send Message
                                        </p>
                                        <textarea
                                            rows={5}
                                            className="form-control shadow-none"
                                            style={{
                                                backgroundColor: '#24242C',
                                                border: '2px solid #44454a',
                                                color: '#51fffe',
                                            }}
                                            // value={item?.message}
                                            onChange={(e) => handleMessage(e, item)}
                                        />
                                    </div>
                                )}
                            </>
                        ))}


                        <div className="d-flex justify-content-end px-3 pb-3">
                            <Button onClick={() => onPressSend(selectedBody)} disabled={loading} className="px-4">
                                {loading ? <Spinner /> : "Send"}
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ApprovedModal;