import * as React from "react";
import { GoogleMap } from "../google-map";
import "./LocationMapModal.scss";

//components
import { Modal } from "../Atomics";
import CIcon from "@coreui/icons-react";
import { cilX } from "@coreui/icons";

interface Props {
  visible: boolean;
  closeModal: () => void;
  deviceName: string;
  coordinates?: { lat: number; lng: number };
}

const LocationMapModal: React.FC<Props> = ({
  visible,
  closeModal,
  deviceName,
  coordinates,
}) => {
  return (
    <Modal
      visible={visible}
      onPressOut={closeModal}
      backgroundClassName="custom-modal-staff"
      className="map-container-staff"
    >
      <div className="map-header-staff">
        <p className="text-light text-capitalize">{deviceName}'s location</p>
        <CIcon
          onClick={closeModal}
          icon={cilX}
          size="xxl"
          className="location-modal-exit-icon"
        />
      </div>
      <GoogleMap
        // zones={perimeterExample}
        center={coordinates}
        zoom={17}
        markers={coordinates ? [coordinates] : null}
        pointerMap={false}
      />
    </Modal>
  );
};

export default LocationMapModal;
const perimeter1 = [
  { lat: 25.798762561483304, lng: -80.37709193042075 },
  { lat: 25.798748072272392, lng: -80.37240879348074 },
  { lat: 25.797173567467002, lng: -80.37240879348074 },
  { lat: 25.797187747086333, lng: -80.3770601271095 },
];

const perimeter2 = [
  { lat: 25.797898035465938, lng: -80.37547724059378 },
  { lat: 25.79788354614935, lng: -80.37465648463522 },
  { lat: 25.797241184667563, lng: -80.37464039138113 },
  { lat: 25.797260503860652, lng: -80.37546114733969 },
];

const perimeter3 = [
  { lat: 25.798651531199916, lng: -80.37708944210145 },
  { lat: 25.79867567990346, lng: -80.37629550823304 },
  { lat: 25.797191871140832, lng: -80.37629550823304 },
  { lat: 25.797191871140832, lng: -80.3770626200113 },
];

const perimeterExample = [perimeter1, perimeter2, perimeter3];

//TODO: remove this coordinate when using the perimeter ones
