import axiosConfig from "./axiosConfig";
import { AxiosError, AxiosResponse } from "axios";
import ApiError from "../utils/ApiError";
import { Roles } from "../components/types";

interface PaginationResponse {
  data: Array<Roles>;
  numberOfTotalParents: number;
}

export async function searchEmergency(
  limit: number,
  page: number,
  search: string
): Promise<PaginationResponse> {
  try {
    const response = await axiosConfig.get<any>(`admin/emergency-contact`);
    const data = response.data.data as Array<Roles>;
    return { data, numberOfTotalParents: response.data.meta.total };
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function getEmergency(
  limit: number,
  page: number
): Promise<Array<Roles>> {
  try {
    const response = await axiosConfig.get<AxiosResponse>(
      `admin/emergency-contact?limit=${limit}&page=${page}`
    );
    return response.data.data as Array<Roles>;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function searchRolesUser(
  limit: number,
  page: number,
  search: string
): Promise<PaginationResponse> {
  try {
    const response = await axiosConfig.get<any>(`/admin/roles-user-list/1`);
    const data = response.data.data as Array<Roles>;
    return { data, numberOfTotalParents: response.data.meta.total };
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function getRolesUser(
  limit: number,
  page: number
): Promise<Array<Roles>> {
  try {
    const response = await axiosConfig.get<AxiosResponse>(
      `/admin/roles-user-list/1`
    );
    return response.data.data as Array<Roles>;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function saveEmergency(
  name: string,
  phone: string | null
): Promise<void> {
  try {
    const body = {
      name: name,
      phone_number: phone,
    };
    await axiosConfig.post("admin/emergency-contact", body);
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function removeEmergency(parentId: number): Promise<Array<any>> {
  try {
    const response = await axiosConfig.delete<AxiosResponse>(
      `admin/emergency-contact/${parentId}`
    );
    return response.data.data as Array<any>;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function editEmergency(
  parentId: number,
  name: string,
  phone: string | null
): Promise<Array<any>> {
  try {
    const body = {
      name: name,
      phone_number: phone,
    };
    const response = await axiosConfig.put<AxiosResponse>(
      `admin/emergency-contact/${parentId}`,
      body
    );
    return response.data.data as Array<any>;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}
