import axiosConfig from "./axiosConfig";
import { AxiosError, AxiosResponse } from "axios";
import ApiError from "../utils/ApiError";
import { StatsResponse, NewUserStaff } from "./types";
import { User } from "../components/types";

interface PaginationResponse {
  data: Array<User>;
  numberOfTotalUsers: number;
}

export async function getAllUsers(): Promise<Array<User>> {
  try {
    const response = await axiosConfig.get<AxiosResponse>("/admin/users");
    return response.data.data as Array<User>;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function getUsersForPagination(
  limit: number,
  page: number
): Promise<Array<User>> {
  try {
    const response = await axiosConfig.get<any>(
      `/admin/users?limit=${limit}&page=${page}`
    );
    return response.data.data as Array<User>;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function addUserToOrganization(
  userId: number,
  newOrganizationId: number | null
): Promise<Array<any>> {
  try {
    const response = await axiosConfig.patch<AxiosResponse>(
      `/admin/users/${userId}/organization`,
      {
        organizationId: newOrganizationId,
      }
    );
    return response.data.data as Array<any>;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function removeUserForOrganization(
  userId: number
): Promise<Array<any>> {
  try {
    const response = await axiosConfig.delete<AxiosResponse>(
      `/admin/users/${userId}/organization`
    );
    return response.data.data as Array<any>;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function searchUserToAdd(search: string): Promise<Array<any>> {
  try {
    const response = await axiosConfig.get<AxiosResponse>(
      `/users?freeText=${search}&freeTextSearchFields=phone,email&filter[organizationId][is]=null`
    );
    return response.data.data as Array<any>;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function searchUsers(
    tab: string,
  limit: number,
  page: number,
  search: string,
  inFacility?: "all" | "in" | "off",
  type?: string
): Promise<PaginationResponse> {
  let inFacilityText = "";
  inFacility == "in" && (inFacilityText = "&inFacility=in");
  inFacility == "off" && (inFacilityText = "&inFacility=off");
  try {
    const response = await axiosConfig.get<any>(
      `/admin/users${tab ? `?role_id=${parseInt(tab)}&` : ''}?limit=${limit}&page=${page}&type=${type}&freeText=${search}${inFacilityText}`
    );
    const data = response.data.data as Array<User>;
    return { data, numberOfTotalUsers: response.data.meta.total };
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function searchUsersAlt(
  tab: string,
limit: number,
page: number,
search: string,
inFacility?: "all" | "in" | "off",
type?: string
): Promise<PaginationResponse> {
let inFacilityText = "";
inFacility == "in" && (inFacilityText = "&inFacility=in");
inFacility == "off" && (inFacilityText = "&inFacility=off");
try {
  const response = await axiosConfig.get<any>(
    `/admin/users${tab ? `?role_id=${parseInt(tab)}&` : '?'}limit=${limit}&page=${page}&type=${type}&freeText=${search}${inFacilityText}`
  );
  const data = response.data.data as Array<User>;
  return { data, numberOfTotalUsers: response.data.meta.total };
} catch (e) {
  const err = e as AxiosError | Error;
  throw new ApiError(err);
}
}


export async function getTotalStaffDevices(): Promise<StatsResponse> {
  try {
    const response = await axiosConfig.get<AxiosResponse>("/admin/stats");
    return response.data.data as StatsResponse;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}
export async function getUserStatistics(): Promise<StatsResponse> {
  try {
    const response = await axiosConfig.get<AxiosResponse>("/admin/user-statistics");
    return response.data.data as StatsResponse;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function createNewUser(infoNewUser: NewUserStaff): Promise<void> {
  try {
    await axiosConfig.post(`admin/users/new-staff`, {
      firstName: infoNewUser.firstName,
      lastName: infoNewUser.lastName,
      email: infoNewUser.email,
      phone: infoNewUser.phone,
      password: infoNewUser.password,
    });
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}
