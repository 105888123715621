import * as React from "react";
import { useEffect, useState } from "react";
import "./NavBar.scss";
import {
    CButton,
    CContainer,
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CNavbar,
    CNavbarBrand
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilMenu } from "@coreui/icons";
import { Spinner, UserAvatar } from "../components/Atomics";
import { useAppSelector } from "../redux/hooks";
import { Link, useHistory, useLocation } from "react-router-dom";
import Alert from "./Alert";
import { AlertResponse } from "./../services/types";
import {
    approvedInProgress,
    approvedShootAlertInProgress,
    closeAlertInProgress,
    closeForwardShootAlertInProgress,
    closeShootAlertInProgress,
    deleteAllNotifications,
    getAlertsInProgress,
    getForwardOtherAlerts,
    getForwardShootAlerts,
    getNotifications,
    getShootAlertsInProgress,
    sendFcmToken,
    sendReadNotification,
} from "./../services/alertServices";
import HandleUIError from "./../utils/HandleUIError";
import ApiErrorStatusCode from "./../utils/ApiErrorStatusCode";
import { getButtonStyle } from "../helpers/uiTransforms";
import AlertModal from "../components/AlertModal";
import { getTokens, onMessageListener } from "../firebaseConfig";
import ShowAlertsModal from "../components/ShowAlertsModal";
import ApprovedModal from "../components/ApprovedModal";
import { getParents } from "../services/rolesService";
import moment from "moment";
import usePermission from "../hooks/usePermission";
import { createActivityReq } from "../services/activityLogService";

interface Props {
    toggleSideBarVisible: () => void;
}

const defaultColorConfig: {
    color: string;
    borderColor: string;
    backgroundColor?: string;
} = {
    color: "#FFFFFF",
    borderColor: "#AB5F5F",
    backgroundColor: "#AB5F5F",
};
const NavBar: React.FC<Props> = ({ toggleSideBarVisible }) => {
    const { checkPermission } = usePermission()
    const history = useHistory()
    const [currentAlert, setCurrentAlert] = React.useState<AlertResponse>();
    const [currentAlertArray, setCurrentAlertArray] = React.useState<any>([]);
    const userSlice: any = useAppSelector((state) => state.UserSlice);
    const [title, setTitle] = React.useState("");
    const [currentAlertColors, setCurrentAlertColors] =
        React.useState(defaultColorConfig);
    const [closeButtonStyle, setCloseButtonStyle] = React.useState({
        color: defaultColorConfig.color,
        borderColor: defaultColorConfig.color,
        backgroundColor: "transparent",
    });
    const [loadingClose, setLoadingClose] = React.useState(false);
    const [loadingCloseShooter, setLoadingCloseShooter] = React.useState(false);
    const [loadingApproved, setLoadingCloseApproved] = React.useState(false);
    const [visibleAlert, setVisibleAlert] = React.useState(false);
    const [isEdit, setIsEdit] = useState<any>(null)
    const [isViewAlerts, setIsViewAlerts] = useState('')
    const [currentShootAlert, setCurrentShootAlert] = useState<any>(null)
    const [currentShootAlertArray, setCurrentShootAlertArray] = useState<any>([])
    const [isForward, setIsForward] = useState<any>(null)
    const [roles, setRoles] = useState<any>([])
    const userEmail = userSlice.user.email;
    const iconLetter = userEmail.substring(0, 2);
    const location: any = useLocation();
    const [notify, setNotify] = useState<any>({})

    const [isTokenFound, setTokenFound] = useState(false);
    getTokens(setTokenFound);


    onMessageListener().then((payload: any) => {
        // setShow(true);
        // setNotification({title: payload.notification.title, body: payload.notification.body })
        console.log('payload', payload);
        let audio: any = new Audio('/security-alarm-80493_1.mp3');

        if (payload?.data['title'] == "alert-title-staff") {
            if (userSlice?.user?.RoleId) {
                audio.play();
                refreshForwardOtherAlerts()
                refreshForwardShootAlerts()
            }
        }
        if (payload?.data['gcm.notification.type'] == "other-header-alert") {
            if (userSlice?.user?.RoleId) {
                audio.play();
                refreshForwardOtherAlerts()
            } else {
                audio.play();
                refreshAlerts()
            }
        } else {
            if (userSlice?.user?.RoleId) {
                audio.play();
                refreshForwardShootAlerts()
            } else {
                if (payload?.data['title'] != "alert-title-staff") {
                    audio.play();
                }
                refreshShootAlerts()
            }
        }
        refreshNotifications()

    }).catch((err: any) => console.log('failed: ', err));

    console.log('shooter===', userSlice?.user?.RoleId)


    useEffect(() => {
        refreshNotifications().catch((e) => console.warn(e));
    }, [])

    React.useEffect(() => {
        if (userSlice?.user?.RoleId) {
            refreshForwardShootAlerts().catch((e) => console.warn(e));
            refreshForwardOtherAlerts().catch((e) => console.warn(e));
        } else {
            refreshShootAlerts().catch((e) => console.warn(e));
            refreshAlerts().catch((e) => console.warn(e));
        }
    }, [userSlice?.user?.RoleId])


    console.log('location.pathname', location.pathname)

    const sendToken = async (token: any) => {
        const getToken = await sendFcmToken(token)
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (isTokenFound) {
            sendToken(isTokenFound)
        }
    }, [isTokenFound]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        getParents(0, 1).then((res) => {
            setRoles(res);
        }).catch((e) => {
            HandleUIError(e)
        })
    }, [])

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(
        function () {
            switch (location.pathname) {
                case "/":
                    setTitle("Dashboard");
                    break;
                case "/facility":
                    setTitle("Facility");
                    break;
                case "/staff":
                    setTitle("Staff");
                    break;
                case "/messages":
                    setTitle("Messages");
                    break;
                case "/alerts":
                    setTitle("Alerts");
                    break;
                case "/alert-type-action":
                    setTitle("Alert Actions");
                    break;
                case "/new-perimeter":
                    setTitle("Add New Perimeter");
                    break;
                case "/community":
                    setTitle("Community");
                    break;
                case "/roles":
                    setTitle("Roles User & Group");
                    break;
                case "/app-users":
                    setTitle("App Users");
                    break;
                case "/alarm-alerts":
                    setTitle("Alarm Alerts");
                    break;
                case `/roles-user/${userSlice.rolesUser.id}`:
                    setTitle("User List");
                    break;
                case "/admins":
                    setTitle("Admins");
                    break;
                default:
                    setTitle("");
                    break;
            }
        },
        [location.pathname]
    );

    function toggleVissibleAlert() {
        setVisibleAlert(!visibleAlert);
    }

    async function refreshAlerts() {
        try {
            const alertsResponse = await getAlertsInProgress();
            setCurrentAlert(alertsResponse[0]);
            setCurrentAlertArray(alertsResponse)
            const currentAlertColors = getButtonStyle(
                alertsResponse[0]?.alertType.color
            );
            const {
                color: closeButtonColor,
                color: border,
                backgroundColor,
            } = currentAlertColors;
            const colorSettings: {
                color: string;
                borderColor: string;
                backgroundColor?: string;
            } = {
                color: closeButtonColor ? closeButtonColor : defaultColorConfig.color,
                borderColor: border ? border : defaultColorConfig.borderColor,
                backgroundColor: backgroundColor
                    ? backgroundColor
                    : currentAlertColors.backgroundColor,
            };

            setCurrentAlertColors(colorSettings);
            setCloseButtonStyle({
                color: colorSettings.color,
                borderColor: colorSettings.color,
                backgroundColor: colorSettings.color,
            });
        } catch (e) {
            HandleUIError(e);
        }
    }

    async function closeCurrentAlert() {
        if (currentAlert) {
            setLoadingClose(true);
            try {
                await closeAlertInProgress(currentAlert.id);
                await refreshAlerts();
                setLoadingClose(false);
                setIsEdit(null)
            } catch (e) {
                HandleUIError(e, interceptError);
                setLoadingClose(false);
            }
        }
    }

    async function refreshShootAlerts() {
        try {
            const alertsResponse = await getShootAlertsInProgress();
            console.log('alertsResponse', alertsResponse)
            setCurrentShootAlert(alertsResponse[0]);
            setCurrentShootAlertArray(alertsResponse)
        } catch (e) {
            HandleUIError(e);
        }
    }

    async function refreshForwardShootAlerts() {
        try {
            const alertsResponse = await getForwardShootAlerts();
            console.log('alertsResponse', alertsResponse)
            setCurrentShootAlert(alertsResponse[0]);
            setCurrentShootAlertArray(alertsResponse)
        } catch (e) {
            HandleUIError(e);
        }
    }

    async function refreshForwardOtherAlerts() {
        try {
            const alertsResponse = await getForwardOtherAlerts();
            console.log('alertsResponse', alertsResponse)
            setCurrentAlert(alertsResponse[0]);
            setCurrentAlertArray(alertsResponse)
        } catch (e) {
            HandleUIError(e);
        }
    }

    async function refreshNotifications() {
        try {
            const alertsResponse = await getNotifications();
            console.log('alertsResponse===', alertsResponse)
            setNotify(alertsResponse)
        } catch (e) {
            HandleUIError(e);
        }
    }

    async function readNotification(id: number) {
        try {
            const alertsResponse = await sendReadNotification(id);
            console.log('alertsResponse===', alertsResponse)
            refreshNotifications()
        } catch (e) {
            HandleUIError(e);
        }
    }

    async function clearAllNotification() {
        try {
            const alertsResponse = await deleteAllNotifications();
            console.log('alertsResponse===', alertsResponse)
            refreshNotifications()
            await createActivityReq('Clear All Notifications Successfully')
        } catch (e) {
            HandleUIError(e);
        }
    }

    async function closeShoottAlert() {
        if (currentShootAlert) {
            setLoadingCloseShooter(true);
            try {
                await closeShootAlertInProgress(currentShootAlert?.id);
                await refreshShootAlerts();
                setLoadingCloseShooter(false);
                setIsEdit(null)
            } catch (e) {
                HandleUIError(e, interceptError);
                setLoadingCloseShooter(false);
            }
        }
    }

    async function closeForwardShoottAlert() {
        if (currentShootAlert) {
            setLoadingCloseShooter(true);
            try {
                await closeForwardShootAlertInProgress(currentShootAlert?.id);
                await refreshForwardShootAlerts();
                setLoadingCloseShooter(false);
                setIsEdit(null)
            } catch (e) {
                HandleUIError(e, interceptError);
                setLoadingCloseShooter(false);
            }
        }
    }

    async function closeForwardOtherAlert() {
        if (currentAlert) {
            setLoadingCloseShooter(true);
            try {
                await closeForwardShootAlertInProgress(currentAlert?.id);
                await refreshForwardOtherAlerts();
                setLoadingCloseShooter(false);
                setIsEdit(null)
            } catch (e) {
                HandleUIError(e, interceptError);
                setLoadingCloseShooter(false);
            }
        }
    }

    async function approvedShooterAlert(id: any) {
        if (id) {
            setLoadingCloseApproved(true);
            try {
                await approvedShootAlertInProgress(id);
                await refreshShootAlerts();
                await refreshAlerts();
                setLoadingCloseApproved(false);
                setIsEdit(null)
            } catch (e) {
                HandleUIError(e, interceptError);
                setLoadingCloseApproved(false);
            }
        }
    }

    async function approvedAlert(id: any) {
        if (id) {
            setLoadingCloseApproved(true);
            try {
                await approvedInProgress(id);
                await refreshShootAlerts();
                await refreshAlerts();
                setLoadingCloseApproved(false);
                setIsEdit(null)
            } catch (e) {
                HandleUIError(e, interceptError);
                setLoadingCloseApproved(false);
            }
        }
    }

    function interceptError(errorStatusCode: number) {
        if (errorStatusCode === ApiErrorStatusCode.ConflictError) {
            window.location.reload();
        }
    }

    const handleIsForward = (data: any) => {
        setIsForward(data)
    }

    const redirectNotification = (type?: any) => {
        if (type == 'shooter') {
            history.push('/emergency-alert')
        } else if (type == 'group message') {
            history.push('/group-alert')
        } else if (type == 'other') {
            history.push('/alerts')
        } else {

        }
    }

    const renderAlerts = (alert: any, index: any) => {
        return (
            <div
                className="w-100 mb-3 d-flex text-center rounded-2 overflow-hidden"
            >
                <div
                    className="h6 py-3 px-3 m-0"
                    style={{
                        color: currentAlertColors.color,
                        borderColor: currentAlertColors.color,
                        backgroundColor: currentAlertColors.backgroundColor ? `${currentAlertColors.backgroundColor}60` : '#ff282860',
                    }}
                >
                    {index + 1}
                </div>
                <div
                    className="current-alert-sign-text flex-column m-0 px-2"
                    role="button"
                    style={{
                        color: currentAlertColors.color,
                        borderColor: currentAlertColors.color,
                        backgroundColor: currentAlertColors.backgroundColor ? `${currentAlertColors.backgroundColor}b3` : '#ff2828b3',
                    }}
                    onClick={() => {
                        if (alert?.alertType) {
                            setIsEdit(alert)
                            setIsViewAlerts('')
                            // setCurrentAlert(alert)
                        } else {
                            // setCurrentShootAlert(alert)
                            if (alert?.is_high_alert) {
                                setIsEdit(alert)
                                setIsViewAlerts('')
                            } else {
                                history.push('/emergency-alert')
                                setIsViewAlerts('')
                            }
                        }
                    }}
                >
                    <div className="d-flex align-items-center justify-content-start" style={{ gap: 10 }}>
                        {alert?.alertType?.icon && (
                            <div className="">
                                <img
                                    src={alert?.alertType?.icon}
                                    alt="icons2"
                                    width={40}
                                    height={40}
                                    className="rounded-circle m-0 shadow-sm"
                                    style={{
                                        objectFit: 'cover'
                                    }}
                                />
                            </div>
                        )}
                        <div className="d-flex flex-column align-items-start">
                            {alert?.alertType ? (
                                <>

                                    <p className="mb-0 fw-bold text-start">
                                        {alert?.alertType?.name}
                                    </p>
                                </>
                            ) : (
                                <>
                                    <p className="mb-0 fw-bold text-start">
                                        {alert?.alert_type == "Message" && (
                                            <>
                                                {alert?.message ? alert?.message?.substring(0, 12) : alert?.alert_name}
                                            </>
                                        )}
                                        {alert?.alert_type == null && (
                                            <>
                                                {alert?.alert_name}
                                            </>
                                        )}
                                        {alert?.alert_type == "Voice" && 'Audio'}
                                        {alert?.alert_type == "Image" && 'Image'}
                                    </p>
                                </>
                            )}
                            <p className="d-block small fw-normal mb-0" style={{ fontSize: 12 }}>
                                By {alert?.user?.firstName}
                            </p>
                        </div>
                    </div>
                </div>
                <div className=" pt-2 pe-2 d-none">
                    {loadingCloseShooter ? (
                        <Spinner />
                    ) : (
                        <CButton
                            size="sm"
                            shape="rounded-circle"
                            className="close-alert-button pt-1 border border-white"
                            style={closeButtonStyle}
                            onClick={closeShoottAlert}
                            color="none"
                        >
                            x
                        </CButton>
                    )}
                </div>
            </div>
        )
    }

    // @ts-ignore
    return (
        <>
            {/*{isTokenFound && <h1> Notification permission enabled 👍🏻 </h1>}*/}
            {/*{!isTokenFound && <h1> Need notification permission ❗️ </h1>}*/}
            <CNavbar
                expand="lg"
                colorScheme="dark"
                className="custom-header bg-dark1 navBarShadow"
            >
                <CContainer fluid>
                    <div className="d-flex align-items-center">
                        <CIcon
                            icon={cilMenu}
                            size="xxl"
                            onClick={toggleSideBarVisible}
                            className="me-3 ms-2 icon-white sidebar-button-nav"
                        />
                        <CNavbarBrand href="#" className="">
                            {title}
                        </CNavbarBrand>
                    </div>

                    {checkPermission("View Live Alerts") && (
                        <>
                            {currentAlert ? (
                                <div
                                    className="current-alert-sign d-flex text-center rounded-1 overflow-hidden"

                                >
                                    <div
                                        className="h6 py-2 px-3 m-0"
                                        onClick={() => setIsViewAlerts('othersAlert')}
                                        role="button"
                                        style={{
                                            color: currentAlertColors.color,
                                            borderColor: currentAlertColors.color,
                                            backgroundColor: currentAlertColors.backgroundColor ? `${currentAlertColors.backgroundColor}60` : '#ff282860',
                                        }}
                                    >
                                        {currentAlertArray?.length}
                                        <i className="fa-solid fa-caret-up" style={{ fontSize: 12, transform: 'rotate(180deg)' }} />
                                    </div>
                                    <div
                                        className="d-flex w-100"
                                        style={{
                                            color: currentAlertColors.color,
                                            borderColor: currentAlertColors.color,
                                            backgroundColor: currentAlertColors.backgroundColor ? `${currentAlertColors.backgroundColor}b3` : '#ff2828b3',
                                        }}
                                    >
                                        <div
                                            className="current-alert-sign-text px-1 flex-column"
                                            role="button"
                                            onClick={() => {
                                                if (currentAlert?.alert) {
                                                    setIsEdit({
                                                        ...currentAlert?.alert,
                                                        type: 'Message',
                                                        message: currentAlert?.message,
                                                        isShowButton: false
                                                    })
                                                } else {
                                                    setIsEdit({ ...currentAlert, isShowButton: true })
                                                }
                                            }}
                                        >
                                            {/*{console.log('currentAlert?.alert', currentAlert?.alertType)}*/}
                                            {currentAlert?.alert ? (
                                                <>
                                                    <div className="d-flex">
                                                        <div className="">
                                                            {currentAlert?.alert?.alert_type?.icon && (
                                                                <img src={currentAlert?.alert?.alert_type?.icon} alt="icons" />
                                                            )}
                                                        </div>
                                                        <div className="">
                                                            <p className="mb-0 fw-bold text-start">
                                                                {currentAlert?.alert?.type == 'Message' ? (
                                                                    currentAlert?.alert?.message?.substring(0, 10)
                                                                ) : (
                                                                    <>
                                                                        {currentAlert?.alert?.type ? currentAlert?.alert?.type : currentAlert?.alert.alertType?.name?.substring(0, 10)}
                                                                    </>
                                                                )}
                                                            </p>
                                                            <p className="d-block small fw-normal mb-0" style={{ fontSize: 12 }}>
                                                                By {currentAlert?.alert?.user?.firstName}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="d-flex align-items-center" style={{ gap: 10 }}>
                                                        <div className="">
                                                            {currentAlert?.alertType?.icon && (
                                                                <img
                                                                    src={currentAlert?.alertType?.icon}
                                                                    alt="icons"
                                                                    width={40}
                                                                    height={40}
                                                                    className="rounded-circle m-0 shadow-sm"
                                                                    style={{
                                                                        objectFit: 'cover'
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                        <div className="">
                                                            <p className="mb-0 fw-bold text-start">
                                                                {currentAlert?.type == 'Message' ? (
                                                                    currentAlert?.message?.substring(0, 10)
                                                                ) : (
                                                                    <>
                                                                        {currentAlert?.type ? currentAlert?.type : currentAlert.alertType?.name?.substring(0, 10)}
                                                                    </>
                                                                )}
                                                            </p>
                                                            <p className="d-block text-start fw-normal mb-0" style={{ fontSize: 12 }}>
                                                                By {currentAlert?.user?.firstName}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <div className=" pt-2 pe-2">
                                            {loadingClose ? (
                                                <div className="p-1">
                                                    <Spinner />
                                                </div>
                                            ) : (
                                                <div
                                                    className="close-alert-button p-1"
                                                    style={{
                                                        color: currentAlertColors.color,
                                                        borderColor: currentAlertColors.color,
                                                        // backgroundColor: currentAlertColors.color,
                                                    }}
                                                    role="button"
                                                    onClick={currentAlert?.alert ? closeForwardOtherAlert : closeCurrentAlert}
                                                    color="none"
                                                >
                                                    x
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : null}

                            {currentShootAlert ? (
                                <div
                                    className="current-alert-sign d-flex text-center rounded-2 over-flow-hidden"
                                >
                                    <div
                                        className="h6 py-2 px-3 m-0"
                                        onClick={() => setIsViewAlerts('shooterAlert')}
                                        role="button"
                                        style={{
                                            color: currentAlertColors.color,
                                            borderColor: currentAlertColors.color,
                                            backgroundColor: currentAlertColors.backgroundColor ? `${currentAlertColors.backgroundColor}60` : '#ff282860',
                                        }}
                                    >
                                        {currentShootAlertArray?.length}
                                        <i className="fa-solid fa-caret-up" style={{ fontSize: 12, transform: 'rotate(180deg)' }} />
                                    </div>
                                    <div
                                        className="d-flex w-100"
                                        style={{
                                            color: currentAlertColors.color,
                                            borderColor: currentAlertColors.color,
                                            backgroundColor: currentAlertColors.backgroundColor ? `${currentAlertColors.backgroundColor}b3` : '#ff2828b3',
                                        }}
                                    >
                                        <div
                                            className="current-alert-sign-text px-2 flex-column"
                                            role="button"
                                            onClick={() => {
                                                if (currentShootAlert?.emergency_alert) {
                                                    if (currentShootAlert?.emergency_alert?.is_high_alert) {
                                                        setIsEdit({
                                                            ...currentShootAlert?.emergency_alert,
                                                            message: currentShootAlert?.message,
                                                            isShowButton: false
                                                        })
                                                    } else {
                                                        history.push('/emergency-alert')
                                                    }
                                                } else {
                                                    if (currentShootAlert?.is_high_alert) {
                                                        setIsEdit({ ...currentShootAlert, isShowButton: true })
                                                    } else {
                                                        history.push('/emergency-alert')
                                                    }
                                                }
                                            }}
                                        >
                                            {currentShootAlert?.emergency_alert ? (
                                                <>
                                                    <div className="d-flex">
                                                        <div className="">
                                                            {currentShootAlert?.emergency_alert?.alert_type?.icon && (
                                                                <img src={currentShootAlert?.emergency_alert?.alert_type?.icon} alt="icons" />
                                                            )}
                                                        </div>
                                                    </div>
                                                    <p className="mb-0 fw-bold text-start">
                                                        {currentShootAlert?.emergency_alert?.alert_type == "Message" && (
                                                            <>
                                                                {currentShootAlert?.emergency_alert?.message ? currentShootAlert?.emergency_alert?.message?.substring(0, 10) : currentShootAlert?.emergency_alert?.alert_name}
                                                            </>
                                                        )}
                                                        {currentShootAlert?.emergency_alert?.alert_type == null && (
                                                            <>
                                                                {currentShootAlert?.emergency_alert?.alert_name}
                                                            </>
                                                        )}
                                                        {currentShootAlert?.emergency_alert?.alert_type == "Voice" && 'Audio'}
                                                        {currentShootAlert?.emergency_alert?.alert_type == "Image" && 'Image'}
                                                    </p>
                                                    <p className="d-block small fw-normal mb-0" style={{ fontSize: 12 }}>
                                                        By {currentShootAlert?.emergency_alert?.user?.firstName}
                                                    </p>
                                                </>
                                            ) : (
                                                <>
                                                    <p className="mb-0 fw-bold text-start">
                                                        {currentShootAlert?.alert_type == "Message" && (
                                                            <>
                                                                {currentShootAlert?.message ? currentShootAlert?.message?.substring(0, 10) : currentShootAlert?.alert_name}
                                                            </>
                                                        )}
                                                        {currentShootAlert?.alert_type == null && (
                                                            <>
                                                                {currentShootAlert?.alert_name}
                                                            </>
                                                        )}
                                                        {currentShootAlert?.alert_type == "Voice" && 'Audio'}
                                                        {currentShootAlert?.alert_type == "Image" && 'Image'}
                                                    </p>
                                                    <p className="d-block small fw-normal mb-0" style={{ fontSize: 12 }}>
                                                        By {currentShootAlert?.user?.firstName}
                                                    </p>
                                                </>
                                            )}
                                        </div>
                                        <div className=" pt-2 pe-2">
                                            {loadingCloseShooter ? (
                                                <div className="p-1">
                                                    <Spinner />
                                                </div>
                                            ) : (
                                                <div
                                                    className="close-alert-button p-1 rounded-circle"
                                                    style={{
                                                        color: currentAlertColors.color,
                                                        borderColor: currentAlertColors.color,
                                                        // backgroundColor: currentAlertColors.color,
                                                    }}
                                                    role="button"
                                                    onClick={currentShootAlert?.emergency_alert ? closeForwardShoottAlert : closeShoottAlert}
                                                    color="none"
                                                >
                                                    x
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : ''}
                        </>
                    )}


                    <div className="d-flex align-items-center">
                        <CDropdown className="d-inline mx-2 position-relative pb-0" alignment={{ lg: 'end' }}>
                            {notify?.un_seen_notification_count > 0 && (
                                <span
                                    className="position-absolute top-0 start-100 translate-middle badge bg-danger text-white rounded-circle text-danger p-0 d-flex align-items-center justify-content-center"
                                    style={{ width: '1.4rem', height: '1.4rem', zIndex: 999 }}
                                >
                                    <span className="">
                                        {notify?.un_seen_notification_count}
                                    </span>
                                </span>
                            )}
                            <CDropdownToggle className="shadow-none" id="dropdown-autoclose-true">
                                <i className="fa fa-bell" />
                            </CDropdownToggle>

                            <CDropdownMenu
                                className="p-2 flex-column-reverse"
                                style={{ backgroundColor: '#36373c', color: '#fff', width: 340 }}
                            >
                                {notify?.notifications?.length > 0 ? (
                                    <div
                                        className="position-relative"
                                        style={{ overflowY: 'auto', height: 350 }}
                                    >
                                        <>
                                            {notify?.notifications?.map((item: any, index: any) => (
                                                <CDropdownItem
                                                    key={index}
                                                    className="d-flex align-items-center rounded-2 mb-2"
                                                    style={{ backgroundColor: item?.is_read ? '#dddddf22' : '' }}
                                                    role="button"
                                                    onClick={() => redirectNotification(item?.notification_type)}
                                                >
                                                    <div className="col" onClick={() => readNotification(item?.id)}>


                                                        <p className="fw-bold">
                                                            {item?.sender?.firstName} {" "}
                                                            {item?.sender?.lastName}
                                                        </p>

                                                        <p className="small fw-bold d-flex justify-content-between">
                                                            {item?.type == 'Message' && item?.title?.substring(0, 20)}
                                                            {item?.type == null && item?.title?.substring(0, 20)}
                                                            {item?.type == 'Voice' && 'Voice'}
                                                            {item?.type == 'Image' && 'Image'}

                                                            <span className="small text-capitalize fw-normal">
                                                                {item?.notification_type}
                                                            </span>
                                                        </p>
                                                        <p className="small d-flex justify-content-between">
                                                            {item?.description?.substring(0, 20) || '-'}

                                                            <span className="small">
                                                                {moment(item?.createdAt)?.fromNow()}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </CDropdownItem>
                                            ))}

                                            <CDropdownItem
                                                className="d-flex align-items-center rounded-2 mb-2 p-3 bg-black rounded-0"
                                                style={{ position: 'sticky', bottom: '-7px', right: 0, marginBottom: 0 }}
                                                role="button"
                                                onClick={clearAllNotification}
                                            >
                                                <div
                                                    className="col text-center"
                                                // onClick={() => readNotification(item?.id)}
                                                >
                                                    <p>
                                                        Clear All
                                                    </p>
                                                </div>
                                            </CDropdownItem>
                                        </>
                                    </div>
                                ) : (
                                    <CDropdownItem
                                        className="d-flex align-items-center rounded-2 mb-2"
                                        style={{ position: 'sticky', bottom: '-7px', right: 0, marginBottom: 0 }}
                                        role="button"
                                    >
                                        <div
                                            className="col text-center"
                                        // onClick={() => readNotification(item?.id)}
                                        >
                                            <p>
                                                No Data Found
                                            </p>
                                        </div>
                                    </CDropdownItem>
                                )}
                            </CDropdownMenu>
                        </CDropdown>
                        {checkPermission("View Emergency Alert") && (
                            <Link
                                to="/emergency-alert"
                                color="warning"
                                className="me-3 btn btn-primary position-relative shadow-none"
                            >
                                Emergency Alert
                                <span className="position-absolute top-0 start-100 translate-middle spinner-grow rounded-circle text-danger p-0" style={{ width: '1.4rem', height: '1.4rem' }}>
                                    <span className="visually-hidden">
                                        unread messages
                                    </span>
                                </span>
                            </Link>
                        )}
                        {checkPermission("View Quick Alert") && (
                            <CButton
                                color="warning"
                                className="me-2 alert-button bg-button-alert-red mt-0"
                                onClick={toggleVissibleAlert}
                            >
                                ALERT
                            </CButton>
                        )}
                        {/* <IconBellNotifications /> */}
                        <UserAvatar iconLetter={iconLetter} size={2} isLoggedUser />
                    </div>
                </CContainer>
            </CNavbar>

            <Alert visible={visibleAlert} closeModal={toggleVissibleAlert} />


            {isEdit && (
                <AlertModal
                    visible={Boolean(isEdit)}
                    closeModal={() => setIsEdit(null)}
                    isEdit={isEdit}
                    loadingClose={isEdit?.alertType ? loadingClose : loadingCloseShooter}
                    loadingApproved={loadingApproved}
                    closeAlert={isEdit?.alertType ? closeCurrentAlert : closeShoottAlert}
                    approved={isEdit?.alertType ? approvedAlert : approvedShooterAlert}
                    forward={handleIsForward}
                />
            )}

            {isForward && (
                <ApprovedModal
                    visible={Boolean(isForward)}
                    closeModal={() => setIsForward('')}
                    isEdit={isForward}
                    refetch={isForward?.alertType ? refreshAlerts : refreshShootAlerts}
                    roles={roles}
                />
            )}

            {isViewAlerts && (
                <ShowAlertsModal
                    visible={Boolean(isViewAlerts)}
                    closeModal={() => setIsViewAlerts('')}
                    listAlerts={isViewAlerts === 'shooterAlert' ? currentShootAlertArray : currentAlertArray}
                    renderAlerts={renderAlerts}
                />
            )}

        </>
    );
};

export default NavBar;
