import * as React from "react";
import "./LowBatteryDevices.scss";

import { MenuHeader } from "../../components/Atomics";
import { CSpinner } from "@coreui/react";

// services
import { getLowBatteryDevices } from "../../services/devicesService";
import { createMessageForAllLowBatteryDevices } from "../../services/messagesService";

//components
import { Pagination } from "..";
import { Spinner, Button } from "../Atomics";
import { ConfirmationDialog } from "../toast-dialogs";
import { LowBatteryDeviceItem } from ".";

// interfaces
import { Devices } from "../types";
import HandleUIError from "../../utils/HandleUIError";
import toast from "react-hot-toast";

const LowBatteryDevices: React.FC = () => {
  const [devices, setDevices] = React.useState<Array<Devices>>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [devicesPerPage] = React.useState<number>(6);
  const [allDevicesLoading, setAllDevicesLoading] = React.useState(false);

  const indexOfLastPost: number = currentPage * devicesPerPage;
  const indexOfFirstPost: number = indexOfLastPost - devicesPerPage;
  const currentDevices = devices.slice(indexOfFirstPost, indexOfLastPost);

  const pageNumbers: number[] = [];
  for (let i = 1; i <= Math.ceil(devices.length / devicesPerPage); i++) {
    pageNumbers.push(i);
  }

  React.useEffect(() => {
    getLowBatteryDevices()
      .then((res) => res)
      .then((data) => {
        const orderedDevices = data.sort((a, b) => {
          return a.battery - b.battery;
        });
        setDevices(orderedDevices);
        setLoading(false);
      });
  }, []);

  async function sendMessageForAllLowBatteryDevices() {
    setAllDevicesLoading(true);
    try {
      await createMessageForAllLowBatteryDevices();
      toast.success("Message sent successfully");
      setAllDevicesLoading(false);
    } catch (e) {
      setAllDevicesLoading(false);
      HandleUIError(e);
    }
  }

  function onPressSendAll() {
    ConfirmationDialog({
      title: "Send message to all low battery devices?",
      confirmAction: sendMessageForAllLowBatteryDevices,
    });
  }

  return (
    <div className="d-flex flex-column justify-content-between h-100 pb-4">
      <div>
        <div className="pt-2 pb-1 rounded-top low-battery-devices-header">
          <p className="text-light fs-5 ms-4 pb-2">Devices with low battery</p>
        </div>
        <MenuHeader className="mx-1 mx-sm-4 d-flex justify-content-between py-1 mt-3">
          <div className="d-flex flex-row w-100 justify-content-between">
            <p className="  ms-md-4 ms-3 text-color2 lowbattery-title">Users</p>
            <p className="text-color2  ms-5 d-flex justify-content-end battery-level lowbattery-title">
              Battery Level
            </p>
            <Button
              onClick={onPressSendAll}
              className="me-md-4 me-3 all-button"
              disabled={allDevicesLoading}
            >
              {allDevicesLoading ? <Spinner /> : "All"}
            </Button>
          </div>
        </MenuHeader>
        <div className="px-3 px-sm-5 mt-3 w-100">
          {devices.length === 0 && !loading ? (
            <div className="d-flex justify-content-between">
              <p className="text-light pb-3">No devices to display</p>
            </div>
          ) : !loading ? (
            currentDevices.map((device: Devices, index: number) => {
              return <LowBatteryDeviceItem device={device} />;
            })
          ) : (
            <div className="spinner">
              <CSpinner variant="grow" />
            </div>
          )}
        </div>
      </div>
      <div className="ms-0 ms-sm-4 mt-4 d-flex justify-content-center justify-content-sm-start">
        {pageNumbers.length > 1 ? (
          <Pagination
            numberOfPages={pageNumbers.length}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default LowBatteryDevices;
