import axiosConfig from "./axiosConfig";
import { AxiosError, AxiosResponse } from "axios";
import ApiError from "../utils/ApiError";
import { GroupResponse, GroupResponseById } from "./types";
import { User } from "../components/types";
// import firebase from "firebase/compat";
// import analytics = firebase.analytics;

export async function getAllGroups(): Promise<Array<GroupResponse>> {
    try {
      const response = await axiosConfig.get<AxiosResponse>(`/admin/groups`);
      return response.data.data as Array<GroupResponse>;
    } catch (e) {
      const err = e as AxiosError | Error;
      throw new ApiError(err);
    }
  }

  export async function getOneGroup(
      groupId: number
  ): Promise<GroupResponseById> {
    try {
      const response = await axiosConfig.get<AxiosResponse>(`/admin/groups/${groupId}`);
      return response.data.data as GroupResponseById;
    } catch (e) {
      const err = e as AxiosError | Error;
      throw new ApiError(err);
    }
  }

export async function addGroup(name: string, description: string): Promise<void> {
    try {
    const body = {
        "name": name,
        "description": description
        };
        await axiosConfig.post('admin/groups', body);
    } catch (e) {
        const err = e as AxiosError | Error;
        throw new ApiError(err);
    }
}

export async function deleteGroup(
    groupId: number
  ): Promise<void> {
    try {
      await axiosConfig.delete<AxiosResponse>(
        `/admin/groups/${groupId}`
      );
    } catch (e) {
      const err = e as AxiosError | Error;
      throw new ApiError(err);
    }
  }

export async function editGroupById(
    groupId: number,
    name: string,
    description: string
  ): Promise<void> {
    try {
      await axiosConfig.patch<AxiosResponse>(
        `/admin/groups/${groupId}`,
        {
          "name": name,
          "description": description
        }
      );
    } catch (e) {
      const err = e as AxiosError | Error;
      throw new ApiError(err);
    }
  }

export async function removeUserFromGroup(
    groupId: number,
    userId: number
  ): Promise<void> {
    try {
      await axiosConfig.delete<AxiosResponse>(
        `/admin/groups/${groupId}/user/${userId}`
      );
    } catch (e) {
      const err = e as AxiosError | Error;
      throw new ApiError(err);
    }
  }

export async function userView(
    id: any
): Promise<void> {
    try {
        let response: any = await axiosConfig.get<AxiosResponse>(
            `/admin/user-complete-detail/${id}`
        );
        return response;
    } catch (e) {
        const err = e as AxiosError | Error;
        throw new ApiError(err);
    }
}

export async function addUserToGroup(
    groupId: number,
    userId: number
  ): Promise<void> {
    try {
       await axiosConfig.post<AxiosResponse>(
        `/admin/groups/${groupId}/user/${userId}`
      );
    } catch (e) {
      const err = e as AxiosError | Error;
      throw new ApiError(err);
    }
  }

export async function searchUserToAddGroup(search: string, organizationId: number | null | undefined): Promise<Array<User>> {
    try {
      const response = await axiosConfig.get<AxiosResponse>(
        `/users?freeText=${search}&freeTextSearchFields=phone,email&filter[organizationId][eq]=${organizationId}`
      );
      return response.data.data as Array<User>;
    } catch (e) {
      const err = e as AxiosError | Error;
      throw new ApiError(err);
    }
  }