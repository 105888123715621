import React, {useState} from 'react';
import {Button, IconButton, Modal, Spinner} from "../Atomics";
import {cilTrash, cilX} from "@coreui/icons";
import {getAllGroups} from "../../services/groupService";
import HandleUIError from "../../utils/HandleUIError";
import toast from "react-hot-toast";
import {sendQuickAlert} from "../../services/alertServices";

const SendQuickAlerts = ({visible, closeModal, isEdit, groups}) => {
    const [loading, setLoading] = useState(false);
    const [group, setGroup] = useState('');
    const [message, setMessage] = useState('');

    const emptyAll = () => {
        setGroup('');
        setMessage('');
    }

    const onPressSend = async () => {
        if (!group) {
            toast.error('Please select a recipient group', {id: 35});
            return;
        }
        if (!message) {
            toast.error('Please enter a message', {id: 34});
            return;
        }

        setLoading(true);
        try {
            const response = await sendQuickAlert({group_id:parseInt(group), description:message, alert_type_id: isEdit?.id});
            console.log('response', response);
            toast.success('Alert sent successfully', {id: 36});
            closeModal();
        } catch (e) {
            HandleUIError(e);
        }
        setLoading(false);
        emptyAll();
    }




    return (
        <>
            <Modal
                visible={visible}
                onPressOut={() => {
                    closeModal()
                    emptyAll()
                }}
            >
                <div className="new-staff-container h-auto">
                    <div className="new-staff-header">
                        <p className="text-light py-2 ps-4 fs-5">
                            Send Quick Alert
                        </p>
                        <IconButton
                            icon={cilX} className="me-3"
                            onClick={() => {
                                closeModal()
                            }}
                        />
                    </div>

                    <div className="py-3">
                        <div className="px-3 mb-5">
                            <div className="d-flex flex-column align-items-start">
                                <p className="text-light mb-2 d-block">Alert Type:</p>
                                <select
                                    className="form-select"
                                    style={{
                                        backgroundColor: '#38373d',
                                        border: '1.5px solid #505050',
                                        color: '#51fffe'
                                    }}
                                    value={group}
                                    onChange={(e) => setGroup(e.target.value)}
                                >
                                    <option value="">Please Select Recipient Group</option>
                                    {groups?.map((template, index) => {
                                        return (
                                            <option value={template.id} key={index}>
                                                {template.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div className="d-flex flex-column align-items-start mt-3">
                                <p className="text-light mb-2 d-block">Alert Message:</p>
                                <textarea
                                    className="form-control"
                                    rows="3"
                                    style={{
                                        backgroundColor: '#38373d',
                                        border: '1.5px solid #505050',
                                        color: '#51fffe'
                                    }}
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-end px-3 pb-3">
                            <Button onClick={onPressSend} disabled={loading} className="px-4">
                                {loading ? <Spinner /> : "Save"}
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default SendQuickAlerts;