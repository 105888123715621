import * as React from "react";

//components
import { IconButton } from "../Atomics";
import { cilCommentBubble, cilTrash, cilPencil } from "@coreui/icons";

//services
import { GroupResponse } from "../../services/types";
import toast from "react-hot-toast";
import { deleteGroup } from "../../services/groupService";
import confirmationDialog from "../toast-dialogs/ConfirmationDialog";
import HandleUIError from "../../utils/HandleUIError";
import usePermission from "../../hooks/usePermission";

interface Props {
  id: number;
  name: string;
  participants: number;
  description: string;
  selectGroup: (group: GroupResponse) => void;
  refreshGroups: () => void
}

const GroupInformation: React.FC<Props> = ({
  id,
  name,
  participants,
  description,
  selectGroup,
  refreshGroups
}) => {
  const { checkPermission } = usePermission()

  function onPressEdit() {
    selectGroup({ id, name, participants, description });
  }

  function onPressMessage() {
    toast("Send a message to the devices of all the group's staff");
  }

  async function removeGroup(userId: number): Promise<void> {
    try {
      await toast.promise(deleteGroup(userId), {
        loading: "Removing group...",
        success: "Group removed successfully",
        error: "Error removing group",
      });
      refreshGroups();
    } catch (e) {
      HandleUIError(e);
    }
  }

  async function onPressTrash(groupId: number) {
    const confirmationDialogOptions = {
      title:
        `Are you sure you want to delete this group (${name})?`,
      confirmAction: () => removeGroup(groupId),
    };
    confirmationDialog(confirmationDialogOptions);

  }

  return (
    <div className="user-information-container d-flex py-3">
      <div className="d-flex w-100">
        <p className="text-light ps-4 section-group-1">{name}</p>
        <p className="text-light ps-3 section-group-2">{participants}</p>
        <p className="text-light ps-2 ms-4 section-group-3">{description ? description : "No description"}</p>
      </div>
      <div className="pe-3 d-flex align-items-center justify-content-between">
        {checkPermission('Create and Edit Group') && (
            <IconButton
                icon={cilPencil}
                size="lg"
                className="me-1"
                onClick={onPressEdit}
            />
        )}
        {/* <IconButton
          icon={cilCommentBubble}
          size="lg"
          className="me-1"
          onClick={onPressMessage}
          /> */}
        {checkPermission('Delete Group') && (
            <IconButton
                icon={cilTrash}
                size="lg"
                onClick={() => onPressTrash(id)}
            />
        )}

      </div>
    </div>
  );
};

export default GroupInformation;
