import * as React from "react";
import "../../views/Staff.scss";

import { cilPencil, cilTrash, cilWatch } from "@coreui/icons";
import { CAvatar } from "@coreui/react";
import { IconButton } from "../Atomics";
import EditUser from "./EditUser";
import { User } from "../types";
import AssociateDevice from "./AssociateDevice";
import toast from "react-hot-toast";
import { deleteUserAdmin } from "../../services/usersService";
import HandleUIError from "../../utils/HandleUIError";
import confirmationDialog from "../toast-dialogs/ConfirmationDialog";
import usePermission from "../../hooks/usePermission";

interface UserRowProps {
  user: User
  refreshUsers: () => void;
  setPreviousAction: (value: boolean) => void;
  previousAction: boolean;
}

const UsersRow: React.FC<UserRowProps> = ({ user, refreshUsers, setPreviousAction, previousAction }) => {
  const { checkPermission } = usePermission()
  const [visibleEditUser, setVisibleEditUser] = React.useState(false);
  const [visibleAssociateDevice, setVisibleAssociateDevice] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  function onPressEdit() {
    setVisibleEditUser(!visibleEditUser);
  }
  function onPressAssociate() {
    setVisibleAssociateDevice(!visibleAssociateDevice);
  }

  async function removeUser(userId: number): Promise<void> {
    try {
      await toast.promise(deleteUserAdmin(userId), { 
        loading: "Deleting user...",
        success: "User deleted from organization",
        error: "Error deleting user from organization",
      });
      setIsLoading(false);
      setPreviousAction(false);
      await refreshUsers();
    } catch (e) {
      HandleUIError(e);
    }
  }

  function onPressRemoveUser(userId: number) {
    setPreviousAction(true);
    setIsLoading(true);
    const confirmationDialogOptions = {
      title:
        "Are you sure you want to delete this user?",
      confirmAction: () => removeUser(userId),
      cancelAction: () => {
        setIsLoading(false)
        setPreviousAction(false)
      },
    };
    confirmationDialog(confirmationDialogOptions);
    setTimeout(() =>{
      setIsLoading(false);
      setPreviousAction(false);
    }, 4000)
  }

  return (
    <>
      <div className="d-flex py-3 user-information-container justify-content-around table-size">
        <div className="section-width-users-1 d-flex justify-content-center ">
          {user.avatarFile === null ? (
              <CAvatar
                  color="primary"
                  textColor="white"
                  size="lg"
              >
                {user.firstName ? user.firstName[0].toUpperCase() : "-"}
                {user.lastName ? user.lastName[0].toUpperCase() : "-"}
              </CAvatar>
          ) : (
              <CAvatar src={user.avatarFile} size="lg"/>
          )}
        </div>
        <div className="d-flex section-width-users-2 align-items-center">
          <p className="text-light word-break">
            {user.firstName ? user.firstName + " " : "-"}
            {user.lastName ? user.lastName : "-"}
          </p>
        </div>
        <div className="d-flex section-width-users-3 justify-content-start align-items-center">
          <p className="text-light ms-1 word-break">{user.email}</p>
        </div>
        <div className="section-width-users-4 d-flex align-items-center">
          <p className="text-light word-break">
            {user.phone ? user.phone : "-"}
          </p>
        </div>
        <div className="section-width-users-4 d-flex align-items-center">
          <p className="text-light word-break">
            {user?.role?.role_name ? user?.role?.role_name : "Admin"}
          </p>
        </div>
        <div className="section-width-users-5 staff-icon d-flex justify-content-end align-items-center">
          <div className="margin-icon">
            <IconButton
                icon={cilWatch}
                size="lg"
                className="ms-1"
                onClick={onPressAssociate}
            />
          </div>
          {checkPermission("Create and Edit Admin") && (
              <div className="margin-icon">
                <IconButton
                    icon={cilPencil}
                    size="lg"
                    onClick={onPressEdit}
                />
              </div>
          )}
          {checkPermission("Delete Admin") && (
              <div>
                {previousAction ? (
                    <IconButton
                        icon={cilTrash}
                        size="lg"
                        className="ms-1"
                        isLoading={isLoading}
                        onClick={() => {
                          toast.error("Please confirm the previous action")
                        }}
                    />
                ) : (
                    <IconButton
                        icon={cilTrash}
                        size="lg"
                        className="ms-1"
                        isLoading={isLoading}
                        onClick={() => {
                          onPressRemoveUser(user.id)
                        }}
                    />
                )}
              </div>
          )}

        </div>
      </div>
      <EditUser
          visible={visibleEditUser}
          closeModal={onPressEdit}
          refreshUsers={refreshUsers}
          userData={{
            id: user.id,
            firstName: user.firstName,
             lastName: user.lastName,
             email: user.email,
             phone: user.phone,
              roleId: user.roleId,
              password: user.password,
           }}/>
      <AssociateDevice
        visible={visibleAssociateDevice}
        closeModal={onPressAssociate}
        refreshUsers={refreshUsers}
        userId={user.id}
        userPhone={user.phone}
        />
    </>
  );
};

export default React.memo(UsersRow);
