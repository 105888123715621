import * as React from "react";
import "./Marker.scss";
import { OverlayView } from "@react-google-maps/api";

interface Props {
  coordinates: { lat: number; lng: number };
  number: number | string | undefined;
}

const NumberPerimeterMarker: React.FC<Props> = ({ coordinates, number }) => {
  return (
    <OverlayView mapPaneName="floatPane" position={coordinates}>
      <div className="number-perimeter-marker-container">
        <p className="text-color fs-3 fw-bold">{number}</p>
      </div>
    </OverlayView>
  );
};

export default NumberPerimeterMarker;
