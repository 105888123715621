import * as React from "react";
import "../../views/Staff.scss";
import { LocationMapModal } from ".";
//redux
import { MessageState } from "../../redux/types";
import { store } from "../../redux/store";
import { saveMessage } from "../../redux/reducers/messageReducer";

import CIcon from "@coreui/icons-react";
import { cilLocationPin, cilCommentBubble, cilTrash } from "@coreui/icons";
import { CAvatar, CFormSelect } from "@coreui/react";
import { UserAvatar, Spinner, IconButton } from "../Atomics";
import { LowBatteryIcon } from "../Atomics/LowBatteryIcon";
import { User } from "../../components/types";
import moment from "moment";

//service
import { getFullDeviceInfo } from "../../services/devicesService";
import HandleUIError from "../../utils/HandleUIError";
import { convertUTCtoLocale } from "../../utils/formatConverters";

interface DeviceRowProps {
  user: User;
  removeUser: (id: number) => void;
}

const lastConnectedExample = moment().subtract(3, "minutes").format();

const DeviceRowGroup: React.FC<DeviceRowProps> = ({ user, removeUser }) => {
  // const [showDeviceLocation, setShowDeviceLocation] = React.useState(false);
  // const [deviceLastCoordinates, setDeviceLastCoordinates] = React.useState<{
  //   lng: number;
  //   lat: number;
  // }>({ lat: 44.5, lng: -89.5 });
  // //   const [loadingCoordinates, setLoadingCoordinates] = React.useState(false);
  // const [lastConnected, setLastConnected] =
  //   React.useState<string>(lastConnectedExample);
  //   const [deviceSelected, setDeviceSelected] = React.useState<number>(0)
  const [showDeviceLocation, setShowDeviceLocation] = React.useState(false);
  const [deviceLastCoordinates, setDeviceLastCoordinates] = React.useState<{
    lng: number;
    lat: number;
  }>();
  const [loadingCoordinates, setLoadingCoordinates] = React.useState(false);
  const [deviceSelected, setDeviceSelected] = React.useState<number>(0);
  //   React.useEffect(
  //     function () {
  //       getDeviceCoordinates();
  //     },
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //     [device]
  //   );

  //   async function getDeviceCoordinates() {
  //     setLoadingCoordinates(true);
  //     try {
  //       const res = await getFullDeviceInfo(device.id);
  //       const lastCoordinates = res.lastHistory
  //         ? { lat: res.lastHistory.latitude, lng: res.lastHistory.longitude }
  //         : undefined;
  //       setDeviceLastCoordinates(lastCoordinates);
  //       setLastConnected(res.lastHistory?.timestamp);
  //       setLoadingCoordinates(false);
  //     } catch (e) {
  //       setLoadingCoordinates(false);
  //       HandleUIError(e);
  //     }
  //   }
  function deviceIsOnline(deviceHour: string) {
    let hour = moment();
    let hourDevice = moment(convertUTCtoLocale(deviceHour));
    if (hour.diff(hourDevice) <= 600000) {
      return true;
    } else {
      return false;
    }
  }

  function closeModal() {
    setShowDeviceLocation(false);
  }
  function openModal() {
    setShowDeviceLocation(true);
  }
  function onChangeDevice(device: any) {
    setDeviceSelected(device);
  }

  function onPushMessageBubble(deviceId: number, ownerName: string) {
    const messageData: MessageState = {
      endpointService: "device",
      headerText: `New message to ${ownerName}`,
      visible: true,
      deviceId,
    };
    store.dispatch(saveMessage(messageData));
  }

  return (
    <>
      <div className="d-flex py-3 user-information-container">
        <div className="section-width-1 d-flex justify-content-center">
          {user.avatarFile === null && (
            <>
              {user.devices.length > 0 &&
              user?.devices[deviceSelected]?.updatedAt ? (
                <CAvatar
                  color="primary"
                  textColor="white"
                  size="lg"
                  status={
                    deviceIsOnline(user.devices[deviceSelected].updatedAt)
                      ? "success"
                      : "danger"
                  }
                >
                  {user.firstName ? user.firstName[0].toUpperCase() : null}
                  {user.lastName ? user.lastName[0].toUpperCase() : null}
                </CAvatar>
              ) : (
                <CAvatar
                  color="primary"
                  textColor="white"
                  size="lg"
                  status="danger"
                >
                  {user.firstName ? user.firstName[0].toUpperCase() : null}
                  {user.lastName ? user.lastName[0].toUpperCase() : null}
                </CAvatar>
              )}
            </>
          )}
          {user.avatarFile !== null && (
            <>
              {user.devices.length > 0 &&
              user.devices[deviceSelected].updatedAt ? (
                <CAvatar
                  src={user.avatarFile}
                  size="lg"
                  status={
                    deviceIsOnline(user.devices[deviceSelected].updatedAt)
                      ? "success"
                      : "danger"
                  }
                />
              ) : (
                <CAvatar src={user.avatarFile} size="lg" status="danger" />
              )}
            </>
          )}
        </div>
        {user !== null ? (
          <div className="section-width-2">
            <p className="text-light word-break">
              {user.firstName} {user.lastName}
            </p>
            <p className="text-secondary word-break">
              {user.email} {user.id ? "|" : ""} {user.id}
              {/* TODO: temporalmente se muestra el id del device, para que sea mas sencilla la presentacion, pero luego se volvera a poner el owner's phone en esta linea  */}
            </p>
          </div>
        ) : (
          <div className="section-width-2">
            <p className="text-light">No owner</p>
          </div>
        )}
        <div className="d-flex section-width-3 justify-content-start align-items-center">
          {user.devices.length > 0 ? (
            <CFormSelect
              value={deviceSelected}
              onChange={(e) => onChangeDevice(e.target.value)}
              className="template-select select-custom-width"
            >
              {user.devices.map(function (device, idx) {
                return <option value={idx}>{device.name}</option>;
              })}
            </CFormSelect>
          ) : (
            <CFormSelect className="template-select select-custom-width">
              <option>No device</option>
            </CFormSelect>
          )}
        </div>
        <div className="d-flex section-width-4 justify-content-start align-items-center">
          {user.devices.length > 0 ? (
            <>
              <LowBatteryIcon
                batteryLevel={user.devices[deviceSelected].battery}
                className={"staff-icon ms-2"}
              />
              {user.devices[deviceSelected].battery === null ? (
                <>
                  <p className="text-secondary label-staff word-break d-block d-sm-none">
                    Battery:
                  </p>
                  <p className="text-light">-</p>
                </>
              ) : (
                <p className="text-color fs-5 ms-1">
                  {user.devices[deviceSelected].battery}%
                </p>
              )}
            </>
          ) : (
            <>
              <p className="text-secondary label-staff word-break d-block d-sm-none">
                Battery:
              </p>
              <p className="text-light">-</p>
            </>
          )}
        </div>
        <div className="section-width-5 d-flex align-items-center">
          <p className="text-light word-break">
            {user.devices.length > 0 && user.devices[deviceSelected].updatedAt
              ? moment(
                  convertUTCtoLocale(user.devices[deviceSelected].updatedAt)
                ).format("L") +
                " " +
                moment(
                  convertUTCtoLocale(user.devices[deviceSelected].updatedAt)
                ).format("LTS")
              : "(Not registered)"}
          </p>
        </div>
        <div className="section-width-6 d-flex align-items-center">
          {user.devices.length > 0 ? (
            <>
              <p className="text-secondary label-staff word-break d-block d-sm-none">
                Device IMEI:
              </p>
              <p className="text-light word-break">
                {user.devices[deviceSelected].imei}
              </p>
            </>
          ) : (
            <>
              <p className="text-secondary label-staff word-break d-block d-sm-none">
                Device IMEI:
              </p>
              <p className="text-light word-break">-</p>
            </>
          )}
        </div>
        <div className="section-width-7 staff-icon d-flex align-items-center justify-content-end">
          {loadingCoordinates ? (
            <div className="margin-icon">
              <Spinner />
            </div>
          ) : (
            <>
              {deviceLastCoordinates && (
                <div className="margin-icon">
                  <CIcon
                    onClick={openModal}
                    icon={cilLocationPin}
                    className="staff-icon-animated"
                    size="lg"
                  />
                </div>
              )}
            </>
          )}
          {user.devices.length > 0 && user.devices[deviceSelected].updatedAt ? (
            <div>
              {deviceIsOnline(user.devices[deviceSelected].updatedAt) ? (
                <div>
                  <IconButton
                    icon={cilCommentBubble}
                    size="lg"
                    onClick={() =>
                      onPushMessageBubble(
                        user.devices[deviceSelected].id,
                        user.firstName + " " + user.lastName
                      )
                    }
                  />
                  <IconButton
                    icon={cilTrash}
                    size="lg"
                    className="ms-1"
                    onClick={() => removeUser(user.id)}
                  />
                </div>
              ) : (
                <div>
                  <IconButton
                    icon={cilTrash}
                    size="lg"
                    onClick={() => removeUser(user.id)}
                  />
                </div>
              )}
            </div>
          ) : (
            <div>
              <IconButton
                icon={cilTrash}
                size="lg"
                onClick={() => removeUser(user.id)}
              />
            </div>
          )}
        </div>
      </div>
      <LocationMapModal
        deviceName={`${user.firstName} ${user.lastName}`}
        visible={showDeviceLocation}
        closeModal={closeModal}
        coordinates={deviceLastCoordinates}
      />
    </>
  );
};

export default DeviceRowGroup;
