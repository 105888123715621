import * as React from "react";
import "../staff/NewStaff.scss";
import "./AssociateDevice.scss";
import HandleUIError from "../../utils/HandleUIError";
import toast from "react-hot-toast";

//components
import { Modal, Input, IconButton, Spinner, Button } from "../Atomics";
import { cilX } from "@coreui/icons";

//services
import {
  associateDevice,
  associateNewDevice,
} from "../../services/usersService";
import confirmationDialog from "../toast-dialogs/ConfirmationDialog";
import { useAppSelector } from "../../redux/hooks";

interface Props {
  visible: boolean;
  userId: number;
  userPhone: string;
  closeModal: () => void;
  refreshUsers: () => void;
}

const AssociateDevice: React.FC<Props> = ({
  visible,
  userId,
  userPhone,
  closeModal,
  refreshUsers,
}) => {
  const [IMEI, setIMEI] = React.useState("");
  const [serialNro, setSerialNro] = React.useState("");
  const [ICCID, setICCID] = React.useState("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingButton, setIsLoadingButton] = React.useState<boolean>(false);
  const [associate, setAssociate] = React.useState({
    existent: true,
    new: false,
  });
  const organization = useAppSelector((state) => state.organizationSlice);

  const handleInputIMEI = (e: any) => {
    setIMEI(e.target.value);
  };

  const handleInputSerialNro = (e: any) => {
    setSerialNro(e.target.value);
  };

  const handleInputICCID = (e: any) => {
    setICCID(e.target.value);
  };

  async function handleAssociateDevice(e: any) {
    if (e.charCode === 13 && !isLoading) {
      try {
        await associateDeviceToUser(userId, IMEI, userPhone);
      } catch (e) {
        HandleUIError(e);
      }
    }
  }

  async function onDeviceNotExists() {
    const confirmationDialogOptions = {
      title: `Device already associated with a user or doesn't exist. Do you want to add a new device?`,
      confirmAction: () => onConfirmAddDevice(),
      cancelAction: () => onCloseModal(),
    };
    confirmationDialog(confirmationDialogOptions);
  }

  function onConfirmAddDevice() {
    setAssociate({
      existent: false,
      new: true,
    });
  }

  async function associateDeviceToUser(
    userId: number,
    imei: string,
    phoneNo: string
  ): Promise<void> {
    setIsLoading(true);
    try {
      await associateDevice(userId, imei, phoneNo);
      await refreshUsers();
      toast.success("Device associated successfully");
      setIsLoading(false);
      onCloseModal();
    } catch (e) {
      setIsLoading(false);
      onDeviceNotExists();
      HandleUIError(e);
    }
  }

  async function associateNewDeviceToUser(
    userId: number,
    IMEI: string,
    ICCID: string,
    serialNo: string
  ): Promise<void> {
    setIsLoadingButton(true);
    try {
      await associateNewDevice(userId, IMEI, ICCID, serialNo, organization.id);
      await refreshUsers();
      toast.success("Device created and associated successfully");
      setIsLoadingButton(false);
      onCloseModal();
    } catch (e) {
      onCloseModal();
      setIsLoadingButton(false);
      HandleUIError(e);
    }
  }

  function onCloseModal() {
    setIMEI("");
    setSerialNro("");
    setICCID("");
    setAssociate({
      existent: true,
      new: false,
    });
    closeModal();
  }

  return (
    <Modal visible={visible} onPressOut={onCloseModal}>
      {associate.existent && (
        <div className="associate-device-container">
          <div className="new-staff-header">
            <p className="text-light py-2 ps-4 fs-4">Associate Device</p>
            <IconButton icon={cilX} className="me-3" onClick={onCloseModal} />
          </div>
          <div className="search-phone">
            <p className="text-light fs-5 ms-3 pt-3">Search by Device IMEI</p>
            <Input
              className="mt-2 mb-3 ms-3 search-phone-input"
              onChange={handleInputIMEI}
              onKeyPress={(e) => handleAssociateDevice(e)}
              autoFocus
            />
            {isLoading && (
              <div className="d-flex justify-content-center">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      )}
      {associate.new && (
        <div className="associate-device-container">
          <div className="new-staff-header">
            <p className="text-light py-2 ps-4 fs-4">
              Create and associate device
            </p>
            <IconButton icon={cilX} className="me-3" onClick={closeModal} />
          </div>
          <div className="search-phone">
            <p className="text-light fs-5 ms-3 pt-3 mb-2">IMEI Number:</p>
            <Input
              type="number"
              className="mb-3 ms-3 search-phone-input"
              onChange={handleInputIMEI}
            />
            <p className="text-light fs-5 ms-3 pt-3 mb-2">Serial Number:</p>
            <Input
              className="mb-3 ms-3 search-phone-input"
              onChange={handleInputSerialNro}
            />
            <p className="text-light fs-5 ms-3 pt-3 mb-2">ICCID:</p>
            <Input
              className="mb-3 ms-3 search-phone-input"
              onChange={handleInputICCID}
            />
          </div>
          {isLoadingButton ? (
            <div className="d-flex justify-content-center pe-3 py-3 mt-3">
              <Button className="px-4" disabled>
                <Spinner />
              </Button>
            </div>
          ) : (
            <div className="d-flex justify-content-center pe-3 py-3 mt-3">
              <Button
                className="px-4"
                onClick={() =>
                  associateNewDeviceToUser(userId, IMEI, ICCID, serialNro)
                }
              >
                Save
              </Button>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default AssociateDevice;
