import * as React from "react";
import "./Staff.scss";

//redux
import { MessageState } from "../redux/types";
import { store } from "../redux/store";
import { saveMessage } from "../redux/reducers/messageReducer";
import { useAppSelector } from "../redux/hooks";

//components
import { Pagination } from "../components";
import {
  NewStaff,
  StaffList,
  GroupList,
  NewGroup,
  GroupContent,
} from "../components/staff";
import { NewMessage } from "../components/messages";
import { Button, PageContainer } from "../components/Atomics";
import {
  GroupButtons,
  ButtonForGroup,
} from "../components/Atomics/GroupButtons";
import CIcon from "@coreui/icons-react";
import { cilPlus } from "@coreui/icons";
import HandleUIError from "../utils/HandleUIError";

// services
import { searchUsers, searchUsersAlt } from "../services/staffService";
import { GroupResponse } from "../services/types";
import { getAllGroups } from "../services/groupService";
import useDebounce from "../hooks/useDebounce";
import { useEffect, useState } from "react";
import { getRolesUser, searchParents } from "../services/rolesService";
import RolesList from "../components/roles-user/RolesList";
import usePermission from "../hooks/usePermission";

type InFacilityOptions = "all" | "in" | "off";

const Staff: React.FC = () => {
  const { checkPermission } = usePermission()
  const visibleNewMesssage = useAppSelector(
    (state) => state.MessageSlice.visible
  );
  const [tab, setTab] = React.useState('')
  const [staffGroups, setStaffGroups] = React.useState({
    staff: true,
    groups: false,
  });
  const [filters, setFilters] = React.useState({
    all: false,
    inFacility: false,
    offFacility: false,
  });
  const [visibleNewStaff, setVisibleNewStaff] = React.useState(false);
  const [visibleNewGroup, setVisibleNewGroup] = React.useState(false);
  const [visibleNewMessageToAll, setVisibleNewMessageToAll] =
    React.useState(false);
  const [currentUsers, setCurrentUsers] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [currentPageStaff, setCurrentPageStaff] = React.useState<number>(1);
  const [usersPageSize] = React.useState<number>(7);
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [pageNumbersStaff, setPageNumbersStaff] = React.useState<number>(1);
  const [groupSelected, setGroupSelected] =
    React.useState<GroupResponse | null>(null);
  const [inFacility, setInFacility] = React.useState<InFacilityOptions>("all");
  // Groups
  const [groups, setGroups] = React.useState<Array<GroupResponse>>([]);
  const [isLoadingGroups, setIsLoadingGroups] = React.useState<boolean>(true);
  const [roles, setRoles] = useState<any>([])
  const [newUsers, setNewUsers] = useState<any>([])
  const [roleType, setRoleType] = useState<string>("")

  React.useEffect(function () {
    getAllGroups()
      .then((groupResponse) => {
        setGroups(groupResponse);
        setIsLoadingGroups(false);
      })
      .catch((e) => {
        HandleUIError(e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(function () {
    refreshGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function refreshGroups() {
    setIsLoadingGroups(true);
    try {
      getAllGroups().then((groupResponse) => {
        setGroups(groupResponse);
        setIsLoadingGroups(false);
      });
    } catch (e) {
      HandleUIError(e);
      setIsLoadingGroups(false);
    }
  }

  const debouncedSearch = useDebounce(searchValue, 300);

  function searchUsersDebounced() {
    let userType = (roleType == "web") ? 'undefined': '1';
    searchUsersAlt(tab, usersPageSize, 1, debouncedSearch, inFacility, userType)
      .then((usersFound) => {
        setCurrentPageStaff(1);
        setCurrentUsers(usersFound?.data);
        setPageNumbersStaff(usersFound.numberOfTotalUsers / usersPageSize);
      })
      .catch((e) => {
        HandleUIError(e);
      });
  }

  console.log("currentUsers", currentUsers)

  React.useEffect(
    function () {
      if (debouncedSearch) {
        searchUsersDebounced();
      } else {
        let userType = (roleType == "web") ? 'undefined': '1';
        searchUsersAlt(tab, usersPageSize, 1, searchValue, inFacility, userType)
          .then((usersFound) => {
            console.log("usersFound", usersFound)
            setCurrentPageStaff(1);
            setCurrentUsers(usersFound?.data);
            setPageNumbersStaff(usersFound.numberOfTotalUsers / usersPageSize);
          })
          .catch((e) => {
            HandleUIError(e);
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearch]
  );


  React.useEffect(() => {
    if (inFacility) {
      console.log('inFacility', inFacility)
      refreshUsers()
    }
  }, [tab, inFacility]);

  React.useEffect(() => {
    if (tab) refreshUsers();
  }, [tab]);

  React.useEffect(() => {
    refreshUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageStaff]);

  function onChangeSearchValue(e: any) {
    setSearchValue(e.target.value);
  }

  //this function is treggered by pagination
  async function refreshUsers() {
    setLoading(true);
    try {
      let userType = (roleType == "web") ? 'undefined': '1';
      let usersFound: any = await searchUsersAlt(
        tab,
        usersPageSize,
        currentPageStaff,
        searchValue,
        inFacility, 
        userType
      );
      console.log('usersFound===============', usersFound)
      if(roleType == "app"){
        // alert("app")
        const filterCurrentUser = usersFound?.data?.filter((item: any) => item.is_complete_register == true)
        setCurrentUsers(filterCurrentUser);
      }else{
        // alert("web")
        setCurrentUsers(usersFound?.data);
      }
      setPageNumbersStaff(usersFound.numberOfTotalUsers / usersPageSize);
      setLoading(false);
    } catch (e) {
      HandleUIError(e);
      setLoading(false);
    }
  }

  function onPressAdd() {
    if (staffGroups.staff) {
      setVisibleNewStaff(!visibleNewStaff);
    } else {
      setVisibleNewGroup(!visibleNewGroup);
    }
  }

  function toggleNewMessage(headerText: string, endpointService: any) {
    const messageData: MessageState = {
      endpointService: endpointService,
      headerText: headerText,
      visible: !visibleNewMesssage,
      deviceId: null,
    };
    store.dispatch(saveMessage(messageData));
    setVisibleNewMessageToAll(!visibleNewMessageToAll);
  }

  function changeToStaff() {
    setStaffGroups({
      staff: true,
      groups: false,
    });
  }

  function changeToGroups() {
    setStaffGroups({
      staff: false,
      groups: true,
    });
  }

  function changeToAll() {
    setInFacility("all");
    setFilters({
      all: true,
      inFacility: false,
      offFacility: false,
    });
  }

  function changeToInFacility() {
    setInFacility("in");
    setFilters({
      all: false,
      inFacility: true,
      offFacility: false,
    });
  }

  function changeToOffFacility() {
    setInFacility("off");
    setFilters({
      all: false,
      inFacility: false,
      offFacility: true,
    });
  }

  function closeGroupContent() {
    setGroupSelected(null);
  }

  const fetch = async () => {
    const data = await searchParents(0, 1, '')
    const data2 = data?.data?.reverse()
    setRoles(data2)
    setRoleType(`${data2[0]?.role_type}`)
    setTab(`${data2[0]?.id}`)
  }

  useEffect(() => {
    fetch()
  }, []);

  return (
    <div className="bg-main pb-5 over-flow-auto h-100">
      <PageContainer className="page-container-staff d-flex  flex-column justify-content-between pb-3 mx-4">
        {!groupSelected ? (
          <>
            <div>
              <div className="row g-3 mb-4 align-items-end">
                <div className="col-lg-7">
                  <div className="row g-2 align-items-end ">
                    <div className="col-auto">
                      <GroupButtons className="mg-top-2 mg-bot mg-lt m-0">
                        {checkPermission("View Staff") && (
                          <>
                            {roles?.map((role: any) => (
                              <ButtonForGroup
                                label={`${role?.role_name}`}
                                checked={tab == `${role?.id}`}
                                onClick={() => {
                                  setTab(`${role?.id}`)
                                  setRoleType(role?.role_type)
                                  changeToStaff()
                                }}
                              />
                            ))}
                          </>
                        )}


                        {/*{checkPermission('View Group') && (
                            <ButtonForGroup
                                label="Groups"
                                checked={staffGroups.groups}
                                onClick={() =>{
                                  setTab('')
                                  changeToGroups()
                                }}
                            />
                        )}*/}

                      </GroupButtons>
                    </div>
                    <div className="col-auto">
                      {staffGroups.staff && (
                        <div className="wrap filters-field center-text m-0">
                          <p className="text-light me-3">Filters: </p>
                          <div className="wrap mg-bot m-0">
                            <GroupButtons className="content-width-mb">
                              <ButtonForGroup
                                label="All"
                                onClick={() => {
                                  changeToAll()
                                }}
                                checked={filters.all}
                              />
                              <ButtonForGroup
                                label="In Facility"
                                onClick={() => {
                                  changeToInFacility()
                                }}
                                checked={filters.inFacility}
                              />
                              <ButtonForGroup
                                label="Off Facility"
                                onClick={() => {
                                  changeToOffFacility()
                                }}
                                checked={filters.offFacility}
                              />
                            </GroupButtons>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-lg-5 ">
                  <div className="row g-2 align-items-end justify-content-end">
                    <div className="col-6">
                      {staffGroups.staff && (
                        <div className="wrap mg-bot mg-lt center-text m-0">
                          <p className="text-light me-2 m-0">Search:</p>
                          <input
                            value={searchValue}
                            onChange={onChangeSearchValue}
                            className="input-search w-100"
                            type="text"
                          />
                        </div>
                      )}
                    </div>
                    <div className="col-auto ">
                      <div className="d-flex align-self-end mt-3 mt-xxl-0 buttons-right wrap m-0">
                        {staffGroups.staff && (
                          <Button
                          className="d-none"
                            onClick={() =>
                              toggleNewMessage("New message to all devices", "all")
                            }
                          >
                            Message to All
                          </Button>
                        )}


                        {staffGroups.staff ? (
                          checkPermission("Create And Edit Staff") && (
                            <div
                              className="add-icon-container mg-lt add-icon-staff"
                              onClick={onPressAdd}
                            >
                              <CIcon icon={cilPlus} size="xl" className="add-icon" />
                            </div>
                          )
                        ) : (
                          checkPermission("Create And Edit Group") && (
                            <div
                              className="add-icon-container mg-lt add-icon-staff"
                              onClick={onPressAdd}
                            >
                              <CIcon icon={cilPlus} size="xl" className="add-icon" />
                            </div>
                          )
                        )}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <>

                {staffGroups.staff ? (
                  <StaffList
                    users={currentUsers}
                    roleType={roleType}
                    loading={loading}
                    refreshUsers={refreshUsers}
                  />
                ) : (
                  <GroupList
                    setGroupSelected={setGroupSelected}
                    groups={groups}
                    refreshGroups={refreshGroups}
                    isLoadingGroups={isLoadingGroups}
                  />
                )}
              </>
            </div>
            {staffGroups.staff && (
              <>
                {pageNumbersStaff > 1 ? (
                  <Pagination
                    numberOfPages={pageNumbersStaff}
                    setCurrentPage={setCurrentPageStaff}
                    currentPage={currentPageStaff}
                  />
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        ) : (
          <GroupContent
            closeView={closeGroupContent}
            group={groupSelected}
            refreshGroups={refreshGroups}
          />
        )}
      </PageContainer>
      <NewStaff
        visible={visibleNewStaff}
        closeModal={onPressAdd}
        refreshUsers={refreshUsers}
      />
      <NewGroup
        closeModal={onPressAdd}
        visible={visibleNewGroup}
        refreshGroups={refreshGroups}
        refreshGroupInfo={refreshGroups}
      />
      <NewMessage />
    </div>
  );
};

export default Staff;
