import * as React from "react";
import "./Atomics.scss";
import { inputEvent } from "../../utils/types";

type inputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;
interface Props extends inputProps {
  className?: string;
  type?: React.InputHTMLAttributes<HTMLInputElement>["type"];
  onChange?: (e: inputEvent) => void;
  onKeyPress?: (e: inputEvent) => void;
}

const Input: React.FC<Props> = ({
  className,
  type,
  onChange,
  onKeyPress,
  autoFocus,
  value,
  defaultValue,
}) => {
  const [isShow, setIsShow] = React.useState(false)

  const handleShow = () => {
    setIsShow(pre => !pre)
  }
  return (
      <>
        {type === "password" ? (
            <div className={`input-group mb-3 border-0 px-0 ${className}`}>
              <input
                  value={value}
                  defaultValue={defaultValue}
                  autoFocus={autoFocus}
                  onKeyPress={onKeyPress}
                  onChange={onChange}
                  type={isShow ? 'text' : type}
                  className={`input-custom form-control h-100`}
              />
              <span className="input-group-text" id="basic-addon2">
                {isShow ? <i role="button" className="fa fa-eye" onClick={handleShow} /> : <i role="button" className="fa fa-eye-slash" onClick={handleShow} />}
              </span>
            </div>
        ) : (
            <input
                value={value}
                defaultValue={defaultValue}
                autoFocus={autoFocus}
                onKeyPress={onKeyPress}
                onChange={onChange}
                type={type}
                className={`input-custom ${className}`}
            />
        )}
      </>

  );
};

export default Input;
