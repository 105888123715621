import * as React from "react";
import {Route, Switch} from "react-router-dom";

import {Alerts, AlertTypeAction, Dashboard, Facility, Messages, NewPerimeter, Staff,} from "../views";
import ParentsComp from "../views/Parents";
import Admins from "../views/Admins";
import RolesComp from "../views/Roles";
import RolesUserComp from "../views/RolesUser";
import GroupAlert from "../views/GroupAlert";
import EmergencyContacts from "../views/EmergencyContacts";
import EmergencyAlert from "../views/EmergencyAlert";
import AppUser from "../views/AppUser";
import AlarmAlert from "../views/AlarmAlert";
import Permissions from "../views/Permissions";
import usePermission from "../hooks/usePermission";
import ActivityLogs from "../views/ActivityLogs";
import EmergencyContactsRelation from "../views/EmergencyContactsRelation";

const LoadRoutes: React.FC = () => {
    const {checkPermission} = usePermission()
    return (
        <Switch>
            {checkPermission('View Facility') && (
                <Route path="/facility">
                    <Facility />
                </Route>
            )}
            {(checkPermission('View Staff') || checkPermission('View Staff')) && (
                <Route path="/staff">
                    <Staff />
                </Route>
            )}
            {checkPermission('View Message') && (
                <Route path="/messages">
                    <Messages />
                </Route>
            )}
            {(checkPermission('View History') ||
                checkPermission('View Settings') ||
                checkPermission('View Categories')
            ) && (
                <Route path="/alerts">
                    <Alerts />
                </Route>
            )}
            <Route path="/alert-type-action">
                <AlertTypeAction />
            </Route>
            {checkPermission('Create and Edit Facility') && (
                <Route path="/new-perimeter">
                    <NewPerimeter />
                </Route>
            )}
            {checkPermission('View Parents') && (
                <Route path="/community">
                    <ParentsComp />
                </Route>
            )}
            {checkPermission('View Roles') && (
                <Route path="/roles">
                    <RolesComp />
                </Route>
            )}
            {checkPermission("View Group Alerts") && (
                <Route path="/group-alert">
                    <GroupAlert />
                </Route>
            )}
            {checkPermission("View Emergency Alert") && (
                <Route path="/emergency-alert">
                    <EmergencyAlert />
                </Route>
            )}
            {checkPermission("View Emergency Contact") && (
                <Route path="/emergency-contact">
                    <EmergencyContacts />
                </Route>
            )}
            {checkPermission("View Emergency Contact") && (
                <Route path="/emergency-contact-relation">
                    <EmergencyContactsRelation />
                </Route>
            )}
            {checkPermission("View Role Users") && (
                <Route path="/roles-user/:id">
                    <RolesUserComp />
                </Route>
            )}

            <Route path="/activity-logs/:id">
                <ActivityLogs />
            </Route>

            <Route path="/activity-logs">
                <ActivityLogs />
            </Route>

            <Route path="/permission/:id">
                <Permissions />
            </Route>

            {checkPermission('View Admin') && (
                <Route path="/admins">
                    <Admins />
                </Route>
            )}
            {checkPermission('View App Users') && (
                <Route path="/app-user">
                    <AppUser />
                </Route>
            )}
            {checkPermission("View Alarm Alerts") && (
                <Route path="/alarm-alerts">
                    <AlarmAlert />
                </Route>
            )}
            {(checkPermission('View Quick Alert') ||
                checkPermission('Send Quick Alert') ||
                checkPermission('Send Group Message') ||
                checkPermission('Save School Details')
            ) && (
                <Route path="/">
                    <Dashboard />
                </Route>
            )}
        </Switch>
    );
};
export default LoadRoutes;