import * as React from "react";
import "../../views/Staff.scss";

import { cilPencil, cilTrash, cilUser, cilLockLocked } from "@coreui/icons";
import { IconButton } from "../Atomics";
import { Roles } from "../types";
import EditRole from "./EditRole";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { saveRolesUser } from "../../redux/reducers/userReducer";
import { useAppSelector } from "../../redux/hooks";
import usePermission from "../../hooks/usePermission";
//service

interface ParentsRowProps {
    parent: Roles;
    removeUser: (id: number) => void;
    refreshParents: () => void
}




const RolesRow: React.FC<ParentsRowProps> = ({ parent, removeUser, refreshParents }) => {
    const { checkPermission } = usePermission()
    const userSlice: any = useAppSelector((state) => state.UserSlice);
    const dispatch = useDispatch()
    const navigate = useHistory();
    const [visibleEditParent, setVisibleEditParent] = React.useState(false);

    function onPressAdd() {
        setVisibleEditParent(!visibleEditParent);
    }

    return (
        <>
            <div className="d-flex px-2 py-3 user-information-container justify-content-around table-size">
                <div className="section-width-parents-1 d-flex justify-content-start">
                    <p className="text-light word-break">
                        {parent.role_name}
                    </p>
                </div>
                <div className="section-width-parents-1 d-flex justify-content-start">
                    <p className="text-light word-break">
                        {parent?.group?.name}
                    </p>
                </div>
                {/* <div className="section-width-parents-1 d-flex justify-content-start">
                <p className="text-light word-break">
                    {parent?.users?.length}
                </p>
            </div> */}
                <div className="section-width-parents-1 d-flex justify-content-start">
                    <p className="text-light word-break">
                        {parent?.users?.filter((user:any) => !!user.email).length || 0}
                    </p>
                </div>
                <div className="section-width-parents-1 d-flex justify-content-start">
                    <p className="text-light word-break">
                        {parent?.role_type}
                    </p>
                </div>
                <div className="section-width-parents-4 d-flex justify-content-end align-items-center">
                    {parent?.role_type == 'web' && (
                        <div className="margin-icon">
                            <IconButton
                                icon={cilLockLocked}
                                size="lg"
                                onClick={() => navigate.push(`/permission/${parent?.id}`, {
                                    state: parent?.permissions ? JSON.parse(parent?.permissions) : [],
                                })}
                            />
                        </div>
                    )}
                    {checkPermission("View Role Users") && (
                        <div className="margin-icon">
                            <IconButton
                                icon={cilUser}
                                size="lg"
                                onClick={() => {
                                    dispatch(saveRolesUser({ id: parent.id ? parent.id : 0 }))
                                    navigate.push(`/roles-user/${parent.id}`, {
                                        state: parent?.role_type
                                    })
                                }}
                            />
                        </div>
                    )}
                    {checkPermission("Create and Edit Role") && (
                        <>
                            {userSlice?.user?.RoleId ? '' : (
                                <div className="margin-icon">
                                    <IconButton
                                        icon={cilPencil}
                                        size="lg"
                                        onClick={() => onPressAdd()}
                                    />
                                </div>
                            )}
                        </>
                    )}

                    {checkPermission("Delete Role") && (
                        <>
                            {userSlice?.user?.RoleId ? '' : (
                                <div>
                                    <IconButton
                                        icon={cilTrash}
                                        size="lg"
                                        className="ms-1"
                                        onClick={() => removeUser(parent.id)}
                                    />
                                </div>
                            )}
                        </>
                    )}




                </div>
            </div>
            <EditRole
                visible={visibleEditParent}
                closeModal={onPressAdd}
                refreshParents={refreshParents}
                parentData={{
                    parentId: parent.id,
                    name: parent.role_name,
                    email: parent.email,
                    phone: parent.phone,
                    group: parent?.group?.name,
                    role: parent?.role_type,
                }}
            />
        </>
    );
};

export default RolesRow;
