import * as React from "react";
import "../../views/Staff.scss";

import { cilPencil, cilTrash } from "@coreui/icons";
import { IconButton } from "../Atomics";
import { Parents } from "../types";
import EditParent from "./EditParent";
import usePermission from "../../hooks/usePermission";
//service

interface ParentsRowProps {
  parent: Parents;
  removeUser: (id: number) => void;
  refreshParents: () => void
}

const ParentsRow: React.FC<ParentsRowProps> = ({ parent, removeUser, refreshParents }) => {
  const { checkPermission } = usePermission()
  const [visibleEditParent, setVisibleEditParent] = React.useState(false);

  function onPressAdd() {
    setVisibleEditParent(!visibleEditParent);
  }
  return (
    <>
        <div className="d-flex py-3 user-information-container justify-content-around">
            <div className="section-width-parents-1 d-flex justify-content-start">
                <p className="text-light word-break">
                {parent.name}
                </p>
            </div>
            <div className="section-width-parents-2 d-flex justify-content-start align-items-center">
            <p className="text-light word-break">{parent.email}</p>
            </div>
            <div className="d-flex section-width-parents-3 justify-content-start align-items-center">
            <p className="text-light word-break">
                {parent.phone}
            </p>
            </div>
            <div className="section-width-parents-4 d-flex justify-content-end align-items-center">
                {checkPermission("Create and Edit Parent") && (
                    <div className="margin-icon">
                        <IconButton
                            icon={cilPencil}
                            size="lg"
                            onClick={() => onPressAdd()}
                        />
                    </div>
                )}
                {checkPermission("Delete Parent") && (
                    <div>
                        <IconButton
                            icon={cilTrash}
                            size="lg"
                            className="ms-1"
                            onClick={() => removeUser(parent.id)}
                        />
                    </div>
                )}
            </div>
        </div>
        <EditParent 
          visible={visibleEditParent}
          closeModal={onPressAdd}
          refreshParents={refreshParents}
          parentData={{
              parentId: parent.id,
              name: parent.name,
              email: parent.email,
              phone: parent.phone
            }}
          />
    </>
  );
};

export default ParentsRow;
