import * as React from "react";
import "./Staff.scss";

//components
import { Pagination } from "../components";
import { NewMessage } from "../components/messages";
import { PageContainer } from "../components/Atomics";
import CIcon from "@coreui/icons-react";
import { cilPlus } from "@coreui/icons";
import HandleUIError from "../utils/HandleUIError";
import UsersList from "../components/admins/UsersList";
import NewUser from "../components/admins/NewUser";
// services
import { searchUsers } from "../services/usersService";
import useDebounce from "../hooks/useDebounce";
import { useAppSelector } from "../redux/hooks";
import usePermission from "../hooks/usePermission";

const Admins: React.FC = () => {
  const { checkPermission } = usePermission()
  const userSlice: any = useAppSelector((state) => state.UserSlice);
  const [visibleNewUser, setVisibleNewUser] = React.useState(false);
  const [currentUsers, setCurrentUsers] = React.useState<Array<any>>([]); /// modify
  const [loading, setLoading] = React.useState<boolean>(true);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [usersPageSize] = React.useState<number>(7);
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [pageNumbers, setPageNumbers] = React.useState<number>(1);
  const [errorMessage, setErrorMessage] = React.useState<string>("");

  const debouncedSearch = useDebounce(searchValue, 300);

  function searchAdmins() {
    searchUsers(usersPageSize, 1, debouncedSearch)
      .then((usersFound) => {
        setCurrentPage(1);
        setCurrentUsers(usersFound.data);
        setPageNumbers(usersFound.numberOfTotalUsers / usersPageSize);
      })
      .catch((e) => {
        HandleUIError(e);
      });
  }

  React.useEffect(
    function () {
      if (debouncedSearch) {
        searchAdmins();
      } else {
        searchUsers(usersPageSize, 1, searchValue)
          .then((usersFound) => {
            setCurrentPage(1);
            setCurrentUsers(usersFound.data);
            setPageNumbers(usersFound.numberOfTotalUsers / usersPageSize);
          })
          .catch((e) => {
            HandleUIError(e);
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearch]
  );

  React.useEffect(() => {
    refreshUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  function onChangeSearchValue(e: any) {
    setSearchValue(e.target.value);
  }

  //this function is treggered by pagination
  async function refreshUsers() {
    setLoading(true);
    try {
      let usersFound: any = await searchUsers(
        usersPageSize,
        currentPage,
        searchValue
      );
      setCurrentUsers(usersFound.data);
      setPageNumbers(usersFound.numberOfTotalUsers / usersPageSize);
      setLoading(false);
    } catch (e) {
      HandleUIError(e);
      setLoading(false);
    }
  }

  function onPressAdd() {
    setVisibleNewUser(!visibleNewUser);
  }

  return (
    <div className="bg-main pb-5 over-flow-auto h-100">
      <PageContainer className="page-container-staff d-flex flex-column justify-content-start pb-3 mx-4">
        <div className="d-flex flex-custom-row flex-column justify-content-end staff-menu">
          <div className="d-flex justify-content-between align-items-center flex-dir-column">
            <div className="wrap mg-bot mg-lt center-text">
              <p className="text-light me-2">Search:</p>
              <input
                value={searchValue}
                onChange={onChangeSearchValue}
                className="input-search"
                type="text"
              />
            </div>
            {console.log('userSlice', userSlice)}
            <div className="d-flex align-self-end mt-3 mt-xxl-0 buttons-right wrap">
              {checkPermission("Create and Edit Admin") && (
                <div
                  className="add-icon-container mg-lt add-icon-staff"
                  onClick={onPressAdd}
                >
                  <CIcon icon={cilPlus} size="xl" className="add-icon" />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="overflowX">
          <UsersList
            users={currentUsers}
            loading={loading}
            refreshUsers={refreshUsers}
          />
        </div>
        {pageNumbers > 1 ? (
          <Pagination
            numberOfPages={pageNumbers}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        ) : (
          <></>
        )}
      </PageContainer>
      <NewUser
        visible={visibleNewUser}
        closeModal={onPressAdd}
        refreshUsers={refreshUsers}
      />
      <NewMessage />
    </div>
  );
};

export default Admins;
