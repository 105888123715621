import * as React from "react";
import { useLocation, useHistory } from "react-router-dom";
import "./AlertTypeAction.scss";

//components
import {
  PageContainer,
  GoBackButton,
  MenuHeader,
  Spinner,
} from "../components/Atomics";
import { AlertTypeActionItem } from "../components/alert-history";
import CIcon from "@coreui/icons-react";
import { cilPlus } from "@coreui/icons";
import { CSpinner } from "@coreui/react";

//services
import {
  AlertTypeResponse,
  TemplateMessageResponse,
  AlertTypeAction as Actions,
  GroupResponse,
} from "../services/types";
import {
  getMessageTemplateAudio,
  getMessageTemplateText,
  getMessageTemplateHtml,
} from "../services/messagesService";
import confirmationDialog from "../components/toast-dialogs/ConfirmationDialog";
import {
  createNewAlertAction,
  getAlertActionById,
} from "../services/alertServices";
import HandleUIError from "../utils/HandleUIError";
import { getAllGroups } from "../services/groupService";

const AlertTypeAction: React.FC = () => {
  const alertType = useLocation().state as AlertTypeResponse;
  const history = useHistory();
  const [alertTypeActions, setAlertTypeActions] = React.useState<Actions[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [messageTemplateText, setMessageTemplateText] = React.useState<
    TemplateMessageResponse[]
  >([]);
  const [messageTemplateAudio, setMessageTemplateAudio] = React.useState<
    TemplateMessageResponse[]
  >([]);
  const [messageTemplateHtml, setMessageTemplateHtml] = React.useState<
    TemplateMessageResponse[]
  >([]);
  const [loadingAddAction, setLoadingAddAction] = React.useState(false);
  const [groups, setGroups] = React.useState<GroupResponse[]>([]);

  React.useEffect(function () {
    async function getMessageTemplate() {
      try {
        const [textTemplate, audioTemplate, htmlTemplate, allGroups] =
          await Promise.all([
            getMessageTemplateText(),
            getMessageTemplateAudio(),
            getMessageTemplateHtml(),
            getAllGroups(),
          ]);
        setMessageTemplateText(textTemplate);
        setMessageTemplateAudio(audioTemplate);
        setMessageTemplateHtml(htmlTemplate);
        setGroups(allGroups);
      } catch (e) {
        HandleUIError(e);
      }
    }
    getMessageTemplate().catch((e) => console.warn(e));
    refreshActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onPressAdd() {
    confirmationDialog({
      title: "Are you sure you want to add a new action?",
      confirmAction: throwCreateNewAlertAction,
    });
  }

  async function refreshActions() {
    setIsLoading(true);
    try {
      const actionsResponse = await getAlertActionById(alertType.id);
      setAlertTypeActions(actionsResponse);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      HandleUIError(e);
    }
  }

  async function throwCreateNewAlertAction() {
    setLoadingAddAction(true);
    try {
      await createNewAlertAction(alertType.id);
      await refreshActions();
      setLoadingAddAction(false);
    } catch (e) {
      setLoadingAddAction(false);
      HandleUIError(e);
    }
  }

  function closeView() {
    history.replace("/alerts", { settings: true });
  }

  return (
    <div className="bg-main pb-3 over-flow-auto h-100">
      <GoBackButton onClick={closeView} className="ms-4 my-1" />
      <PageContainer className="alert-page-container px-2 mx-2 px-sm-4 mx-sm-4 d-flex flex-column justify-content-between pb-3 alert-page-container mt-0">
        <div className="">
          <div className="d-flex justify-content-between align-items-center">
            <p className="text-light fs-5">{alertType.name}</p>
            <div
              className="add-icon-container mg-lt add-icon-staff"
              onClick={onPressAdd}
            >
              {!loadingAddAction ? (
                <CIcon icon={cilPlus} size="xl" className="add-icon" />
              ) : (
                <Spinner className="text-light add-action-spinner" />
              )}
            </div>
          </div>
          <MenuHeader className="mt-3">
            <p className="text-color py-3 px-3">Actions </p>
          </MenuHeader>
          {!isLoading ? (
            <>
              {alertTypeActions.map((action, index) => {
                return (
                  <AlertTypeActionItem
                    action={action}
                    index={index}
                    messageTemplateText={messageTemplateText}
                    messageTemplateAudio={messageTemplateAudio}
                    messageTemplateHtml={messageTemplateHtml}
                    alertId={alertType.id}
                    refreshActions={refreshActions}
                    groups={groups}
                  />
                );
              })}
            </>
          ) : (
            <div className="d-flex justify-content-center mt-4">
              <CSpinner variant="grow" />
            </div>
          )}
          {!isLoading && alertTypeActions.length === 0 && (
            <p className="text-light fs-5 mt-2 ms-3">
              There are no registered actions.
            </p>
          )}
        </div>
      </PageContainer>
    </div>
  );
};

export default AlertTypeAction;
