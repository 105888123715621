const config = {
  apiBaseUrl:
    process.env.REACT_APP_API_BASE_URL || "https://api.core-test.lutiband.com",
  firebaseConfig: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
  },
  google: {
    googleApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
    icon_marker:
      process.env.REACT_APP_GEO_MARKER_IMAGE_URL ||
      "https://i.ibb.co/F8RLfBd/Grupo-2897.png",
    geocodingBaseUrl:
      process.env.REACT_APP_GOOGLE_GEOCODING_BASE_URL ||
      "https://maps.googleapis.com/maps/api/geocode/json?address=",
  },
};

export default config;
