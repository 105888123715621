import * as React from "react";

interface Props {
  onClick?: (e: React.FormEvent<HTMLButtonElement>) => void;
  className?: string;
  disabled?: boolean;
}

const Button: React.FC<Props> = ({
  onClick,
  className,
  children,
  disabled,
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`btn text-light btn-info custom-button d-flex justify-content-center align-items-center ${className}`}
    >
      {children}
    </button>
  );
};
export default Button;
