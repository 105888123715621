import * as React from "react";

import { MenuHeader } from "../Atomics";
import { CSpinner } from "@coreui/react";
import "./EmergencyList.scss";
import { removeEmergency } from "../../services/emergencyService";
import HandleUIError from "../../utils/HandleUIError";
import toast from "react-hot-toast";
import confirmationDialog from "../toast-dialogs/ConfirmationDialog";
import "./EmergencyList.scss";
// interfaces
import { Roles } from "../types";
import EmergencyRow from "./EmergencyRow";
import {createActivityReq} from "../../services/activityLogService";

interface Props {
  parents: any;
  loading: boolean;
  refreshParents: () => void;
}

const EmergencyList: React.FC<Props> = ({
  parents,
  loading,
  refreshParents,
}) => {
  async function removeUser(parentId: number): Promise<void> {
    try {
      await toast.promise(removeEmergency(parentId), {
        loading: "Removing Emergency Contact...",
        success: "Emergency Contact removed from organization",
        error: "Error removing Emergency Contact from organization",
      });
      await refreshParents();
      await createActivityReq('Emergency Contact Removed Successfully')
    } catch (e) {
      HandleUIError(e);
    }
  }

  function onPressRemoveUser(parentId: number) {
    const confirmationDialogOptions = {
      title: "Are you sure you want to remove this?",
      confirmAction: () => removeUser(parentId),
    };
    confirmationDialog(confirmationDialogOptions);
  }

  return (
    <>
      <MenuHeader className="d-flex pt-3 pb-3 justify-content-around">
        <p className="text-color section-width-parents-1 d-none d-sm-block">
          Name
        </p>
        <p className="text-color section-width-parents-1 d-none d-sm-block">
          Phone Number
        </p>
        <p className="text-color section-width-parents-1 d-none d-sm-block">
          Date & Time
        </p>
        <p className="text-color section-width-parents-4 d-none d-sm-block"></p>
      </MenuHeader>
      {!loading ? (
        parents?.map((element: Roles, index: number) => {
          return (
            <>
              <EmergencyRow
                parent={element}
                key={index}
                removeUser={onPressRemoveUser}
                refreshParents={refreshParents}
              />
            </>
          );
        })
      ) : (
        <div className="spinner">
          <CSpinner variant="grow" />
        </div>
      )}
    </>
  );
};

export default React.memo(EmergencyList);
