import axiosConfig from "./axiosConfig";
import { AxiosError } from "axios";
import ApiError from "../utils/ApiError";
import {
  AlertTypeResponse,
  AlertHistoryResponse,
  AlertResponse,
} from "./types";
import moment from "moment";

interface PaginationResponse {
  data: Array<AlertTypeResponse>;
  numberOfTotalDevices: number;
}

export async function getAlerts(): Promise<PaginationResponse> {
  try {
    const response = await axiosConfig.get("/admin/alerts-types");
    const data = response.data.data as Array<AlertTypeResponse>;
    return { data, numberOfTotalDevices: response.data.meta.total };
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function sendQuickAlert(body: any): Promise<PaginationResponse> {
  try {
    const response = await axiosConfig.post("/admin/send-quick-alert", body);
    const data: any = response.data.data as Array<any>;
    return data;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function saveSchoolDetail(body: any): Promise<PaginationResponse> {
  try {
    const response = await axiosConfig.post("/admin/organization-details", body);
    const data: any = response.data.data as Array<any>;
    return data;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function getSchoolDetails(): Promise<PaginationResponse> {
  try {
    const response = await axiosConfig.get("/admin/organization-details");
    const data: any = response.data.data;
    return data;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function getAlertCategory(): Promise<PaginationResponse> {
  try {
    const response = await axiosConfig.get("/admin/alert-categories");
    const data = response.data.data as Array<AlertTypeResponse>;
    return { data, numberOfTotalDevices: response.data.meta.total };
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function createNewAlertType(
  name: string,
  color: string,
  icon: any,
  category: any,
  isDefault: boolean,
  isHomeAlert: boolean,
): Promise<void> {
  const form = new FormData();
    form.append("name", name);
    form.append("color", color);
    if(icon){
      form.append("icon", icon);
    }
    if(category){
      form.append("alert_category_id", category);
    }
    form.append("isDefault", isDefault.toString());
    form.append("is_home_alert", isHomeAlert.toString());
  //   name, color, alert_category_id: category, isDefault
  try {
    await axiosConfig.post("/admin/alert-types-post", {name, color, icon, alert_category_id: category, isDefault, isHomeAlert: isHomeAlert}, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });
  } catch (e: any) {
    const err = e as AxiosError | Error;
    // if we throw thru "ApiError" we lost error properties like original api message property
    // throw new ApiError(err);
    throw err;
  }
}

export async function createNewAlertCategory(
    name: string,
    category: false | any,
): Promise<void> {
  try {
    await axiosConfig.post("/admin/alert-categories", {name, alert_type_ids: category}, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    });
  } catch (e: any) {
    const err = e as AxiosError | Error;
    // if we throw thru "ApiError" we lost error properties like original api message property
    // throw new ApiError(err);
    throw err;
  }
}

export async function deleteAlertType(alertTypeId: string): Promise<void> {
  try {
    await axiosConfig.delete(`/admin/alerts-types/${alertTypeId}`);
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function deleteAlertCategory(alertTypeId: string): Promise<void> {
  try {
    await axiosConfig.delete(`/admin/alert-categories/${alertTypeId}`);
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function updateAlertType(name: string, color: string, icon: any, category: any, id: string): Promise<void> {
  try {
    await axiosConfig.patch(`/admin/alerts-types/${id}`, { name, color, icon, alert_category_id: category });
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function updateAlertCategory(name: string, category: any, id: string): Promise<void> {
  try {
    await axiosConfig.put(`/admin/alert-categories/${id}`, { name, alert_type_ids: category });
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function throwAlertById(
  alertId: number,
  userId: number
): Promise<void> {
  try {
    await axiosConfig.post(`/admin/alerts-types/throw-alert/new`, {
      alertId,
      userId,
    });
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function setAlertDefaultById(alertId: number) {
  try {
    await axiosConfig.patch(`/admin/alerts-types/${alertId}`, {
      isDefault: true,
    });
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function setHomeAlert(alertId: number, checked: any) {
  try {
    await axiosConfig.put(`/admin/alerts-types/${alertId}`, {
      isHomeAlert: checked,
    });
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

  export async function setAutoAlert(alertId: number, checked: any) {
  try {
    await axiosConfig.put(`/admin/alerts-types-auto-send/${alertId}`, {
      autoSend: checked,
    });
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function createNewAlertAction(alertId: number) {
  try {
    const body = {
      actions: [
        {
          sendStaff: "all",
          sendToStaffGroupId: null,
          sendToParents: false,
        },
      ],
    };
    await axiosConfig.patch(`/admin/alerts-types/${alertId}`, body);
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function getAlertActionById(alertId: number) {
  try {
    const alerts = await getAlerts();
    const currentAlert = alerts.data.find((alert) => alert.id === alertId);
    if (!currentAlert) {
      throw Error("Alert not found");
    }
    return currentAlert.actions;
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function editAlertActionById(
  alertId: number,
  alertActionId: number,
  state: any
) {
  try {
    const body = {
      actions: [
        {
          id: alertActionId,
          ...state,
        },
      ],
    };
    await axiosConfig.patch(`/admin/alerts-types/${alertId}`, body);
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function deleteAlertAction(alertActionId: number): Promise<void> {
  try {
    await axiosConfig.delete(`/admin/alerts-types/actions/${alertActionId}`);
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

//allow query with all filters combined
export async function getHistoryOfAlerts(
  page: number,
  limit: number,
  alertTypeId: string,
  date_from: string | undefined,
  date_to: string | undefined
): Promise<{ total: number; data: AlertHistoryResponse[] }> {
  const today = moment().format("L");
  try {
    const pagination = `page=${page}&limit=${limit}`;
    const alertType =
      alertTypeId === "all" ? "" : `&filter[alertTypeId][eq]=${alertTypeId}`;
    const dateFrom =
      today !== moment(date_from).format("L")
        ? `&filter[created_at][gt]=${date_from}`
        : "";
    const dateTo =
      today !== moment(date_to).format("L")
        ? `&filter[created_at][lt]=${date_to}`
        : "";
    const response = await axiosConfig.get(
      `/admin/alerts?${pagination}&${alertType}&${dateFrom}&${dateTo}`
    );
    return { total: response.data.meta.total, data: response.data.data };
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function sendFcmToken(token: string): Promise<AlertResponse[]> {
  try {
    // const AlertTypeResponse = await axiosConfig.post(
    //     "/admin/update-fcm-token", {fcm_token : token}
    // );
    return [];
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function getAlertsInProgress(): Promise<AlertResponse[]> {
  try {
    const AlertTypeResponse = await axiosConfig.get(
      "/admin/alerts/in-progress"
    );
    return AlertTypeResponse.data.data as AlertResponse[];
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function getShootAlertsInProgress(): Promise<AlertResponse[]> {
  try {
    const AlertTypeResponse = await axiosConfig.get(
        "/admin/high-alerts"
    );
    return AlertTypeResponse.data.data as AlertResponse[];
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function getForwardShootAlerts(): Promise<AlertResponse[]> {
  try {
    const AlertTypeResponse = await axiosConfig.get(
        "/admin/forward-high-alerts"
    );
    return AlertTypeResponse.data.data as AlertResponse[];
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function getForwardOtherAlerts(): Promise<AlertResponse[]> {
  try {
    const AlertTypeResponse = await axiosConfig.get(
        "/admin/forward-other-alerts"
    );
    return AlertTypeResponse.data.data as AlertResponse[];
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function getNotifications(): Promise<AlertResponse[]> {
  try {
    const AlertTypeResponse = await axiosConfig.get(
        "/admin/admin-notifications"
    );
    return AlertTypeResponse.data.data as AlertResponse[];
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function sendReadNotification(id: number): Promise<AlertResponse[]> {
  try {
    const AlertTypeResponse = await axiosConfig.get(
        `/admin/read-admin-notification/${id}`
    );
    return AlertTypeResponse.data.data as AlertResponse[];
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function deleteAllNotifications(): Promise<AlertResponse[]> {
  try {
    const AlertTypeResponse = await axiosConfig.get(
        `/admin/remove-all-notification`
    );
    return AlertTypeResponse.data.data as AlertResponse[];
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function closeShootAlertInProgress(alertId: string): Promise<void> {
  try {
    await axiosConfig.delete(`/admin/high-alert/${alertId}`);
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function closeForwardShootAlertInProgress(alertId: string): Promise<void> {
  try {
    await axiosConfig.delete(`/admin/forward-high-alert/${alertId}`);
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function approvedShootAlertInProgress(alertId: string): Promise<void> {
  try {
    await axiosConfig.put(`/admin/high-alert/${alertId}`);
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function forwardShootAlertInProgress(data: any): Promise<void> {
  try {
    return await axiosConfig.patch(`/admin/high-alert/${data?.id}`, {data: data?.body});
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function forwardInProgress(data: any): Promise<void> {
  try {
    return await axiosConfig.patch(`/admin/other-alert/${data?.id}`, {data: data?.body});
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function approvedInProgress(alertId: string): Promise<void> {
  try {
    return await axiosConfig.put(`/admin/other-alert/${alertId}`);
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}

export async function closeAlertInProgress(alertId: string): Promise<void> {
  try {
    await axiosConfig.post(`/admin/alerts/${alertId}/finish`);
  } catch (e) {
    const err = e as AxiosError | Error;
    throw new ApiError(err);
  }
}
