import * as React from "react";

function useDebounce(value: any, delay: number){
    const [debouncedValue, setDebouncedValue] = React.useState(value)

    React.useEffect( () => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay)

        return () => {
            clearTimeout(handler);
        }
    }, [value, delay])

    return debouncedValue;
}

export default useDebounce