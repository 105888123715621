import React, {useEffect, useState} from 'react';
import PageContainer from "../components/Atomics/PageContainer";
import {CFormCheck} from "@coreui/react";
import {Button, Spinner} from "../components/Atomics";
import {savePermission} from "../services/rolesService";
import HandleUIError from "../utils/HandleUIError";
import {useHistory, useLocation, useParams} from "react-router-dom";
import toast from "react-hot-toast";

const permissionsState = [
    {
        'title': 'Dashboard',
        'permissions': ['View Quick Alert', 'Send Quick Alert', 'Send Group Message', 'Save School Details']
    },
    {
        'title': 'Facility',
        'permissions': ['View Facility', 'Create and Edit Facility', 'Delete Facility', 'Hide and Un-Hide Facility']
    },
    {
        'title': 'Staff',
        'permissions': ['View Staff', 'View Group', 'Create and Edit Staff', 'Delete Staff', 'Create and Edit Group', 'Delete Group']
    },
    {
        'title': 'Message',
        'permissions': ['View Message', 'Create and Edit Message', 'Delete Message']
    },
    {
        'title': 'Alerts',
        'permissions': ['View History', 'View Settings', 'View Categories', 'Create and Edit Setting', 'Delete Setting', 'Create and Edit Category', 'Delete Category']
    },
    {
        'title': 'Live Alerts',
        'permissions': ['View Live Alerts', 'Approved Alerts', 'Forward Alerts', 'Cancel Alert']
    },
    {
        'title': 'Parent',
        'permissions': ['View Parents', 'Create and Edit Parent', 'Delete Parent']
    },
    {
        'title': 'Role',
        'permissions': ['View Roles', 'Create and Edit Role', 'Delete Role', 'Import CSV', 'View Role Users', 'Delete Role User']
    },
    {
        'title': 'Admin',
        'permissions': ['View Admin', 'Create and Edit Admin', 'Delete Admin']
    },
    {
        'title': 'App Users',
        'permissions': ['View App Users', 'View App User Location, Approval and Status']
    },
    {
        'title': 'Alarm Alerts',
        'permissions': ['View Alarm Alerts', 'Edit Alarm Alert']
    },
    {
        'title': 'Group Alerts',
        'permissions': ['View Group Alerts']
    },
    {
        'title': 'Emergency Contact',
        'permissions': ['View Emergency Contact', 'Create and Edit Emergency Contact', 'Delete Emergency Contact']
    },
    {
        'title': 'Emergency Alerts',
        'permissions': ['View Emergency Alert']
    },


]

const Permissions = () => {
    const params: any = useParams()
    const history = useHistory()
    const location = useLocation();
    let data: any = location.state;

    const [permissionsBody, setPermissionsBody] = React.useState<any>([])
    const [isLoading, setIsLoading] = useState(false)

    const onChange = (e: any, name: any) => {
        if(!e.target.checked && permissionsBody.includes(name)) {
            setPermissionsBody((pre: any) => pre.filter((i: any) => i !== name))
        } else {
            setPermissionsBody((pre: any) => [...pre, name])
        }
    }

    const onSave = async (id: number, permissions: any) => {
        if(permissions.length == 0) {
            toast.error("Please select at least one permission")
            return
        }
        setIsLoading(true)
        try {
            const response: any = await savePermission(id, permissions);
            console.log('response==', response);
            if (response?.status == 200) {
                toast.success("Parent added successfully");
                history.push('/roles');
            }
        } catch (e) {
            HandleUIError(e);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if(data?.state) {
            setPermissionsBody(data?.state)
        }
    }, [data.state])

    console.log('permissionsBody', permissionsBody, data)

    return (
        <>
            <div className="bg-main pb-5 over-flow-auto h-100">
                <PageContainer className="page-container-staff d-flex flex-column justify-content-start pb-3 mx-4">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-auto">
                            <h2 className="text-white mb-0">Roles Permissions</h2>
                        </div>
                        <div className="col-auto">
                            <Button
                                // disabled={addParentLoading}
                                className="mt-7 button-add px-4 mb-3"
                                onClick={() => onSave(params?.id, permissionsBody)}
                            >
                                {isLoading ? <Spinner /> : 'Save'}
                            </Button>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-auto">
                            <a
                                className="text-white mb-0"
                                role="button"
                                onClick={() => {
                                    setIsLoading(true)
                                    const permission = permissionsState?.map((i: any) => (i?.permissions)).flat()
                                    data['state'] = permission
                                    setPermissionsBody(permission)
                                    setTimeout(() => {
                                        setIsLoading(false)
                                    }, 1000)
                                }}
                            >
                                Select All
                            </a>
                        </div>
                        <div className="col-auto">
                            <a
                                className="text-white mb-0"
                                role="button"
                                onClick={() => {
                                    setIsLoading(true)
                                    data['state'] = null
                                    setPermissionsBody([])
                                    setTimeout(() => {
                                        setIsLoading(false)
                                    }, 1000)
                                }}
                            >
                                Deselect All
                            </a>
                        </div>
                    </div>


                    <div className="row mt-4 g-3 green-check">
                        {!isLoading && permissionsState?.map((i: any) => (
                            <>
                                <div className="col-lg-12 mb-3">
                                    <h3 className="text-white mb-0">{i.title}</h3>
                                </div>
                                {i?.permissions?.map((j: any) => (
                                    <div className="col-lg-3">
                                        <CFormCheck
                                            className="text-white-50 text-capitalize"
                                            id={`check=${j}`}
                                            label={j}
                                            defaultChecked={data?.state?.includes(j)}
                                            onChange={(e) => onChange(e, j)}
                                        />
                                    </div>
                                ))}
                            </>
                        ))}
                    </div>
                </PageContainer>
            </div>
        </>
    );
};

export default Permissions;