import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MessageState,} from "../../redux/types";

const initialState: any = {
 zonesToDisplay: [],
};

export const zoneSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    saveZoneToDisplay: (state, action: PayloadAction<any>) => {
      return {...state, zonesToDisplay: action.payload}
    },
    default: (state) => {
      return state;
    },
  },
});

export const { saveZoneToDisplay, } = zoneSlice.actions;
export default zoneSlice.reducer;
