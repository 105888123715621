import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MessageState,} from "../../redux/types";

const initialState: MessageState = {
 endpointService: "" ,
 headerText: "",
 visible: false,
 deviceId: null,
};

export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    saveMessage: (state, action: PayloadAction<MessageState>) => {
      return action.payload;
    },
    default: (state) => {
      return state;
    },
  },
});

export const { saveMessage, } =
messageSlice.actions;
export default messageSlice.reducer;
