import * as React from "react";
import "../../views/Staff.scss";
import { LocationMapModal } from ".";
//redux
import { MessageState } from "../../redux/types";
import { store } from "../../redux/store";
import { saveMessage } from "../../redux/reducers/messageReducer";

import CIcon from "@coreui/icons-react";
import {
  cilLocationPin,
  cilCommentBubble,
  cilTrash,
  cilWatch,
} from "@coreui/icons";
import { CAvatar, CFormSelect } from "@coreui/react";
import {Spinner, IconButton, Button} from "../Atomics";
import { LowBatteryIcon } from "../Atomics/LowBatteryIcon";
import { User } from "../../components/types";
import moment from "moment";
import AssociateDevice from "../admins/AssociateDevice";

//service
import { getFullDeviceInfo } from "../../services/devicesService";
import HandleUIError from "../../utils/HandleUIError";
import { convertUTCtoLocale } from "../../utils/formatConverters";
import confirmationDialog from "../toast-dialogs/ConfirmationDialog";
import { removeUserForOrganization } from "../../services/staffService";
import toast from "react-hot-toast";
import usePermission from "../../hooks/usePermission";

interface DeviceRowProps {
  user: User;
  refreshUsers: () => void;
  setPreviousAction: (value: boolean) => void;
  previousAction: boolean;
  roleType: string;
  handleView: (e: any) => void;
}

const DeviceRow: React.FC<DeviceRowProps> = ({
  user,
  refreshUsers,
  setPreviousAction,
  previousAction,
  handleView,
  roleType,
}) => {
  const { checkPermission } = usePermission()
  const [showDeviceLocation, setShowDeviceLocation] = React.useState(false);
  const [deviceLastCoordinates, setDeviceLastCoordinates] = React.useState<{
    lng: number;
    lat: number;
  }>();
  const [loadingCoordinates, setLoadingCoordinates] = React.useState(false);
  const [deviceSelected, setDeviceSelected] = React.useState<number>(0);
  const [visibleAssociateDevice, setVisibleAssociateDevice] =
    React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(
    function () {
      getDeviceCoordinates();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, deviceSelected]
  );

  async function removeUser(userId: number): Promise<void> {
    try {
      await toast.promise(removeUserForOrganization(userId), {
        loading: "Removing user...",
        success: "User removed from organization",
        error: "Error removing user from organization",
      });
      setIsLoading(false);
      setPreviousAction(false);
      await refreshUsers();
    } catch (e) {
      HandleUIError(e);
    }
  }

  function onPressRemoveUser(userId: number) {
    setPreviousAction(true);
    setIsLoading(true);
    const confirmationDialogOptions = {
      title:
        "Are you sure you want to remove this user from the organization?",
      confirmAction: () => removeUser(userId),
      cancelAction: () => {
        setIsLoading(false)
        setPreviousAction(false)
      },
    };
    confirmationDialog(confirmationDialogOptions);
    setTimeout(() =>{
      setIsLoading(false);
      setPreviousAction(false);
    }, 4000)
  }

  function onPressAssociate() {
    setVisibleAssociateDevice(!visibleAssociateDevice);
  }

  async function getDeviceCoordinates() {
    setLoadingCoordinates(true);
    try {
      if (user.devices.length > 0) {
        const deviceInfo = await getFullDeviceInfo(
          user.devices[deviceSelected].id
        );
        const lastCoordinates = deviceInfo.lastHistory
          ? {
              lat: deviceInfo.lastHistory.latitude,
              lng: deviceInfo.lastHistory.longitude,
            }
          : undefined;
        setDeviceLastCoordinates(lastCoordinates);
        setLoadingCoordinates(false);
      } else {
        setLoadingCoordinates(false);
      }
    } catch (e) {
      setLoadingCoordinates(false);
      HandleUIError(e);
    }
  }

  function deviceIsOnline(deviceHour: string) {
    let hour = moment();
    let hourDevice = moment(convertUTCtoLocale(deviceHour));
    if (hour.diff(hourDevice) <= 300000) {
      return true;
    } else {
      return false;
    }
  }

  function closeModal() {
    setShowDeviceLocation(false);
  }
  function openModal() {
    setShowDeviceLocation(true);
  }
  function onChangeDevice(device: any) {
    setDeviceSelected(device);
  }

  function onPushMessageBubble(deviceId: number, ownerName: string) {
    const messageData: MessageState = {
      endpointService: "device",
      headerText: `New message to ${ownerName}`,
      visible: true,
      deviceId,
    };
    store.dispatch(saveMessage(messageData));
  }

  return (
    <>
      <div className="d-flex py-3 user-information-container table-size">
        <div className="section-width-1 d-flex justify-content-center">
          {user.avatarFile === null && (
            <>
              {user.last_login ? (
                <CAvatar
                  color="primary"
                  textColor="white"
                  size="lg"
                  status={
                    deviceIsOnline(user.last_login)
                      ? "success"
                      : "danger"
                  }
                >
                  {user?.firstName ? user.firstName[0].toUpperCase() : "-"}
                  {user?.lastName ? user.lastName[0].toUpperCase() : "-"}
                </CAvatar>
              ) : (
                <CAvatar
                  color="primary"
                  textColor="white"
                  size="lg"
                  status="danger"
                >
                  {user?.firstName ? user.firstName[0].toUpperCase() : "-"}
                  {user?.lastName ? user.lastName[0].toUpperCase() : "-"}
                </CAvatar>
              )}
            </>
          )}
          {user?.avatarFile !== null && (
            <>
              {user?.last_login ? (
                <CAvatar
                  src={user.avatarFile}
                  size="lg"
                  status={
                    deviceIsOnline(user.last_login)
                      ? "success"
                      : "danger"
                  }
                />

              ) : (
                <CAvatar src={user?.avatarFile} size="lg" status="danger" />
              )}
            </>
          )}
        </div>
        {user !== null ? (
          <div className="section-width-2">
            <p className="text-light word-break small">
              {user?.firstName !== null ? user.firstName + " " : ""}
              {user?.lastName !== null ? user.lastName : ""}
              &nbsp;
              {user?.id ? "|" : ""} {user?.id}
            </p>
            <p className="text-secondary word-break small">
              {user?.email}
              {/* TODO: temporalmente se muestra el id del device, para que sea mas sencilla la presentacion, pero luego se volvera a poner el owner's phone en esta linea  */}
            </p>
          </div>
        ) : (
          <div className="section-width-2">
            <p className="text-light">No owner</p>
          </div>
        )}
        <div className="d-flex section-width-3 justify-content-start align-items-center">
          <p className="text-light text-capitalize">
            {user?.student_id || '-'}
          </p>

        </div>
        <div className="d-flex section-width-3 justify-content-start align-items-center">
          {user.devices.length > 0 ? (
            <CFormSelect
              value={deviceSelected}
              onChange={(e) => onChangeDevice(e.target.value)}
              className="template-select select-custom-width"
            >
              {user?.devices?.map(function (device, idx) {
                return (
                  <option value={idx} key={idx}>
                    {device?.name}
                  </option>
                );
              })}
            </CFormSelect>
          ) : (
            <>
              <p className="text-light text-capitalize">
                {user?.device_type || '-'}
              </p>
            </>
          )}
        </div>
        <div className="d-flex section-width-4 justify-content-start align-items-center">
          {user?.devices?.length > 0 ? (
            <>
              <LowBatteryIcon
                batteryLevel={user?.devices[deviceSelected].battery}
                className={"staff-icon ms-2"}
              />
              {user?.devices[deviceSelected].battery === null ? (
                <>
                  <p className="text-secondary label-staff word-break d-block d-sm-none">
                    Battery:
                  </p>
                  <p className="text-light">-</p>
                </>
              ) : (
                <p className="text-color fs-5 ms-1">
                  {user?.devices[deviceSelected].battery}%
                </p>
              )}
            </>
          ) : (
            <>
              <p className="text-secondary label-staff word-break d-block d-sm-none">
                Battery:
              </p>
              <p className="text-light">
                {user?.mobile_battery}{'%'}
              </p>
            </>
          )}
        </div>
        <div className="section-width-5 d-flex align-items-center">
          <p className="text-light word-break">
            {user?.devices.length > 0 && user.devices[deviceSelected].updatedAt
              ? moment(
                  convertUTCtoLocale(user.devices[deviceSelected].updatedAt)
                ).format("L") +
                " " +
                moment(
                  convertUTCtoLocale(user.devices[deviceSelected].updatedAt)
                ).format("LTS")
              : <>
                  {user?.last_login ? moment(user?.last_login).fromNow() : '-'}
                </>}
          </p>
        </div>
        <div className="section-width-6 d-flex align-items-center">
          {roleType == "web" ? <>
            <span className="badge bg-success">
                  Registered
                </span>
          </>: <>
          {user.is_complete_register ? (
              <span className="badge bg-success">
                  Registered
                </span>
          ) : (
              <span className="badge bg-danger">
                  Not Register
                </span>
          )}
          </>}
        </div>
        <div className="section-width-6 d-flex align-items-center">
          {user?.devices.length > 0 ? (
            <>
              <p className="text-secondary label-staff word-break d-block d-sm-none">
                Device IMEI:
              </p>
              <p className="text-light word-break">
                {user?.devices[deviceSelected]?.imei}
              </p>
            </>
          ) : (
            <>
              <p className="text-secondary label-staff word-break d-block d-sm-none">
                Device IMEI:
              </p>
              <p className="text-light word-break">-</p>
            </>
          )}
        </div>

        <div className="section-width-7 staff-icon d-flex align-items-center justify-content-end">
          <button
              className="btn btn-sm btn-primary"
              onClick={() => handleView(user)}
          >
            View
          </button>
          {loadingCoordinates ? (
            <div className="margin-icon">
              <Spinner />
            </div>
          ) : (
            <>
              {deviceLastCoordinates && (
                <div className="margin-icon-staff">
                  <CIcon
                    onClick={openModal}
                    icon={cilLocationPin}
                    className="staff-icon-animated"
                    size="lg"
                  />
                </div>
              )}
            </>
          )}
          {user?.devices?.length > 0 && user.devices[deviceSelected].updatedAt ? (
            <div>
              {deviceIsOnline(user?.devices[deviceSelected].updatedAt) ? (
                <div>
                  {user?.firstName && user?.lastName ? (
                    <IconButton
                      icon={cilCommentBubble}
                      size="lg"
                      onClick={() =>
                        onPushMessageBubble(
                          user.devices[deviceSelected].id,
                          user.firstName + " " + user.lastName
                        )
                      }
                    />
                  ) : (
                    <IconButton
                      icon={cilCommentBubble}
                      size="lg"
                      onClick={() =>
                        onPushMessageBubble(user.devices[deviceSelected].id, "")
                      }
                    />
                  )}
                  <Button className="btn btn-primary">
                    View
                  </Button>
                  <IconButton
                    icon={cilWatch}
                    size="lg"
                    className="ms-1"
                    onClick={onPressAssociate}
                  />
                  {checkPermission("Delete Role User") && (
                      <>
                        {previousAction ? (
                            <IconButton
                                icon={cilTrash}
                                size="lg"
                                className="ms-2"
                                isLoading={isLoading}
                                onClick={() => toast.error("Please confirm the previous action")}
                            />
                        ) : (
                            <IconButton
                                icon={cilTrash}
                                size="lg"
                                className="ms-2"
                                isLoading={isLoading}
                                onClick={() => onPressRemoveUser(user?.id)}
                            />
                        )}
                      </>
                  )}

                </div>
              ) : (
                <div>
                  <IconButton
                    icon={cilWatch}
                    size="lg"
                    className="ms-1"
                    onClick={onPressAssociate}
                  />
                  {checkPermission("Delete Role User") && (
                      <>
                        {previousAction ? (
                            <IconButton
                                icon={cilTrash}
                                size="lg"
                                className="ms-2"
                                isLoading={isLoading}
                                onClick={() => toast.error("Please confirm the previous action")}
                            />
                        ) : (
                            <IconButton
                                icon={cilTrash}
                                size="lg"
                                className="ms-2"
                                isLoading={isLoading}
                                onClick={() => onPressRemoveUser(user?.id)}
                            />
                        )}
                      </>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div>
              <IconButton
              icon={cilWatch}
              size="lg"
              className="ms-1 mr-1"
              onClick={onPressAssociate}
              />
              {checkPermission("Delete Role User") && (
                  <>
                    {previousAction ? (
                        <IconButton
                            icon={cilTrash}
                            size="lg"
                            className="ms-2"
                            isLoading={isLoading}
                            onClick={() => toast.error("Please confirm the previous action")}
                        />
                    ) : (
                        <IconButton
                            icon={cilTrash}
                            size="lg"
                            className="ms-2"
                            isLoading={isLoading}
                            onClick={() => onPressRemoveUser(user?.id)}
                        />
                    )}
                  </>
              )}

            </div>
          )}
          
        </div>
      </div>
      {user.firstName && user.lastName ? (
        <LocationMapModal
          deviceName={`${user.firstName} ${user.lastName}`}
          visible={showDeviceLocation}
          closeModal={closeModal}
          coordinates={deviceLastCoordinates}
        />
      ) : (
        <LocationMapModal
          deviceName={`-`}
          visible={showDeviceLocation}
          closeModal={closeModal}
          coordinates={deviceLastCoordinates}
        />
      )}
      <AssociateDevice
        visible={visibleAssociateDevice}
        closeModal={onPressAssociate}
        refreshUsers={refreshUsers}
        userId={user.id}
        userPhone={user.phone}
      />
    </>
  );
};

export default DeviceRow;
