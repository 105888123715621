import * as React from "react";
import "./Messages.scss";
import { PreStoredMessages, Recipients } from "../components/messages";
import toast from "react-hot-toast";

//components
import { NewMessage } from "../components/messages";

//services
import { Device } from "../components/types";
import { useAppDispatch } from "../redux/hooks";
import { saveMessage } from "../redux/reducers/messageReducer";
import { MessageState } from "../redux/types";
import { getOneGroup } from "../services/groupService";
import HandleUIError from "../utils/HandleUIError";
import {createActivityReq} from "../services/activityLogService";

const Messages: React.FC = () => {
  const [selectedGroup, setSelectedGroup] = React.useState<number[]>([]);
  const dispatch = useAppDispatch();

  async function getDevicesIdsOfAGroup() {
    try {
      const groupsQuery = selectedGroup.map((group) => getOneGroup(group));
      const groups = await Promise.all(groupsQuery);
      const staffs = groups.flatMap((group) => group.users);

      let devices: Device[] = [];
      staffs.forEach((staff) => {
        devices = [...devices, ...staff.devices];
      });
      console.log('staffs', devices)
      const devicesIds = devices.map((device) => device.id);
      return devicesIds;
    } catch (e) {
      HandleUIError(e);
    }
  }

  function onSelectGroup(groupId: number[]) {
    setSelectedGroup(groupId);
  }

  async function onPressSend(devicesIds: number[]): Promise<void> {
    const groupsDevicesIds = await getDevicesIdsOfAGroup();
    groupsDevicesIds && devicesIds.push(...groupsDevicesIds);
    if (devicesIds.length > 0) {
      const messageData: MessageState = {
        endpointService: "arrayDevices",
        headerText: "New Message",
        visible: true,
        deviceId: devicesIds,
      };
      dispatch(saveMessage(messageData));
      await createActivityReq('Recipients Added Successfully')
    } else {
      toast.error("Please select at least one recipient");
    }
  }

  console.log('selectedGroup', selectedGroup)

  return (
    <>
      <div className="bg-main p-3 pb-5 h-100 " style={{ overflowY: 'auto'}}>
        <div className="row g-3 mt-4 row-cols-lg-2">
          <PreStoredMessages />
          {/* <Recipients
            onPressSend={onPressSend}
            onSelectedGroup={onSelectGroup}
          /> */}
        </div>
      </div>
      <NewMessage />
    </>
  );
};

export default Messages;
