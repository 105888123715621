import * as React from "react";
import toast from "react-hot-toast";
import "./ToastDialogs.scss";

//components
import { Button } from "../Atomics";

interface ConfirmationDialogThrowerProps {
  confirmAction: () => void;
  cancelAction?: () => void;
  title: string;
  confirmText?: String;
  cancelText?: String;
}

interface ConfirmationDialogProps extends ConfirmationDialogThrowerProps {
  toastId: string;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  confirmAction,
  cancelAction,
  title,
  toastId,
  confirmText,
  cancelText,
}) => {
  function onPressConfirm() {
    confirmAction();
    toast.dismiss(toastId);
  }
  function onPressCancel() {
    cancelAction ? defaultCancel() : toast.dismiss(toastId);
  }

  function defaultCancel() {
    cancelAction && cancelAction();
    toast.dismiss(toastId);
  }

  return (
    <div className="toast-dialog-container">
      <p>{title}</p>
      <div className="buttons-container">
        <Button onClick={onPressConfirm} className="button-dialog">
          {confirmText ? confirmText : "Yes"}
        </Button>
        <Button onClick={onPressCancel} className="button-dialog">
          {cancelText ? cancelText : "No"}
        </Button>
      </div>
    </div>
  );
};

export default function confirmationDialog({
  title,
  confirmAction,
  cancelAction,
  cancelText,
  confirmText,
}: ConfirmationDialogThrowerProps) {
  toast(
    (t) => {
      return (
        <ConfirmationDialog
          title={title}
          toastId={t.id}
          confirmAction={confirmAction}
          cancelAction={cancelAction}
          cancelText={cancelText}
          confirmText={confirmText}
        />
      );
    },
    { className: "toast-dialog-position", position: "top-center", id: '214' }
  );
}
