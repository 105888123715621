import * as React from "react";
import SaveSchoolDetail from "./SaveSchoolDetail";
import { useEffect, useState } from "react";
import { getSchoolDetails } from "../../services/alertServices";
import usePermission from "../../hooks/usePermission";

const SchoolDetails: React.FC = () => {
    const { checkPermission } = usePermission()
    const [data, setData] = useState<any>([])
    const [isEdit, setIsEdit] = useState<any>(null)


    const getDetails = () => {
        getSchoolDetails()
            .then((res) => setData(res))
            .catch((e) => console.warn(e));
    }

    useEffect(() => {
        getDetails()
    }, [])

    console.log('esd', data)
    return (
        <>
            <div className="new-staff-container w-100 mt-2 battery-status-container">
                <div className="new-template-header d-flex justify-content-between align-items-center">
                    <p className="text-light py-2 ps-4 fs-5">School Details</p>
                    {checkPermission('Save School Details') && (
                        <button
                            className="btn btn-success text-white"
                            onClick={() => setIsEdit(data)}
                        >
                            Edit
                        </button>
                    )}
                </div>
                <div className="ps-4 pe-5">
                    {data?.map((item: any) => (
                        <React.Fragment>
                            <p className="m-0 p-0 m-3 text-capitalize" style={{ fontSize: 18, fontWeight: '500', wordBreak: "break-all" }}>
                                {item.heading}
                            </p>

                            <p className="m-0 p-0 m-3 text-capitalize" style={{ fontSize: 15, fontWeight: '400', wordBreak: "break-all" }}>
                                {item.description}
                            </p>
                        </React.Fragment>
                    ))}
                    {/*<p className="m-0 p-0 m-3">
                      North Cobb Christian School <br /> 4500 Eagle Drive, Kennesaw, GA
                      30144 <br /> Tel. (770) 975-0252
                  </p>
                  <p className="m-0 p-0 m-3">
                      Director of Security, Jeff Ezell <br /> Tel. (727) 599-6821
                  </p>
                  <p className="m-0 p-0 m-3">
                      Head of School, Todd Clingham <br /> Mobile: (214) 868-8336
                  </p>*/}
                </div>
            </div>


            <SaveSchoolDetail
                visible={Boolean(isEdit)}
                closeModal={() => setIsEdit(null)}
                isEdit={isEdit}
                refetch={() => getDetails()}
            />
        </>
    );
};

export default SchoolDetails;
