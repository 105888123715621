import * as React from "react";
import "../staff/NewStaff.scss";
import "./NewParent.scss"
import HandleUIError from "../../utils/HandleUIError";
import toast from "react-hot-toast";

//components
import {
  Modal,
  Input,
  Button,
  Spinner,
  IconButton,
} from "../Atomics";
import { cilX } from "@coreui/icons";
import { editContactFromParents } from "../../services/parentsService";
import {createActivityReq} from "../../services/activityLogService";

interface DataParents {
    parentId: number;
    name: string;
    email: string;
    phone: string;
}

interface Props {
  visible: boolean;
  closeModal: () => void;
  refreshParents: () => void;
  parentData: DataParents
}

const EditParent: React.FC<Props> = ({ visible, closeModal, refreshParents, parentData}) => {
  const [name, setName] = React.useState<string>(parentData.name);
  const [email, setEmail] = React.useState<string>(parentData.email);
  const [phone, setPhone] = React.useState<string>(parentData.phone);
  const [addParentLoading, setAddParentLoading] = React.useState<boolean>(false);

  const handleInputName = (e: any) => {
    setName(e.target.value);
  };
  
  const handleInputMail = (e: any) => {
    setEmail(e.target.value);
  };
  
  const handleInputPhone = (e: any) => {
    setPhone(e.target.value);
  };
  
  async function changeParent(parentId: number, name: string, email: string, phone: string): Promise<void> {
    setAddParentLoading(true);
      try {
        if(name === "" || name === null){
          toast.error("Name cannot be empty")
          setAddParentLoading(false);
        }else{
          await editContactFromParents(parentId, name, email, phone)
          await refreshParents();
          toast.success("Parent modified");
          setAddParentLoading(false);
          onCloseModal();
          await createActivityReq('Contact Successfully Updated in the Community.')
        }
      } catch (e) {
        setAddParentLoading(false);
        HandleUIError(e);
      }
  }

  function onCloseModal() {
    closeModal();
  }

  return (
    <Modal visible={visible} onPressOut={onCloseModal}>
      <div className="new-staff-container">
        <div className="new-staff-header">
          <p className="text-light py-2 ps-4 fs-5">Edit Parent</p>
          <IconButton icon={cilX} className="me-3" onClick={closeModal} />
        </div>
      
          <div className="parents-container">
            <p className="text-light fs-5 ms-3 pt-3">
              Name
            </p>
            <Input
              value={name}
              className="mt-2 mb-3 ms-3 search-phone-input"
              onChange={handleInputName}
              autoFocus
            />  
            <p className="text-light fs-5 ms-3 pt-3">
              Email
            </p>
            <Input
              value={email}
              className="mt-2 mb-3 ms-3 search-phone-input"
              onChange={handleInputMail}
            />  
            <p className="text-light fs-5 ms-3 pt-3">
              Phone
            </p>
            <Input
              value={phone}
              type="number"
              className="mt-2 mb-3 ms-3 search-phone-input"
              onChange={handleInputPhone}
            />  
            <Button
              disabled={addParentLoading}
              className="mt-7 button-add px-4 mb-3"
              onClick={() => changeParent(parentData.parentId, name, email, phone)}
            >
              {!addParentLoading ? "Save changes" : <Spinner />}
            </Button>
          </div>
      </div>
    </Modal>
  );
};

export default EditParent;