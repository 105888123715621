import * as React from "react";

import { MenuHeader } from "../Atomics";
import { CSpinner } from "@coreui/react";
import "./RoleList.scss";
import { removeContactFromParents } from "../../services/rolesService";
import HandleUIError from "../../utils/HandleUIError";
import toast from "react-hot-toast";
import confirmationDialog from "../toast-dialogs/ConfirmationDialog";
import "./RoleList.scss";
// interfaces
import { Roles } from "../types";
import GroupRow from "./GroupRow";

interface Props {
  parents: Array<Roles>;
  loading: boolean;
  refreshParents: () => void;
  alert: string;
  setEditData: (data: any) => void;
}

const GroupList: React.FC<Props> = ({ parents, loading, refreshParents, alert='', setEditData }) => {
  async function removeUser(parentId: number): Promise<void> {
    try {
      await toast.promise(removeContactFromParents(parentId), {
        loading: "Removing role...",
        success: "role removed from organization",
        error: "Error removing role from organization",
      });
      await refreshParents();
    } catch (e) {
      HandleUIError(e);
    }
  }

  function onPressRemoveUser(parentId: number) {
    const confirmationDialogOptions = {
      title: "Are you sure you want to remove this?",
      confirmAction: () => removeUser(parentId),
    };
    confirmationDialog(confirmationDialogOptions);
  }

  return (
    <>
      <MenuHeader className="d-flex pt-3 pb-3 justify-content-around px-2 table-size">
        <p className="text-color section-width-parents-custom d-none d-sm-block">
          Type
        </p>
        <p className="text-color section-width-parents-custom d-none d-sm-block">
          Student Name
        </p>
        <p className="text-color section-width-parents-custom d-none d-sm-block">
          {alert =='Emergency' ? 'Alert Name' : 'Group Name'}
        </p>
        {alert == 'Emergency' && (
            <p className="text-color section-width-parents-custom d-none d-sm-block">
              Location
            </p>
        )}
        <p className="text-color section-width-parents-custom d-none d-sm-block">
          Role Name
        </p>
        <p className="text-color section-width-parents-custom d-none d-sm-block">
          Date & Time
        </p>
        <p className="text-color w-auto invisible d-sm-block">
          <div className="margin-icon">
            <span
                className="badge bg-primary text-white"
                role="button"
            >
              View
            </span>
          </div>
        </p>
      </MenuHeader>
      {!loading ? (
        parents.map((element: Roles, index: number) => {
          return (
            <>
              <GroupRow
                parent={element}
                key={index}
                removeUser={onPressRemoveUser}
                refreshParents={refreshParents}
                setIsEdit={setEditData}
              />
            </>
          );
        })
      ) : (
        <div className="spinner">
          <CSpinner variant="grow" />
        </div>
      )}
    </>
  );
};

export default React.memo(GroupList);
