import React, {useEffect, useState} from 'react';
import {IconButton, Modal, Spinner} from "./Atomics";
import {cilX} from "@coreui/icons";
import {GoogleMap} from "./google-map";

const ShowAlertsModal = ({visible, closeModal, listAlerts, renderAlerts}) => {

    const renderConditionAlerts = (alert, index) => {
        if (alert?.alert) {
            return renderAlerts({...alert?.alert, isShowButton: false}, index)
        } else if (alert?.emergency_alert) {
            return renderAlerts({...alert?.emergency_alert, isShowButton: false}, index)
        } else {
            return renderAlerts({...alert, isShowButton: true}, index)
        }
    }
    return (
        <>
            <Modal visible={visible} onPressOut={closeModal}>
                <div className="new-staff-container h-auto">
                    <div className="new-staff-header">
                        <p className="text-light py-2 ps-4 fs-5">
                            Show Alerts List
                        </p>
                        <IconButton icon={cilX} className="me-3" onClick={closeModal}/>
                    </div>

                    <div className="py-3 px-3" style={{height: 300, overflowX: 'auto'}}>
                        {listAlerts?.map((alert, index) => (
                            <>
                                {renderConditionAlerts(alert, index)}
                            </>
                        ))}

                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ShowAlertsModal;