import * as React from "react";
import { Button, IconButton, Input, Modal } from "../components/Atomics";
import { cilX } from "@coreui/icons";
import "./Settings.scss";

//components
import HandleUIError from "../utils/HandleUIError";
import toast from "react-hot-toast";

//services
import { getOrganizationConfig, handleOrganizationConfig } from "../services/drillService";
import Spinner from '../components/Atomics/Spinner';
import { CFormSelect, CSpinner } from "@coreui/react";
import { getAlerts } from "../services/alertServices";
import { AlertTypeResponse } from '../services/types';
import { getAllDevices } from "../services/devicesService";
import { Device } from "../components/types";

interface Props {
    visible: boolean;
    closeModal: () => void;
}

const Settings: React.FC<Props> = ({ visible, closeModal }) => {
    const [numberDrillModeOn, setNumberDrillModeOn] = React.useState<number>(0);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [alertTypes, setAlertTypes] = React.useState<Array<AlertTypeResponse>>([]);
    const [staffDevices, setStaffDevices] = React.useState<Array<Device>>([]);
    const [deviceSelected, setDeviceSelected] = React.useState<number>(141);
    const [alertTypeSelected, setAlertTypeSelected] = React.useState<number>(17);
    const [loadingScreen, setLoadingScreen] = React.useState<boolean>(true);

    function handleInputNumberDrillOn(e: any){
        setNumberDrillModeOn(e.target.value)
    }
    
    function handleDeviceSelected(e: any){
        setDeviceSelected(e.target.value)
    }

    function handleAlertTypeSelected(e: any){
        setAlertTypeSelected(e.target.value)
    }

    async function saveChanges(){
        setIsLoading(true);
        try{
            await handleOrganizationConfig(numberDrillModeOn, alertTypeSelected, deviceSelected);
            setIsLoading(false);
            toast.success("Settings saved")
        } catch (e){
            setIsLoading(false);
            toast.error("Error changing settings")
            HandleUIError(e);
        }
        closeModal();
    }

    React.useEffect(
        function(){
            getAlerts()
                .then(res => setAlertTypes(res.data))
                .catch((e) => HandleUIError(e))

            getAllDevices()
                .then(res => setStaffDevices(res))
                .catch((e) => HandleUIError(e))

            getOrganizationConfig()
                .then(res => {
                    setAlertTypeSelected(res.alertTypeId);
                    setDeviceSelected(res.deviceId);
                    setNumberDrillModeOn(res.emergencyNumberDrill);
                    setLoadingScreen(false);
                }).catch((e) => {
                    setLoadingScreen(false);
                    HandleUIError(e);
                })
        }, [visible])

    return (
            <>
                <Modal visible={visible} onPressOut={closeModal}>
                    <div className="settings-container">
                        <div className="settings-header">
                            <p className="text-light py-2 ps-4 fs-3">Settings</p>
                            <IconButton icon={cilX} className="me-3" onClick={closeModal} />
                        </div>
                        {loadingScreen ? (
                            <div className="d-flex justify-content-center settings-container-spinner">
                                <CSpinner variant="grow" className="mt-5"/>
                            </div>
                        ) : (
                            <div className="settings-body">
                                <div>
                                    <p className="text-color ps-4 fs-4">Drill mode</p>
                                </div>
                                <p className="text-light fs-5 ms-4 pt-3 mb-2">
                                Emergency Number
                                </p>
                                <div className="emergency-number-box w-pers ms-4">
                                    <p>911</p>
                                </div>
                                <p className="text-light fs-5 ms-4 pt-3 mb-2">
                                Emergency Number Drill Mode
                                </p>
                                <Input
                                    className="mx-3 ms-4"
                                    defaultValue={numberDrillModeOn}
                                    onChange={handleInputNumberDrillOn}
                                />
                                <p className="text-light fs-5 ms-4 pt-3 mb-2">
                                Alert Type
                                </p>
                                <CFormSelect
                                    className="template-select w-pers ms-4"
                                    onChange={handleAlertTypeSelected}
                                    value={ 
                                        alertTypeSelected
                                        ? alertTypeSelected
                                        : "None"}
                                >
                                    {alertTypes.map(alertType =>{
                                        return <option value={alertType.id}>{alertType.name}</option>
                                    })
                                    }
                                </CFormSelect>
                                <p className="text-light fs-5 ms-4 pt-3 mb-2">
                                Device
                                </p>
                                <CFormSelect
                                    className="template-select w-pers ms-4"
                                    onChange={handleDeviceSelected}
                                    value={ 
                                        deviceSelected
                                        ? deviceSelected
                                        : "None"}
                                >
                                    <option value="none">None</option>
                                    {staffDevices.map(device =>{
                                        return <option value={device.id}>{device.name ? device.name : "No name"}</option>
                                    })
                                    }
                                </CFormSelect>
                                {isLoading ? (
                                <div className="d-flex justify-content-center pe-3 py-3 mt-3">
                                <Button
                                    className="px-4"
                                    disabled
                                    ><Spinner /></Button>
                                </div>
                                ):(
                                <div className="d-flex justify-content-center pe-3 py-3 mt-3">
                                <Button
                                    className="px-4"
                                    onClick={saveChanges}
                                    >Save</Button>
                                </div>
                                )}
                            </div>
                        )}
                    </div>
                </Modal>
            </>
    );
};

export default Settings;
