import * as React from "react";
import "../../views/Alerts.scss";

//components
import { Modal, IconButton, Input, Button, Spinner } from "../Atomics";
import { cilX } from "@coreui/icons";
import HandleUIError from "../../utils/HandleUIError";

//services
import { CFormCheck } from "@coreui/react";
import {
  createNewAlertCategory,
    getAlerts,
  createNewAlertType, updateAlertCategory,
  updateAlertType,
} from "../../services/alertServices";
import Multiselect from 'multiselect-react-dropdown';
import {useEffect, useState} from "react";
import {createActivityReq} from "../../services/activityLogService";

interface Props {
  visible: boolean;
  closeModal: () => void;
  refreshAlerts: () => Promise<void>;
  alertId: string;
  alertName: string;
  alertCategories: any;
  openedFrom: number; // 1 likes edit, 2 likes create
}

const AlertCategoryModal: React.FC<Props> = ({
  visible,
  closeModal,
  refreshAlerts,
  openedFrom,
  alertId,
  alertName,
  alertCategories
}) => {
  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState(alertName || "");
  const [isDefault, setIsDefault] = React.useState(false);

  // multiselect dropdown start
  const [selectedOptions, setSelectedOptions] = React.useState(openedFrom === 2 ? alertCategories : []);

  const [options, setOptions] = useState([
    { id: 1, value: 'Option 1' },
    { id: 2, value: 'Option 2' },
    { id: 3, value: 'Option 3' },
  ]);

  const onSelect = (selectedList: any, selectedItem: any) => {
    setSelectedOptions(selectedList);
  };

  const onRemove = (selectedList: any, removedItem: any) => {
    setSelectedOptions(selectedList);
  };
  // multiselect dropdown end

  function onChangeIsDefault() {
    setIsDefault(!isDefault);
  }

  function onChangeName(e: any) {
    setName(e.target.value);
  }

  useEffect(() => {
    fetchAlerts()
  }, [])

  async function fetchAlerts() {
    try {
      const response = await getAlerts();
      const options = response?.data?.map((val) => {
        return {
            id: val?.id,
            value: val?.name
        }
      })
      setOptions(options);
    } catch (e) {
      HandleUIError(e);
    }
  }

  async function onPressSend() {
    if (name !== "") {
      setLoading(true);
      try {
        const category = selectedOptions?.length > 0 && selectedOptions?.map((option: any) => {
          return option.id
        })
        if (openedFrom === 2) {
          await updateAlertCategory(name,category, alertId);
          await createActivityReq('Alert Category Updated Successfully')
        } else {
          await createNewAlertCategory(name, category);
          await createActivityReq('Alert Category Added Successfully')
        }
        refreshAlerts();
        setLoading(false);
        closeModal();
        setName("");
      } catch (e) {
        HandleUIError(e);
        closeModal();
        setName("");
        setLoading(false);
      }
    }
  }

  return (
    <>
      <Modal visible={visible} onPressOut={closeModal}>
        <div className="new-staff-container new-alert-type-container">
          <div className="new-template-header d-flex justify-content-between align-items-center">
            <p className="text-light fs-5">
              {openedFrom === 2 ? "Edit Categories" : "New Categories"}
            </p>
            <IconButton icon={cilX} onClick={closeModal} />
          </div>
          <div className="ms-3 mt-3">
            <p className="fs-5 mb-1 p-0 me-2">Name:</p>
            {openedFrom === 2 ? (
              <Input defaultValue={alertName} onChange={onChangeName} />
            ):(
              <Input onChange={onChangeName} />
            )}
          </div>
          {/*{openedFrom !== 2 && (
            <div className="d-flex align-items-center ms-3 mt-3">
              <p className="fs-5 m-0 p-0 me-2">Set this alert as default?</p>
              <CFormCheck checked={isDefault} onChange={onChangeIsDefault} />
            </div>
          )}*/}
          {false && (
              <>
                <div
                    style={{
                      width: 300,
                    }}
                    className="ms-3 mt-3"
                >
                  <p className="fs-5 mb-1 p-0 me-2">Category:</p>
                  <Multiselect
                      options={options}
                      selectedValues={selectedOptions}
                      onSelect={onSelect}
                      onRemove={onRemove}
                      displayValue="value"
                  />
                </div>
              </>
          )}
          <div className="d-flex justify-content-end px-3 pb-3">
            <Button onClick={onPressSend} disabled={loading} className="px-4">
              {loading ? <Spinner /> : "Save"}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AlertCategoryModal;
