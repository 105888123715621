import * as React from "react";
import "./NewPerimeter.scss";
import { useHistory, useLocation } from "react-router-dom";

//components
import { Input, Button, GoBackButton, Spinner } from "../components/Atomics";
import { GoogleMap } from "../components/google-map";

//services
import { getOrganizationInfo } from "../services/loggedUserService";
import { getCoordinatesByDirectionString } from "../services/facilityService";
import HandleUIError from "../utils/HandleUIError";
import toast from "react-hot-toast";
import { ZonePointsState, ZoneResponse } from "../services/types";
import { addZone, updateZone } from "../services/facilityService";
import {useEffect, useRef, useState} from "react";
import {createActivityReq} from "../services/activityLogService";

const NewPerimeter: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [initialCoordinates, setInitialCoordinates] =
    React.useState<any>({lat: -33.8688, lng: 151.2195});
  const [zonePoints, setZonePoints] = React.useState<ZonePointsState[]>();
  const [name, setName] = React.useState("");
  const [addressName, setAddressName] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [zoom, setZoom] = useState(17)

  // const [map, setMap] = useState<google.maps.Map | null>(null);
  const [center, setCenter] = useState<{ lat: number; lng: number }>({ lat: 0, lng: 0 });
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const [marker, setMarker] = useState<any>(false)

  const locationState = location.state as { zone: ZoneResponse };
  console.log('locationState', locationState)
  const zone: any = locationState ? locationState.zone : undefined;

  function onChangeName(e: any) {
    setName(e.target.value);
  }

  function closeView() {
    history.replace("/facility");
  }

  React.useEffect(() => {
    getInitialCoordinates();
  }, []);

  useEffect(() => {
    if (zone) {
      setName(zone?.name)
      setInitialCoordinates({
        lat: zone?.zonePoints[0]?.latitude,
        lng: zone?.zonePoints[0]?.longitude,
      })
    } else {
      handleCurrentLocation()
    }
  }, [zone])

  async function onPressSave() {
    // @ts-ignore
    if (name.length !== 0 && zonePoints && zonePoints?.length > 0) {
      setIsLoading(true);
      try {
        const zonePointsWithOrder: any = zonePoints?.map((zonePoint, index) => ({
          latitude: zonePoint.lat,
          longitude: zonePoint.lng,
          order: index + 1,
        }));
        await addZone(name, addressName, zonePointsWithOrder);
        toast.success("Perimeter added successfully");
        setIsLoading(false);
        closeView();
        await createActivityReq('Facility Created Successfully')
      } catch (e) {
        setIsLoading(false);
        HandleUIError(e, showRepitedNameError);
      }
    } else {
      toast.error("Please fill all fields");
    }
  }

  async function onPressSaveEdit() {
    if (zone && name.length !== 0) {
      setIsLoading(true);
      try {
        // @ts-ignore
        const zonePointsWithOrder = zonePoints?.length > 0 ? zonePoints.map((zonePoint, index) => ({
          latitude: zonePoint.lat,
          longitude: zonePoint.lng,
          order: index + 1,
        })) : zone.zonePoints;
        await updateZone(zone.id, name,addressName, zonePointsWithOrder);
        toast.success("Perimeter edited successfully");
        setIsLoading(false);
        closeView();
        await createActivityReq('Facility Updated Successfully')
      } catch (e) {
        setIsLoading(false);
        HandleUIError(e, showRepitedNameError);
      }
    } else {
      toast.error("Please fill the name field");
    }
  }

  function showRepitedNameError(errorStatusCode: number) {
    if (errorStatusCode === 400) {
      toast.error("Name already exists");
    }
  }

  async function getInitialCoordinates() {
    try {
      const { address } = await getOrganizationInfo();
      const coordinates = await getCoordinatesByDirectionString(address);
      if (coordinates) {
        setInitialCoordinates({
          lat: coordinates.latitude,
          lng: coordinates.longitude,
        });
      } else {
        toast.error("Could not get organization address");
      }
    } catch (e) {
      HandleUIError(e);
    }
  }

  const onLoad = (map: any) => {
    setInitialCoordinates(map);
  };

  const adjustZoom = (location : any) => {
    // Sample location types and corresponding zoom levels (adjust as needed)
    console.log('!zone', location)
    const zoomLevels: any = {
      "administrative_area_level_1": 12, // Zoom in for states/provinces
      "administrative_area_level_2": 14, // Zoom in for counties/districts
      "locality": 16, // Zoom in for cities/towns
      "sublocality_level_1": 17, // Zoom in for neighborhoods
      "sublocality_level_2": 18, // Zoom in for streets
      'postal_code': 18, // Zoom in for postal codes (streets)
      "country": 4, // Zoom out for countries
    };

    // Extract location type from geocoder results (assuming it's available)
    const locationType = location.address_components?.find(
        (component: any) => component.types.includes('administrative_area_level_1') ||
            component.types.includes('administrative_area_level_2') ||
            component.types.includes('locality') ||
            component.types.includes('postal_code') ||
            component.types.includes('country')
    )?.types[0];

    // Determine zoom level based on location type
    let zoomLevel = 15; // Default zoom level (adjust as needed)
    if (locationType && zoomLevels[locationType]) {
      zoomLevel = zoomLevels[locationType];
    }

    return zoomLevel;
  };


  const handleAddress = (e: any) =>  {
    setAddressName(e.target.value)
  }
  const handlePlaceSelect = () => {
    if (initialCoordinates && autocomplete) {
      const place: any = autocomplete.getPlace();
      console.log('places', place)
      setAddressName(place?.name +' '+ place?.adr_address)
      if (place.geometry) {
        setMarker(true)
        const zoomLevel = adjustZoom(place);
        setZoom(zoomLevel)
        setInitialCoordinates({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        });
        // initialCoordinates.panTo(place.geometry.location);
      }
    }
  };

  const handleCurrentLocation  = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setMarker(true)
        setInitialCoordinates({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        });
        // setZoom(17)
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  console.log('cor===', zone)



  return (
    <div className="bg-main over-flow-auto h-100 pb-3 pt-2">
      <GoBackButton onClick={closeView} className="ms-4" />
      <div className="perimeters-container  rounded pb-4 m-2 mx-sm-4 px-2 px-sm-4">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <p className="text-light me-2">Perimeter's Name:</p>
            <Input
              onChange={onChangeName}
              defaultValue={zone ? zone.name : ""}
            />
          </div>
          <Button
            className="px-4"
            onClick={zone ? onPressSaveEdit : onPressSave}
          >
            {isLoading ? <Spinner /> : "Save"}
          </Button>
        </div>
        {console.log('!zone ===', zone, zoom)}
        {!zone && zoom ? (
          <div className="pb-xl-4 pb-xxl-0">
            <p className="text-light mt-3 mb-2">Trace the perimeter zone: </p>
            <div className="map-container position-relative rounded mx-2 mx-sm-4 p-1" style={{}}>
              <GoogleMap
                handlePlaceSelect={handlePlaceSelect}
                setAutocompletes={setAutocomplete}
                drawingMode
                center={initialCoordinates}
                zoom={zoom}
                setZonePoints={setZonePoints}
                onLoad={onLoad}
                pointerMap={marker}
                handleCurrentLocation={handleCurrentLocation}
              />
            </div>
          </div>
        ) : (
            <div className="pb-xl-4 pb-xxl-0">
              <p className="text-light mt-3 mb-2">Trace the perimeter zone: </p>
              <div className="map-container position-relative rounded mx-2 mx-sm-4 p-1" style={{}}>
                <GoogleMap
                    handlePlaceSelect={handlePlaceSelect}
                    setAutocompletes={setAutocomplete}
                    drawingMode={true}
                    center={initialCoordinates}
                    zoom={zoom}
                    setZonePoints={setZonePoints}
                    zones={[zone]}
                    zonesOrder={[zone]?.map((item: any, index) => ({
                      id: item.id,
                      index: item.users?.length,
                    }))}
                    pointerMap={marker}
                    onLoad={onLoad}
                    addressName={zone?.address}
                    handleCurrentLocation={handleCurrentLocation}
                />
              </div>
            </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(NewPerimeter);
